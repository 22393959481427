<div
  class="report-message-inline"
  [ngStyle]="{
    'border-color': getPhosphorClassAndColor().color,
    'background-color': getPhosphorClassAndColor().bgColor,
  }"
  [ngClass]="variant"
>
  <app-icon
    class="report-message-icon"
    [iconStyle]="getPhosphorClassAndColor().style"
    [phosphorClass]="getPhosphorClassAndColor().class"
    [color]="getPhosphorClassAndColor().color"
  ></app-icon>
  <div class="d-flex flex-column">
    <p class="m-b-10" *ngIf="title">
      <b>{{ title }}</b>
    </p>
    <div class="report-message-text" [style.text-align]="align" [style.font-weight]="weight">
      <ng-container *ngIf="messageTemplate; else plainMessage">
        <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
      </ng-container>
      <ng-template #plainMessage>
        <p [innerHTML]="message"></p>
      </ng-template>
    </div>
  </div>
</div>
