import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';

@Injectable()
export class ImpersonateInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userFromToken = this.authService.createUserFromToken(this.authService.getToken()!);
    const email = this.authService.getUserEmail();

    if (email && userFromToken?.providerType) {
      const clonedReq = req.clone({
        setParams: {
          email,
        },
      });
      return next.handle(clonedReq);
    }

    return next.handle(req);
  }
}
