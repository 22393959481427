import { Component, TemplateRef, ViewChild } from '@angular/core';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BannerLevel } from '@app/shared/components/banner/banner.model';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { ContractDataResponse, ContractResponse } from '@app/shared/interfaces/contract.interface';
import { Profile } from '@app/shared/interfaces/profile.interface';
import { LocalStorageService } from '@app/shared/auth/local-storage.service';
import { LoaderService } from '@app/shared/services/loader.service';
import { Size, Style } from '@app/components/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { ContractsService } from '../contract/contracts/contracts.service';
import { ChangeUserInfoComponent } from '../user-info/change-user-info/change-user-info.component';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
})
export class MainContentComponent {
  public static readonly ANSWER_CONSENT_KEY = 'ANSWER_CONSENT_KEY';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  protected BannerLevel = BannerLevel;

  public slides = [
    {
      title: 'SOREGIES peut désormais gérer la facturation à votre place grâce aux relevés des compteurs LINKY',
      icon: 'ph ph-megaphone',
      iconDiscover: 'ph ph-arrow-square-out',
      subtitle: 'Nouvelles fonctionnalités',
      content:
        '🔌 Comment ça marche ?\n' +
        'Vous nous mandatez pour émettre à votre place 💪 votre facture de Production\n' +
        'Nous récupérons automatiquement les relevés de votre compteur LINKY\n' +
        'Nous générons l’autofacture, qui sera disponible sur votre Espace Producteur\n' +
        'Vous êtes payé à la date prévue par le contrat',
      routerLink: '/home/help',
    },
    {
      title: 'SOREGIES peut désormais gérer la facturation à votre place grâce aux relevés des compteurs LINKY',
      icon: 'ph ph-megaphone',
      iconDiscover: 'ph ph-arrow-square-out',
      subtitle: 'Nouvelles fonctionnalités',
      content:
        '💸 Quand ?\n' +
        'Dès maintenant en donnant votre accord. C’est simple, il suffit de cocher ✅️\n' +
        'Si vous disposez d’une installation supérieure à 36 kW, il faudra encore un peu patienter et continuer à déclarer vos index vous-mêmes.',
      routerLink: '/home/help',
    },
  ];

  getLines(content: string): string[] {
    return content.split('\n');
  }

  protected readonly window = window;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  totalContractNumber: number = 0;

  contracts: ContractDataResponse[] = [];

  displayBanner: boolean = false;

  profile: Profile | undefined = undefined;

  @ViewChild('dialogConsentContent')
  private dialogConsentContent: TemplateRef<unknown>;

  @ViewChild('changeUserInfoContent')
  private dialogChangeUserInfoContent: TemplateRef<unknown>;

  @ViewChild(ChangeUserInfoComponent)
  childUserInfo: ChangeUserInfoComponent;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private authentication: AuthService,
    private contractsService: ContractsService,
    private alertService: AlertService,
    private localStorage: LocalStorageService,
    private loaderService: LoaderService,
  ) {
    this.initContracts();
    this.initProfile();
  }

  initContracts() {
    this.authentication.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) this.getContracts();
    });
  }

  initProfile() {
    this.authentication.getProfile().subscribe({
      next: (profile: Profile) => {
        this.profile = profile;
      },
    });
  }

  get loading$() {
    return this.loaderService.loading$;
  }

  public getContracts(): void {
    this.loaderService.show('half');

    this.contractsService
      .getContracts(
        {
          partnerId: this.authentication.getProfileID(),
          filter: ['ConsentEnable'],
        },
        false,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: ContractResponse) => {
          this.totalContractNumber = response.total;
          this.contracts = response.data ? response.data : [];
          if (this.contracts.length > 0) {
            this.checkConsents();
          }
          this.loaderService.hide();
        },
        error: () => {
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.loaderService.hide();
        },
      });
  }

  checkConsents(): void {
    const contractsWithConsents = this.contracts.filter((item) => item.consentOk === true);
    if (contractsWithConsents.length === 0) {
      const answerConsent = this.localStorage.get(MainContentComponent.ANSWER_CONSENT_KEY);
      if (answerConsent === 'false') this.openConsentDialog();
      else this.showBanner();
    }
  }

  updateContractsConsent() {
    const ids = this.contracts.map((contract) => contract.id);
    const fields = { consentOk: true };
    this.contractsService
      .updateContracts({
        ids,
        fields,
      })
      .subscribe({
        next: () => {
          this.alertService.success('Les données ont été mises à jour');
        },
      });
  }

  showBanner(): void {
    this.displayBanner = true;
    this.localStorage.set(MainContentComponent.ANSWER_CONSENT_KEY, 'true');
  }

  hideBanner(): void {
    this.displayBanner = false;
  }

  openConsentDialog(): void {
    const options: ConfirmDialogData = {
      confirmText: 'Accepter',
      cancelText: 'Ignorer',
      title: 'Votre consentement',
      enableConfirmButton: true,
      dialogContent: this.dialogConsentContent,
      width: '800px',
      confirmFunction: () => {
        this.updateContractsConsent();
        this.hideBanner();
        this.dialogService.close();
        this.openChangeUserInfoDialog();
      },
      cancelFunction: () => {
        const contractsWithConsents = this.contracts.filter((item) => item.consentOk === true);
        if (contractsWithConsents.length !== this.contracts.length) this.showBanner();
      },
    };
    this.dialogService.open(options);
  }

  openChangeUserInfoDialog() {
    const options: ConfirmDialogData = {
      confirmText: 'Valider',
      cancelText: 'Ignorer',
      title: 'Mettre à jour mes coordonnées',
      dialogContent: this.dialogChangeUserInfoContent,
      confirmFunction: () => {
        this.childUserInfo
          .submitForm()
          .then(() => {
            this.dialogService.close();
            this.authentication.getProfile(true).subscribe({
              next: (profile: Profile) => {
                this.profile = profile;
                this.alertService.success('Coordonnées modifiées avec succès');
              },
            });
          })
          .catch(() => {
            this.alertService.error('Problème lors de la modification des coordonnées');
          });
      },
    };
    this.dialogService.open(options);
  }

  protected readonly Style = Style;

  protected readonly Color = Color;

  protected readonly Size = Size;
}
