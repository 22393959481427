<div class="checkbox-content">
  <label class="option">
    <input
      type="checkbox"
      [attr.aria-label]="ariaLabel"
      [disabled]="disabled"
      [checked]="checked"
      [indeterminate]="indeterminate"
      (change)="toggleCheckbox()"
    />
    <span
      class="checkbox checkbox-white-background"
      [class.checked]="checked && !indeterminate"
      [class.disabled]="disabled"
      [class.indeterminate]="indeterminate"
    >
    </span>
  </label>
  <span
    #ref
    [class.text]="ref.innerHTML.trim()"
    [class.disabled]="disabled"
    (click)="!disabled && toggleCheckbox()"
    (keydown.enter)="!disabled && toggleCheckbox()"
    [attr.tabindex]="disabled ? -1 : 0"
    [attr.role]="disabled ? null : 'button'"
  >
    <ng-content></ng-content>
  </span>
</div>
