<div class="change-user-info-container">
  <div class="form-container">
    <form [formGroup]="changeUserInfoForm" class="change-user-info-form" (ngSubmit)="submitForm()">
      <div class="form-sections">
        <div class="text-input-container mobile-phone-container" *ngIf="forSettingsPage">
          <mat-label>Titulaire</mat-label>
          <span>{{ data.contact.fullName }}</span>
          <mat-hint>Par mesure de sécurité, il n'est pas possible de modifier le nom du titulaire </mat-hint>
        </div>

        <div class="text-input-container mobile-phone-container" *ngIf="!forSettingsPage">
          Afin de faciliter nos échanges et vous avertir en cas de problème sur votre facturation, nous vous prions de
          vérifier et compléter vos coordonnées téléphoniques ci-dessous.
        </div>

        <div class="text-input-container mobile-phone-container">
          <mat-label>Téléphone mobile</mat-label>
          <app-phone-input
            (hasError)="mobileError($event)"
            [initValue]="data.contact.mobilePhone"
            (phoneValue)="setMobilePhoneValue($event)"
            (phoneCountry)="setMobilePhoneCountry($event)"
            [franceRegex]="MOBILE_PHONE_RE"
            badFormatError="
              Le numéro de téléphone mobile doit être composé de 10 chiffres et commencer par 06 ou 07.
            "
          ></app-phone-input>
        </div>

        <div class="text-input-container mobile-phone-container">
          <mat-label>Téléphone fixe</mat-label>
          <app-phone-input
            (hasError)="fixedError($event)"
            [initValue]="data.contact.fixedPhone"
            (phoneValue)="setFixedPhoneValue($event)"
            (phoneCountry)="setFixedPhoneCountry($event)"
            [franceRegex]="FIXED_PHONE_RE"
            badFormatError="
              Le numéro de téléphone fixe doit être composé de 10 chiffres et commencer par 01, 02, 03, 04, 05 ou 09
            "
          ></app-phone-input>
        </div>

        <span class="error" *ngIf="isRequired()">Au moins un numéro de téléphone est requis</span>
      </div>
    </form>
  </div>
</div>
