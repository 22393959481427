import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from './dialog.data';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  dimensions = {};

  enableConfirmButton = false;

  loading = false;

  typeTemplate: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private mdDialogRef: MatDialogRef<DialogComponent>,
  ) {
    mdDialogRef.backdropClick().subscribe(() => {
      this.close(false);
    });
  }

  ngOnInit(): void {
    this.dimensions = {
      'min-height': this.data.minHeight,

      'max-width': this.data.width,
    };

    this.data.confirmText = this.data.confirmText ?? 'Valider';
    this.enableConfirmButton = this.data.enableConfirmButton ?? false;
    this.typeTemplate = typeof this.enableConfirmButton;
  }

  public cancel() {
    this.close(false);
  }

  public close(value: unknown) {
    if (this.data.cancelFunction) this.data.cancelFunction(value);
    this.mdDialogRef.close(value);
  }

  async confirm() {
    this.loading = true;
    if (this.data.confirmFunction) {
      if (this.data.dataFunction) {
        this.data.confirmFunction(this.data.dataFunction);
      } else {
        this.data.confirmFunction();
      }
    }
  }

  setEnable = (value: boolean) => {
    this.enableConfirmButton = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @HostListener('document:keydown.esc')
  public onEsc() {
    this.close(false);
  }
}
