import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DialogComponent } from './dialog.component';
import { ConfirmDialogData } from './dialog.data';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  dialogRef!: MatDialogRef<DialogComponent>;

  public open(options: ConfirmDialogData) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: options,
      autoFocus: false,
      disableClose: true,
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public confirmed(): Observable<boolean> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => res),
    );
  }
}
