import { Color } from '@app/shared/models/color';
import { Component, Input, TemplateRef } from '@angular/core';
import { MessageReportLevel, MessageReportVariant } from '@app/shared/components/report-message/report-message.model';
import { Style } from '@app/components/icon/icon.component';

@Component({
  selector: 'app-report-message',
  templateUrl: './report-message.component.html',
  styleUrls: ['./report-message.component.scss'],
})
export class ReportMessageComponent {
  @Input() type: MessageReportLevel = MessageReportLevel.INFO;

  @Input() variant: MessageReportVariant = MessageReportVariant.OUTLINED;

  @Input() message: string = '';

  @Input() align: string = 'center';

  @Input() title: string | undefined = undefined;

  @Input() weight: string = 'inherit';

  @Input() messageTemplate?: TemplateRef<any>;

  protected MessageReportVariant = MessageReportVariant;

  getPhosphorClassAndColor(): { class: string; color: Color; bgColor: Color | ''; style: Style } {
    const isFilled = this.variant === MessageReportVariant.FILLED;
    const style = isFilled ? Style.fill : Style.light;

    switch (this.type) {
      case MessageReportLevel.ERROR:
        return { class: 'ph-warning-circle', color: Color.danger500, bgColor: isFilled ? Color.danger100 : '', style };
      case MessageReportLevel.WARNING:
        return { class: 'ph-warning', color: Color.warning500, bgColor: isFilled ? Color.warning100 : '', style };
      default:
        return { class: 'ph-info', color: Color.info500, bgColor: isFilled ? Color.info100 : '', style };
    }
  }
}
