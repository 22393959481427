<h1 class="title">
  Connectez-vous à<br />
  votre espace producteur
</h1>
<div class="container">
  <div class="form-container">
    <form (ngSubmit)="submit()" [formGroup]="loginform" class="login-container">
      <div class="text-input-container">
        <mat-label>Email</mat-label>
        <app-input
          id="login"
          type="email"
          placeholder="Votre adresse mail"
          [formCtrl]="loginform.get('login')! | formControl"
          [errorMessages]="errorMessagesEmail"
        ></app-input>
      </div>
      <div class="text-input-container">
        <mat-label>Mot de passe</mat-label>
        <app-password-input
          id="password"
          [formCtrl]="this.loginform.get('password')! | formControl"
          placeholder="Votre mot de passe"
        >
        </app-password-input>
        <mat-error *ngIf="f.password.touched && f.password.hasError('required')" id="passwordErrorRequired">
          Le mot de passe est requis
        </mat-error>
        <a [queryParams]="{ email: f.login.value }" [routerLink]="['/forgot_password']" class="forget-password"
          >Mot de passe oublié ?</a
        >
        <div *ngIf="f.login.errors?.badIds" class="submit-error" id="submittedError">Identifiants incorrects</div>
        <div *ngIf="errorMessage" class="submit-error connection" id="connection-error">
          {{ errorMessage }}
        </div>
      </div>

      <div *ngIf="displayResendPassword">
        <app-resend-password
          (generationOk)="generationOk($event)"
          [email]="loginform.get('login')?.value"
        ></app-resend-password>
      </div>

      <div class="submit-form">
        <button
          [disabled]="
            loading ||
            (f.login.invalid && !f.login.hasError('badIds')) ||
            (f.password.invalid && !f.password.hasError('badIds'))
          "
          class="default-btn"
          id="loginBtn"
          mat-flat-button
          type="submit"
        >
          <span *ngIf="!loading" id="button-text">Me connecter</span>
          <mat-spinner *ngIf="loading" [diameter]="30" class="default-loader" id="mat-spinner"></mat-spinner>
        </button>
      </div>
    </form>
  </div>
  <div class="container-info">
    <span>Votre espace producteur est disponible en version mobile.</span>
  </div>
</div>
