import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { PaginatorService } from '@app/shared/components/paginator/paginator.service';
import { ContractDataResponse, ContractResponse } from '@app/shared/interfaces/contract.interface';
import { MESSAGES } from '@app/shared/utils/messages';
import { billingRythm, salesType } from '@app/shared/utils/referential';
import { Subject, takeUntil } from 'rxjs';
import { ToggleSwitchSize } from '@app/shared/models/toggle-switch.model';
import { DataColumns } from '@app/shared/interfaces/table.interface';
import { Color } from '@app/shared/models/color';
import { Style } from '@app/components/icon/icon.component';
import { ContractsService } from './contracts.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  IconStyle = Style;

  Colors = Color;

  @Input() searchContracts: FormControl = new FormControl();

  @Input() isActualContract: boolean = true;

  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;

  @ViewChild('consentTemplate') consentTemplate!: TemplateRef<any>;

  dataSource: MatTableDataSource<ContractDataResponse> = new MatTableDataSource<ContractDataResponse>();

  resultLimit: number = 10;

  resultOffset: number = 0;

  searchValue: string = '';

  dataColumns: DataColumns[] = [
    { def: 'contractLineId', header: 'Dossier', class: 'default-cell default-column-type-semibold-center contract' },
    {
      def: 'name',
      header: 'Titulaire',
      class: 'default-cell default-column-type-regular-center holder',
      sortKey: 'contract.ownerPartner.mainContact.fullName',
    },
    {
      def: 'mptCode',
      header: 'Contrat',
      class: 'default-cell default-column-type-semibold-center exchangeRef',
      sortKey: 'mpt.code',
    },
    {
      def: 'mptAddress',
      header: 'Adresse du point de comptage',
      class: 'default-cell default-column-type-regular-center mptAddress',
      sortKey: 'mpt.address.fullName',
    },
    {
      def: 'startDate',
      header: "Date de prise d'effet",
      type: 'date',
      class: 'default-cell date-column-type supplyStartDate',
    },
    {
      def: 'peakPower',
      header: 'Puissance crête',
      type: 'number',
      class: 'default-cell default-column-type-regular-center peakPower',
      unit: 'kWc',
    },
    {
      def: 'salesTypeSelect',
      header: 'Type de vente',
      type: 'transform',
      transform: salesType,
      class: 'default-cell default-column-type-regular-center salesType',
    },
    {
      def: 'invoicingRhythmSelect',
      header: 'Rythme de facturation',
      type: 'transform',
      transform: billingRythm,
      class: 'default-cell default-column-type-regular-center billingRate',
    },
    {
      def: 'status',
      header: 'Statut',
      type: 'custom',
      class: 'default-cell default-column-type-regular-center status',
      displayCondition: this.isActualContract,
      customTemplate: undefined,
      sortKey: 'status.name',
    },
    {
      def: 'consent',
      header: 'Auto-facturation',
      type: 'custom',
      class: 'default-cell default-column-type-regular-center status',
      displayCondition: this.isActualContract,
      customTemplate: undefined,
      sortKey: 'consentOk',
    },
    {
      def: 'actions',
      header: 'Détails du contrat',
      class: 'default-cell default-column-type-semibold-center action-buttons',
      action: true,
      icon: 'ph-eye-bold',
      sortable: false,
    },
  ];

  contractOptions = [
    { key: 'Mes contrats', value: 'actual' },
    { key: 'Contrats cédés', value: 'ceased' },
  ];

  totalContractNumber: number = 0;

  noDataMessage: string = '';

  isLoading: boolean = true;

  currentStatus: string = this.contractOptions[0].value;

  readonly ToggleSwitchSize = ToggleSwitchSize;

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataColumns = this.dataColumns.map((col) => {
      const copy = { ...col };
      switch (col.def) {
        case 'status':
          copy!.customTemplate = this.statusTemplate;
          break;
        case 'consent':
          copy!.customTemplate = this.consentTemplate;
          break;
        default:
          break;
      }
      return copy;
    });
  }

  constructor(
    private authentication: AuthService,
    private paginatorService: PaginatorService,
    private contractsService: ContractsService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.initPaginator();
    this.initContracts();
  }

  initContracts() {
    this.authentication.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) this.getContracts();
    });
  }

  initPaginator() {
    this.paginatorService.limit.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (limit) => {
        this.resultLimit = limit;
      },
    });

    this.paginatorService.page.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (page) => {
        this.resultOffset = (page - 1) * this.resultLimit;
        this.getContracts();
      },
    });
  }

  public getContracts(sortBy?: string, isHistory: boolean = false): void {
    this.isLoading = true;

    this.contractsService
      .getContracts(
        {
          limit: this.resultLimit,
          offset: this.resultOffset,
          sortBy,
          search: this.searchValue,
          partnerId: this.authentication.getProfileID(),
        },
        isHistory,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: ContractResponse) => {
          this.paginatorService.itemProperties$.next({
            total: response.total,
            shown: response.data ? response.data.length : 0,
          });
          this.totalContractNumber = response.total;
          this.dataSource.data = response.data
            ? response.data.map((item) => ({
                ...item,
                mptCode: item.mpt?.code,
                mptAddress: item.mpt?.address,
              }))
            : [];
          this.noDataMessage = response.data ? '' : MESSAGES.NO_DATA_ERROR;
          this.isLoading = false;
        },
        error: () => {
          this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.isLoading = false;
        },
      });
  }

  announceSearchChange(): void {
    this.resultOffset = 0;
    this.searchValue = this.searchContracts.value;
    this.getContracts();
  }

  announceSortChange(sortState: Sort): void {
    const column = this.dataColumns.find((col) => col.def === sortState.active);
    const sortKey = column?.sortKey || sortState.active;
    const sortBy = sortState.direction ? `${sortState.direction === 'asc' ? '-' : ''}${sortKey}` : '';
    this.getContracts(sortBy, !this.isActualContract);
  }

  onClickContractToggleButton(eventValue: string) {
    this.isActualContract = eventValue === 'actual';
    this.paginatorService.clear();
    this.dataSource.data = [];
    this.getContracts('', !this.isActualContract);
  }

  ngOnDestroy(): void {
    this.paginatorService.clear();
    this.dataSource.data = [];
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  isConsentAvailable(element: any): boolean {
    return element.status === 'Actif' && element.userSegment === 'P4' && element?.mpt?.exchangeRef;
  }
}
