<ng-container *ngIf="loaderService.loading$ | async as loading">
  <div *ngIf="loading.open" class="loader-container" [ngClass]="{ 'half-background': loading.type === 'half' }">
    <nav class="loader-header" *ngIf="loading.type !== 'half'">
      <img
        alt="soregies_logo"
        class="loader-img-logo"
        src="https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Logos%20Groupe%20Soregies/Logo%20Groupe%20Soregies/Logo%20Groupe%20Soregies.png"
      />
    </nav>
    <div class="loader-content">
      <img
        class="loader-logo"
        src="https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Symboles%20Groupe%20Soregies/Vert/Symbole%20Soregies.svg"
      />
    </div>
  </div>
</ng-container>
