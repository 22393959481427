<div class="carousel">
  <!-- Section du haut du carrousel -->
  <div class="carousel-content carousel-top" (click)="nextPage()">
    <ng-container #currentPageTop></ng-container>
  </div>

  <!-- Section du bas du carrousel -->
  <div class="carousel-content carousel-bottom" (click)="nextPage()">
    <ng-container #currentPageBottom></ng-container>
  </div>

  <!-- Pied du carrousel (pagination et navigation) -->
  <div class="carousel-footer">
    <!-- Flèche gauche pour la navigation -->
    <app-icon
      class="arrow"
      [iconStyle]="Style.bold"
      phosphorClass="ph-caret-left"
      [size]="Size.large"
      (click)="prevPage()"
      [color]="Color.danger500"
    ></app-icon>

    <!-- Pagination des pages -->
    <div class="carousel-pagination">
      <div
        *ngFor="let page of [].constructor(totalPages); let i = index"
        class="page-item"
        [class.selected]="i === currentPageNumber"
        (click)="changePage(i)"
      ></div>
    </div>

    <!-- Flèche droite pour la navigation -->
    <app-icon
      class="arrow"
      [iconStyle]="Style.bold"
      phosphorClass="ph-caret-right"
      [size]="Size.large"
      (click)="nextPage()"
      [color]="Color.danger500"
    ></app-icon>
  </div>
</div>
