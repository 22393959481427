<div class="privacy-and-policy-container">
  <h1>
    Politique de confidentialité et de protection
    <br />
    des données personnelles Sorégies
  </h1>

  <div class="content">
    <div>
      <span class="bold-text">
        <strong>
          <br />
          Préambule
          <br />
        </strong>
      </span>
      <br />
      Dans le cadre de ses activités auprès ses prospects et de ses clients, la société Sorégies traite des données à
      caractère personnel. Ces traitements de données sont basés sur les données fournies par la personne concernée
      elle-même, ou sont collectées de façon automatique dans le cadre d’un contrat ou après obtention de son
      consentement. Le présent document a vocation à informer les utilisateurs de Sorégies sur la manière dont elle, en
      tant que responsable des traitements, gère et traite ces données fournies et collectées, conformément à la
      législation en vigueur en France (la Loi n°78-17 du 6 janvier1978 modifiée relative à l’informatique, aux fichiers
      et aux libertés et le Règlement Européen Général sur la Protection des Données UE 2016/679 du 27avril 2016) ainsi
      que dans le respect des stipulations ci-après. La politique de confidentialité et de protection des données
      personnelles s’applique à l’ensemble des utilisateurs des services de Sorégies, qu’ils soient clients ou prospect
      des services et produits de Sorégies. La présente politique de confidentialité et de protection des données de
      Sorégies est disponible en permanence sur le site
      <a href="https://mon-espace-producteur.soregies.fr/">mon-espace-producteur.soregies.fr</a>
      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          1. Identité du responsable des traitements des Données à Caractère Personnel
          <br />
        </strong>
      </span>
      <br />
      Le responsable des traitements des Données à Caractère Personnel est la société Sorégies, société par actions
      simplifiées, au capital de 3 800 000 euros, dont le siège social est : 78 Avenue Jacques Cœur, CS 10 000, 86068
      Poitiers cedex 9 Immatriculée au RCS de Poitiers sous le numéro : 502.035.785.

      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />2. Finalités des traitements de donnée à caractère personnel
          <br />
        </strong>
      </span>
      <br />
      Les données à caractère personnel peuvent être fournies ou collectées au travers de la relation contractuelle qui
      relie l’utilisateur à Sorégies ou lors de la navigation sur le site de Sorégies.
      <div class="list">
        <br />
        Les finalités principales des traitements sont les suivantes :
        <ul>
          <li>
            Gestion de la relation contractuelle (fourniture d’énergies, de services ou de charge de véhicules
            électriques)
          </li>
          <li>La mise à disposition d’informations et d’outils (dans de domaine de l’énergie)</li>
          <li>La gestion des réseaux de distribution</li>
          <li>Gestion des impayés et du contentieux</li>
          <li>Gestion des enquêtes ou des avis des personnes sur les produits, services ou contenus</li>
          <li>Gestion des opérations relatives à la prospection</li>
          <li>Elaboration de statistiques commerciales</li>
          <li>Organisation de jeux concours, de loteries ou de toute opération promotionnelle</li>
        </ul>
        <br />
        Les bases légales des différents traitements ainsi réalisés sont :
        <ul>
          <li>L’exécution du contrat liant Sorégies au client</li>
          <li>Les missions de services publics de distribution de l’Energie.</li>
          <li>
            L’intérêt légitime du responsable de traitement qui justifie que Sorégies puisse mettre en œuvre le
            traitement de données à caractère personnel concerné,
          </li>
          <li>Le consentement de l’utilisateur pour certains traitements optionnels des données. </li>
        </ul>
      </div>
      <br />
      Le consentement peut être fourni ou retiré à tout moment sans que cela puisse porter atteinte à la licéité du
      traitement fondé sur le consentement avant le retrait de celui-ci. Pour les traitements basés sur l’intérêt
      légitime qui nécessite un contact direct avec le client (recueil d’avis par exemple), Sorégies s’efforcera
      toujours de mettre en avant le droit d’opposition pour la personne concernée. Sorégies ne procède à aucun
      traitement de données à caractère personnel entièrement automatisé qui engendrerait un effet juridique sur la
      relation contractuelle.
      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          3. Nature des données à caractère personnel fournies et collectées‍
          <br />
        </strong>
      </span>
      <br />
      Les catégories de données collectées et traitées sont les suivantes :
      <div class="list">
        <ul>
          <li>Données d’identification, éventuellement professionnelle</li>
          <li>Données financières et de transaction</li>
          <li>Habitude de vie (en lien avec les consommations d’énergies)</li>
          <li>Données de navigation sur les sites internet </li>
        </ul>
      </div>
      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          4. Destinataire des données à caractère personnel
          <br />
        </strong>
      </span>
      <br />
      Les Données à Caractère Personnel sont destinées à être traitées par les services internes de Sorégies, en charge
      de la gestion des contrats et de la réalisation des traitements, ainsi que par les sous-traitants dûment habilités
      par Sorégies, travaillant exclusivement sur instruction et pour le compte de Sorégies. Sorégies encadre
      juridiquement les contrats de sous-traitance pour interdire formellement et strictement la réutilisation des
      données par les sous-traitants, pour tout motif autre que la formation ou l’amélioration de ses activités. ‍ Les
      Données à Caractère Personnel sont communiquées pour les seuls besoins de la réalisation de la finalité pour
      laquelle elles sont recueillies initialement. Aucune donnée fournie n’est et ne sera jamais transmise à un tiers
      sans votre consentement préalable, que ce soit pour de la prospection commerciale ou à des fins publicitaires.
      Sorégies traite les données à caractère personnel dans une volonté de transparence et dans le respect de la
      législation en vigueur.

      <div class="list">
        <br />
        Enfin, Les Données à Caractère Personnel peuvent être transmises à un tiers dans les cas où :
        <ul>
          <li>La loi l’impose ou l’autorise expressément</li>
          <li>En présence d’une disposition législative en vigueur</li>
          <li>Une décision judiciaire</li>
          <li>
            Si cette divulgation est nécessaire dans le cadre d’une enquête ou d’une procédure, sur le territoire
            national ou à l’étranger
          </li>
        </ul>
      </div>
      <br />
      Dans la mesure du possible, l’ensemble des données à caractère personnel dont Sorégies est directement responsable
      de traitement sont prioritairement hébergées sur le territoire de l’Union Européenne. Si un transfert de données,
      dont Sorégies est exclusivement Responsable de Traitement, est réalisé vers un pays situé en dehors de l’Union
      Européenne, Soregies met en place les mesures contractuelles et de protection adaptées.
      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          5. Durée de conservation des données personnelles
          <br />
        </strong>
      </span>
      <br />
      Sorégies s’engage à n’utiliser les Données à Caractère Personnel collectées que pour une durée n’excédant pas
      celle nécessaire aux finalités pour lesquelles elles sont collectées et traitées.
      <br /><br />
      Ces durées de conservations sont définies en fonction des finalités de traitement mise en œuvre par Sorégies et
      tiennent notamment compte des dispositions légales ou règlementaires applicables en la matière, en particulier les
      délais de recours ou de contestation.
      <br /><br />
      Les données qui concernent la gestion du contrat sont conservées toute la durée du contrat assortie d’une période
      de 10 ans à compter de la date de résiliation afin de satisfaire à des obligations légales, comptables, fiscales
      et afin de gérer les délais de recours. 
      <br /><br />
      Les données liées aux opérations commerciales sur des personnes ayant un statut de prospect sont supprimées au
      maximum 3 ans après le dernier contact actif avec le client.

      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          6. Sécurité des Données Personnelles
          <br />
        </strong>
      </span>
      <br />
      SORÉGIES s’engage à mettre en œuvre toutes les précautions utiles, les mesures organisationnelles et techniques
      appropriées aux données traitées afin d’assurer la sécurité et la confidentialité des données récoltées contre
      notamment l’accès illégitime, la disparition de données ou la modification non désirée.
      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          7. Exercice de vos droits
          <br />
        </strong>
      </span>
      <br />
      Les personnes concernées disposent entre autres des droits d’accès, de rectification, de suppression et de
      portabilité des données les concernant, ainsi que d’un droit d’opposition et de limitation des traitements pour
      motifs légitimes. Elles disposent aussi du droit de retirer ou de fournir librement son consentement. Elle dispose
      également de la possibilité de donner des directives relatives à la conservation, à l’effacement et à la
      communication de ses données après leur décès. Ces droits peuvent s’exercer à tout moment à l’aide d’un des moyens
      suivants
      <br /><br />
      Ces droits peuvent s’exercer à tout moment par mail à l’adresse suivante :  dpo&#64;soregies.fr
      <br /><br />
      Ou par courrier ou sur place à l’adresse suivante : DPO SORÉGIES – 78, avenue Jacques Cœur – CS 10 000 – 86000
      POITIERS. Ou Par téléphone au 05 49 60 54 00.
      <br /><br />
      En cas de doute légitime sur l’identité de la personne concernée, l’exercice de l’un de ces droits pourra
      nécessiter la fourniture d’une preuve de l’identité à l’aide d’un justificatif adapté.  Les usagers disposent
      également de la possibilité d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et
      des Libertés, sur le site
      <a href="http://www.cnil.fr/">www.cnil.fr</a>.
      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          8. Modifications
          <br />
        </strong>
      </span>
      <br />
      SORÉGIES se réserve le droit de modifier à tout moment la présente politique de confidentialité et de protection
      des données personnelles pour tenir compte des évolutions législatives, règlementaire et opérationnelles. Si la
      modification de la présente politique engendrait des effets importants pour les droits et attentes légitimes des
      personnes concernées, Sorégies informerait préalablement ses clients par un moyen raisonnable et adapté.
      <br />
    </div>

    <div>
      <span class="bold-text">
        <strong>
          <br />
          9. Contact
          <br />
        </strong>
      </span>
      <br />

      Pour toute question relative à la politique de confidentialité et de protection des données personnelles de
      Sorégies, l’utilisateur peut contacter le Délégué à la Protection des Données de Sorégies en écrivant à l’adresse
      suivante :
      <a href="mailto:DPO@srd-energie.fr">DPO&#64;Sorégies-energie.fr</a>
      <br />
      Méta title : SORÉGIES Politique de confidentialité et protection des données
      <br />
      Métadescription : Consultez la politique de confidentialité et de protection des données personnelles SORÉGIES
      <br />
    </div>
  </div>
</div>
