import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IndexEventService } from '@app/components/indexEvent/indexEvent.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { IndexDataResponse, IndexResponse } from '@app/shared/interfaces/index.interface';
import { MESSAGES } from '@app/shared/utils/messages';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-forbidden-index-event',
  templateUrl: './forbiddenIndexEvent.component.html',
  styleUrls: ['./forbiddenIndexEvent.component.scss'],
})
export class ForbiddenIndexEventComponent implements OnInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  forbiddenIndexHead: string[] = [
    'contractLine.contractLineId',
    'ownerPartner.mainContact.fullName',
    'contractLine.mpt.code',
    'contractLine.mpt.address.fullName',
    'theoreticalDateNextInvoice',
  ];

  isLoading: boolean = false;

  noDataMessage: string = 'Pas de données';

  dataSource: MatTableDataSource<IndexDataResponse> = new MatTableDataSource<IndexDataResponse>();

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(
    private indexService: IndexEventService,
    private alertService: AlertService,
    private authentication: AuthService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.authentication.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) {
        this.getIndex();
      }
    });
  }

  getIndex(): void {
    this.isLoading = true;
    this.indexService
      .getIndex({
        limit: 10,
        offset: 0,
        edit: ['forbidden'],
        sortBy: 'theoreticalDateNextInvoice',
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: IndexResponse) => {
          // this.paginatorService.itemProperties$.next({
          //   total: response.total,
          //   shown: response.data ? response.data.length : 0,
          // });
          // this.totalIndexNumber = response.total;
          if (response.data) this.dataSource.data = response.data;
          else {
            this.dataSource.data = [];
            this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          }
          this.isLoading = false;
        },
        error: () => {
          this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.isLoading = false;
        },
      });
  }
}
