<div class="mobile-phone-input">
  <div>
    <mat-form-field class="country-field" hideRequiredMarker>
      <mat-select [(ngModel)]="selected" (valueChange)="selectChange($event)" panelClass="country-panel">
        <mat-select-trigger>
          <em *ngIf="selected === 'international'" class="ph-globe"></em>
          <span *ngIf="selected === 'fr'" class="fi fi-fr"></span>
        </mat-select-trigger>
        <mat-option value="fr" selected> <span class="fi fi-fr"></span> France </mat-option>
        <mat-option value="international"> <em class="ph-globe"></em>International </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="phone-field" hideRequiredMarker appearance="outline">
      <input
        id="mobilePhone"
        [formControl]="phone"
        matInput
        name="MobilePhone"
        type="text"
        placeholder="Entrez votre nouveau numéro"
        (keyup)="updatePhoneNumber($event)"
        (change)="updatePhoneNumber($event)"
      />
    </mat-form-field>
  </div>
  <mat-error *ngIf="phone.hasError('pattern')">{{ badFormatError }}</mat-error>
</div>
