import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from '@app/components/contact/contact.component';
import { ContractDetailsComponent } from '@app/components/contract/contract-details/contract-details.component';
import { ContractsComponent } from '@app/components/contract/contracts/contracts.component';
import { DemarcheComponent } from '@app/components/demarches/demarche.component';
import { HelpComponent } from '@app/components/help/help.component';
import { HomePageGuard } from '@app/components/home-page/home-page.guard';
import { IndexEventDetailsComponent } from '@app/components/indexEvent/indexEvent-details/indexEvent-details.component';
import { IndexEventComponent } from '@app/components/indexEvent/indexEvent.component';
import { InvoicesComponent } from '@app/components/invoice/invoices.component';
import { LegalNoticeComponent } from '@app/components/legal-notice/legal-notice.component';
import { ResetPasswordGuard } from '@app/components/login/reset-password/reset_password.guard';
import { PrivacyAndPolicyComponent } from '@app/components/privacy-and-policy/privacy-and-policy.component';
import { ProfilesComponent } from '@app/components/profil/profiles.component';
import { ProfileGuard } from '@app/components/profil/profiles.guard';
import { UserInfoComponent } from '@app/components/user-info/user-info.component';
import { ChooseProducerComponent } from '@app/components/admin-account/choose-producer/choose-producer.component';
import { SamlCallbackComponent } from '@app/components/admin-account/saml-callback.component';
import { ChooseProducerGuard } from '@app/components/admin-account/choose-producer/choose-producer.guard';
import { ContactNotFoundComponent } from '../components/contact-not-found-page/contact-not-found-page.component';
import { HomePageComponent } from '../components/home-page/home-page.component';
import { ForgotPasswordComponent } from '../components/login/forgot-password/forgot-password.component';
import { LoginContainerComponent } from '../components/login/login-container/login-container.component';
import { LoginFormComponent } from '../components/login/login-form/login-form.component';
import { ResetPasswordComponent } from '../components/login/reset-password/reset-password.component';
import { MainContentComponent } from '../components/main-content/main-content.component';
import { AuthGuard } from '../shared/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LoginContainerComponent,
    children: [
      { path: '', component: LoginFormComponent },
      { path: 'forgot_password', component: ForgotPasswordComponent },
      { path: 'reset_password', canActivate: [ResetPasswordGuard], component: ResetPasswordComponent },
    ],
  },
  {
    path: 'saml-callback',
    component: SamlCallbackComponent,
  },
  {
    path: 'profiles',
    component: LoginContainerComponent,
    canActivate: [ProfileGuard],
    children: [{ path: '', component: ProfilesComponent }],
  },
  {
    path: 'producers',
    component: ChooseProducerComponent,
    canActivate: [ChooseProducerGuard],
  },
  {
    path: 'contact-not-found-page',
    component: ContactNotFoundComponent,
  },
  {
    path: 'home',
    canActivate: [HomePageGuard],
    canActivateChild: [HomePageGuard],
    component: HomePageComponent,
    children: [
      { path: '', component: MainContentComponent },
      {
        path: 'index',
        component: IndexEventComponent,
      },
      {
        path: 'index/:id',
        component: IndexEventDetailsComponent,
      },
      {
        path: 'contracts',
        component: ContractsComponent,
      },
      {
        path: 'contracts/:id',
        component: ContractDetailsComponent,
      },
      {
        path: 'contracts-history/:id',
        component: ContractDetailsComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
      },
      {
        path: 'user',
        component: UserInfoComponent,
      },
      {
        path: 'mentions-legales',
        component: LegalNoticeComponent,
      },
      {
        path: 'politique-de-confidentialite',
        component: PrivacyAndPolicyComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      {
        path: 'step',
        component: DemarcheComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
