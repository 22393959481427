<mat-form-field class="mat-select-field" appearance="outline" [class.mat-select-panel-opened]="panelIsOpened">
  <mat-select
    [disabled]="disabled"
    [formControl]="selectControl"
    [id]="selectId || 'selector'"
    [class.panel-opened]="panelIsOpened"
    panelClass="mat-select-field-panel"
    disableOptionCentering
    [placeholder]="placeHolder"
    [(value)]="selectedValue"
    (selectionChange)="handleChange($event)"
    (openedChange)="handlePanelIsOpen($event)"
  >
    <mat-option *ngFor="let param of params" [value]="param.value || param.title">
      <div class="mat-option-content">
        <span>{{ param.title }}</span>
        <em *ngIf="selectedValue === param.value || selectedValue === param.title" class="ph-check-bold"></em>
      </div>
    </mat-option>
  </mat-select>
  <em [class.rotated]="panelIsOpened" class="ph-caret-down-bold" [ngClass]="{ disabled: 'disabled' }"></em>
</mat-form-field>
<mat-error id="selectErrorRequired" *ngIf="selectControl.touched && selectControl.hasError('required')">
  {{ errorMessage }}
</mat-error>
