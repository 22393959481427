import { Color } from '@app/shared/models/color';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BannerLevel } from '@app/shared/components/banner/banner.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() type: BannerLevel = BannerLevel.INFO;

  @Input() title: string = '';

  @Input() description: string = '';

  @Input() buttonText: string = '';

  @Output() clickAction = new EventEmitter<Event>();

  getPhosphorClassAndColor(): { class: string; color: Color } {
    switch (this.type) {
      case BannerLevel.ERROR:
        return { class: 'ph-warning-circle', color: Color.danger500 };
      case BannerLevel.WARNING:
        return { class: 'ph-warning', color: Color.warning500 };
      default:
        return { class: 'ph-info', color: Color.info500 };
    }
  }

  handleClick($event: Event) {
    this.clickAction.emit($event);
  }
}
