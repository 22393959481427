<ng-container *ngIf="displayBanner">
  <app-banner
    [type]="BannerLevel.WARNING"
    [title]="'Votre consentement pour l’auto-facturation'"
    [description]="
      'Je souhaite que SOREGIES gère la facturation de ma production à la place, grâce aux relevés de mon compteur LINKY. Je n’ai plus à déclarer mes index, tout est automatisé.'
    "
    [buttonText]="'En savoir plus'"
    (click)="openConsentDialog()"
  ></app-banner>
</ng-container>

<ng-template #dialogConsentContent>
  <app-change-consent [contracts]="this.contracts"></app-change-consent>
</ng-template>

<ng-template #changeUserInfoContent let-setEnable="setEnable">
  <app-change-user-info
    [forSettingsPage]="false"
    [setEnable]="setEnable"
    [data]="{ contact: this.profile }"
  ></app-change-user-info>
</ng-template>

<div class="main-container" [class.mobile]="isHandset$ | async">
  <mat-card class="main-content">
    <mat-card-header class="main-content-header">
      <mat-card-title-group>
        <mat-card-title> <span class="title">Bonjour</span></mat-card-title>
        <mat-card-subtitle>
          <span class="subtitle">Bienvenue dans votre Espace Producteur Sorégies</span>
        </mat-card-subtitle>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content class="main-content-body">
      <div *ngIf="(isHandset$ | async) === false" class="shortcuts-container">
        <app-shortcut-button
          link="/home/contracts"
          title="Mes contrats"
          iconClass="ph-file-text"
          id="shortcut-contracts"
        ></app-shortcut-button>

        <app-shortcut-button
          link="/home/invoices"
          title="Mes factures"
          iconClass="ph-currency-eur"
          id="shortcut-invoices"
        ></app-shortcut-button>

        <app-shortcut-button
          link="/home/index"
          title="Mes index"
          iconClass="ph-lightning"
          id="shortcut-index"
        ></app-shortcut-button>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="sub-container" [class.mobile]="isHandset$ | async">
    <div class="actu-card">
      <app-carousel class="carousel">
        <ng-container *ngFor="let slide of slides; let index = index">
          <!-- Section pour la partie "top" -->
          <div class="carousel-element" *appCarouselItemTop>
            <div class="subtype">
              <i [ngClass]="slide.icon" [ngStyle]="{ 'font-weight': 'bold' }"></i>
              <p>{{ slide.subtitle }}</p>
            </div>
            <div class="title">{{ slide.title }}</div>
          </div>

          <!-- Section pour la partie "bottom" -->
          <div class="carousel-element" *appCarouselItemBottom>
            <div class="message">
              <ul class="padded-list">
                <ng-container *ngFor="let line of getLines(slide.content); let i = index">
                  <li [class.indented]="i > 0">
                    <span *ngIf="i > 0" class="emoji">●</span>
                    <span>{{ line }}</span>
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="discover-telereleve">
              <a [routerLink]="slide.routerLink"><p>Découvrir</p></a>
              <app-icon [iconStyle]="Style.bold" [phosphorClass]="slide.iconDiscover" [size]="Size.medium"></app-icon>
            </div>
          </div>
        </ng-container>
      </app-carousel>
    </div>
  </div>
</div>

<div class="container-side-rss">
  <app-side-content></app-side-content>
  <div class="actu">
    <app-rss-feed></app-rss-feed>
  </div>
</div>
