import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/auth/auth.service';
import { User } from '@app/shared/models/user-info';
import { Subject, takeUntil } from 'rxjs';
import { MessageReportLevel, MessageReportVariant } from '@app/shared/components/report-message/report-message.model';
import { ChangeMailService } from './change-mail.service';

@Component({
  selector: 'app-change-mail',
  templateUrl: './change-mail.component.html',
  styleUrls: ['./change-mail.component.scss'],
})
export class ChangeMailComponent implements OnDestroy {
  @Input() setEnable: (args: boolean) => void;

  @Input() setLoading: (args: boolean) => void;

  protected readonly MessageReportVariant = MessageReportVariant;

  protected readonly MessageReportLevel = MessageReportLevel;

  userConnected?: User;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  protected validMail: string = '';

  EMAIL_REGEX = '^([A-Z|a-z|0-9](\\.|_){0,1})+[A-Z|a-z|0-9]\\@([A-Z|a-z|0-9](\\.|-){0,1})+[A-Z|a-z|0-9]\\.[a-z]{2,63}$';

  changeMailform = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.EMAIL_REGEX)]),
  });

  verifyMailform = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public changeMailService: ChangeMailService,
    public authService: AuthService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  confirm(): Promise<unknown> {
    if (this.validMail) return this.verifyMail();
    return this.submitForm();
  }

  verifyMailChange() {
    this.setEnable(!this.verifyMailform.controls.code.errors);
  }

  submitForm(): Promise<boolean | void> {
    this.setLoading(true);
    return new Promise<boolean | void>((resolve, reject) => {
      this.authService
        .changeMail(this.changeMailform.get('email')?.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.validMail = this.changeMailform.get('email')?.value;
            this.setLoading(false);
            this.setEnable(false);
            resolve(false);
          },
          error: () => {
            this.setLoading(false);
            this.setEnable(false);
            this.controls.email.setErrors({ incorrect: true });
            reject();
          },
        });
    });
  }

  verifyMail() {
    this.setLoading(true);

    return new Promise<unknown>((resolve, reject) => {
      this.authService
        .verifyMail(this.verifyMailform.get('code')?.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.changeMailService
              .changeMail(this.validMail)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe({
                next: () => {
                  this.setLoading(false);
                  resolve(true);
                },
                error: () => {
                  this.controlsCode.code.setErrors({ error: true });
                  this.setLoading(false);
                  reject();
                },
              });
          },
          error: (error) => {
            this.setLoading(false);
            if (error?.error?.errorType === 'AliasExistsException') this.controlsCode.code.setErrors({ exist: true });
            else this.controlsCode.code.setErrors({ incorrect: true });
            reject();
          },
        });
    });
  }

  back() {
    this.validMail = '';
  }

  get controls() {
    return this.changeMailform.controls;
  }

  get controlsCode() {
    return this.verifyMailform.controls;
  }

  setEmailError(event: ValidationErrors | null) {
    this.changeMailform.get('email')?.setErrors(event);
    this.setEnable(!event);
  }

  setCodeError(event: ValidationErrors | null) {
    this.changeMailform.get('code')?.setErrors(event);
    this.setEnable(!event);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
