<ng-container *ngTemplateOutlet="this.selectedTmpl"> </ng-container>

<ng-template #borderedBackBtn>
  <button
    #buttons
    id="back-btn"
    class="back-btn square"
    mat-flat-button
    [disabled]="isLoading"
    (click)="onClickButton()"
  >
    <em *ngIf="!isLoading; else elseBlock" class="ph-arrow-left-bold"></em>
    <ng-template #elseBlock>
      <mat-spinner *ngIf="isLoading" [diameter]="25" class="default-loader"></mat-spinner>
    </ng-template>
  </button>
</ng-template>
<ng-template #iconTextBtn>
  <button
    #buttons
    id="{{ buttonId }}"
    class="icon-text-btn bordered-btn square"
    mat-flat-button
    [disabled]="isLoading || disabled"
    (click)="onClickButton()"
  >
    <em *ngIf="iconClass" class="ph-{{ iconClass }}"></em>
    <span *ngIf="!isLoading; else elseBlock">{{ text }}</span>
    <ng-template #elseBlock>
      <mat-spinner *ngIf="isLoading" [diameter]="25" class="default-loader"></mat-spinner>
    </ng-template>
  </button>
</ng-template>
<ng-template #primaryBtn>
  <button
    #buttons
    id="{{ buttonId }}"
    [disabled]="isLoading || disabled"
    class="primary-btn bordered-btn square"
    mat-flat-button
    (click)="onClickButton()"
  >
    <span *ngIf="!isLoading; else elseBlock">{{ text }}</span>
    <ng-template #elseBlock>
      <mat-spinner *ngIf="isLoading" [diameter]="25" class="default-loader"></mat-spinner>
    </ng-template>
  </button>
</ng-template>
<ng-template #blankBtn>
  <button
    #buttons
    id="blank-btn"
    [disabled]="isLoading || disabled"
    class="blank-btn bordered-btn square"
    mat-flat-button
    (click)="onClickButton()"
  >
    <span *ngIf="!isLoading; else elseBlock">{{ text }}</span>
    <ng-template #elseBlock>
      <mat-spinner *ngIf="isLoading" [diameter]="25" class="default-loader"></mat-spinner>
    </ng-template>
  </button>
</ng-template>
