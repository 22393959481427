import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ContactService } from '@app/components/contact/contact.service';
import { ContractsService } from '@app/components/contract/contracts/contracts.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { TypeParams } from '@app/shared/components/selector/selector.component';
import { User } from '@app/shared/models/user-info';
import { FileUploadService } from '@app/shared/services/file-upload.service';
import { PhoneNumberService } from '@app/shared/services/phone-number.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { Observable, Subject, takeUntil } from 'rxjs';
import { formatFileName } from '@app/shared/utils/format-utils';
import { Themes } from '@app/components/contact/contact.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractDataResponse } from '@app/shared/interfaces/contract.interface';
import { Size, Style } from '@app/components/icon/icon.component';
import { IndexEventDetailsService } from '../indexEvent/indexEvent-details/indexEvent-details.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent implements OnInit, OnDestroy {
  @ViewChild('fileinput', { static: true }) inputRef: ElementRef;

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  loading: boolean = false;

  displayFormContact: boolean = false;

  displaySolutionAutre: boolean = false;

  selectedTheme: Themes | null = null;

  protected readonly Themes = Themes;

  public filesHolder$: Observable<File[]> = this.fileUploadService.filesHolder$.asObservable();

  EMAIL_REGEX = '^([A-Z|a-z|0-9](\\.|_){0,1})+[A-Z|a-z|0-9]\\@([A-Z|a-z|0-9](\\.|-){0,1})+[A-Z|a-z|0-9]\\.[a-z]{2,63}$';

  over: boolean;

  isFormHidden: boolean = false;

  extensions: string[] = ['application/pdf', 'image/png', 'image/jpeg'];

  userConnected?: User;

  contracts: TypeParams[] = [];

  errorsCount: number = 0;

  errorMessage: string = '';

  openFormContact: Themes[] = [
    Themes.CompteurDeProductionEnPanne,
    Themes.ErreurDeSaisieDIndex,
    Themes.CessionDuContrat,
    Themes.ModificationRIBPourReglement,
    Themes.JeContesteMonIndex,
  ];

  displayMessageInformations: Themes[] = [
    Themes.CessionDuContrat,
    Themes.ModificationRIBPourReglement,
    Themes.SaisirMonIndexDeProduction,
    Themes.ReglementDeFacture,
    Themes.ErreurDeSaisieDIndex,
  ];

  themes: TypeParams[] = [
    {
      title: Themes.CompteurDeProductionEnPanne,
      value: Themes.CompteurDeProductionEnPanne,
    },
    {
      title: Themes.ErreurDeSaisieDIndex,
      value: Themes.ErreurDeSaisieDIndex,
    },
    {
      title: Themes.ReglementDeFacture,
      value: Themes.ReglementDeFacture,
    },
    {
      title: Themes.SaisirMonIndexDeProduction,
      value: Themes.SaisirMonIndexDeProduction,
    },
    {
      title: Themes.ModificationRIBPourReglement,
      value: Themes.ModificationRIBPourReglement,
    },
    {
      title: Themes.CessionDuContrat,
      value: Themes.CessionDuContrat,
    },
    {
      title: Themes.Autres,
      value: Themes.Autres,
    },
  ];

  public contactForm: UntypedFormGroup = this.fb.group({
    contract: ['', Validators.required],
    index: [''],
    email: ['', Validators.pattern(this.EMAIL_REGEX)],
    phone: [''],
    theme: ['', Validators.required],
    comment: ['', Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private fileUploadService: FileUploadService,
    private authService: AuthService,
    private contractsService: ContractsService,
    private contactService: ContactService,
    private indexService: IndexEventDetailsService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  ngOnInit(): void {
    this.authService.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) {
        this.contactForm.get('email')?.setValue(this.userConnected?.email);
        this.contactForm.get('phone')?.setValue(PhoneNumberService.formatPhoneNumber(this.userConnected?.phoneNumber));
      }
    });
    this.initContracts();

    // if params include index=xxx, we display the contest index page
    const id = this.route.snapshot.queryParamMap.get('index');
    if (id) {
      this.indexService.getIndex(id).subscribe((index) => {
        this.setTheme(Themes.JeContesteMonIndex);
        this.setIndex(index.indexValue.toString());
        this.setContract(index.contractLine.contractLineId);
      });
    }
  }

  initContracts() {
    this.contractsService
      .getContracts(
        {
          limit: 100,
          offset: 0,
          partnerId: this.authService.getProfileID(),
        },
        false,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: { data: ContractDataResponse[] }) => {
          this.contracts = response.data?.map((contractData: ContractDataResponse) => ({
            title: contractData.contractLineId,
            value: contractData.contractLineId,
          }));
        },
        error: (err: unknown) => {
          console.error('err', err);
        },
      });
  }

  get f() {
    return this.contactForm.controls;
  }

  setContract(contract: string): void {
    this.contactForm.get('contract')?.setValue(contract);
  }

  setTheme(theme: Themes): void {
    this.contactForm.get('theme')?.setValue(theme);
    this.selectedTheme = theme;
    this.displayFormContact = this.openFormContact.includes(theme);
    this.displaySolutionAutre = false;
  }

  setIndex(index: string): void {
    this.contactForm.get('index')?.setValue(index);
  }

  async submit() {
    this.loading = true;
    const body = {
      ...this.contactForm.getRawValue(),
      contact: this.userConnected?.fullName,
    }; // TODO : Ne pas le mettre en dur
    body.files = [];
    const filesUploaded = this.fileUploadService.filesHolder;
    for (let i = 0; i !== filesUploaded.length; i++) {
      if (this.extensions.includes(filesUploaded[i].type)) {
        const fileBase64 = (await new Promise((r) => {
          const reader = new FileReader();
          reader.onload = () => r(reader.result as string);
          reader.readAsDataURL(filesUploaded[i]);
        })) as string;

        body.files.push({
          content: fileBase64.slice(fileBase64.indexOf(',')).substring(1),
          name: formatFileName(filesUploaded[i].name),
          type: filesUploaded[i].type,
        });
      }
    }

    this.contactService
      .sendEmail(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.alertService.success(MESSAGES.SEND_PROVIDER_CONTACT_SUCCESS, {
            icon: 'envelope',
          });
          this.isFormHidden = true;
          this.contactForm.reset();
          this.fileUploadService.cleanFiles();
        },
        error: () => {
          this.loading = false;
          this.alertService.error(MESSAGES.SEND_PROVIDER_CONTACT_ERROR, {
            icon: 'envelope',
          });
        },
      });
  }

  // not used
  updatePhoneNumber($event: any) {
    const rawPhoneNumber = String($event.target.value);
    this.contactForm.get('phone')?.setValue(PhoneNumberService.formatPhoneNumber(rawPhoneNumber));
  }

  dropFile($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    if ($event.dataTransfer) this.handleDrop($event?.dataTransfer?.files);
  }

  handleDrop(files: FileList) {
    if (this.validateSize(files)) {
      const validFiles = this.validateExtensions(files);
      this.fileUploadService.addFiles(validFiles);
    }
  }

  private validateExtensions(files: FileList): File[] {
    if (this.extensions.length !== 0) {
      const incorrectFiles: string[] = [];
      const valid = Array.from(files).filter((x) => {
        if (!this.extensions.includes(x.type)) {
          incorrectFiles.push(x.name);
          return false;
        }
        return true;
      });

      if (valid.length < files.length) {
        if (incorrectFiles.length > 1) {
          this.errorMessage = `L'extension des fichiers ${incorrectFiles.join(
            ', ',
          )} est incorrecte, ces derniers ne seront pas envoyés`;
        } else
          this.errorMessage = `L'extension du fichiers ${incorrectFiles[0]} est incorrecte, ce dernier ne sera pas envoyé`;
      }

      return valid;
    }
    return Array.from(files);
  }

  private validateSize(files: FileList): boolean {
    this.errorMessage = '';
    const valid = this.fileUploadService.getSize() + Array.from(files).reduce((a, c) => a + c.size, 0) <= 5000000;
    if (!valid) this.errorMessage = 'Les pièces jointes sont limités à 5Mo';
    return valid;
  }

  importFiles($event: Event) {
    const input = $event.target as HTMLInputElement;
    if (input.files) {
      this.handleDrop(input.files);
    }
  }

  getSize() {
    return (this.fileUploadService.getSize() / 1000000).toFixed(2);
  }

  getIndex(): string {
    return this.contactForm.get('index')?.value;
  }

  removeFile(index: number) {
    this.errorMessage = '';
    this.fileUploadService.removeFile(index);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
    this.fileUploadService.cleanFiles();
  }

  get errorCount(): number {
    return this.contactForm.errors ? Object.keys(this.contactForm.errors)?.length : 0;
  }

  setError(event: ValidationErrors | null): void {
    this.contactForm.setErrors(event);
  }

  onClickYesButton(): void {
    this.displayFormContact = true;
    this.displaySolutionAutre = false;
  }

  onClickNoButton(): void {
    this.displayFormContact = false;
    switch (this.selectedTheme) {
      case Themes.ReglementDeFacture:
      case Themes.SaisirMonIndexDeProduction:
        this.router.navigateByUrl('/home');
        break;
      case Themes.Autres:
        this.displaySolutionAutre = true;
        break;
      default:
        break;
    }
  }

  protected readonly Size = Size;

  protected readonly Style = Style;
}
