<div class="invoices-container">
  <div class="invoices-header">
    <div class="title-container">
      <h2 class="invoices-title">Toutes mes factures</h2>
    </div>
    <div class="actions-header">
      <button
        class="default-btn"
        type="button"
        mat-flat-button
        (click)="showInfoCheckingStatusInvoice()"
        id="infoInvoiceCheckingBtn"
      >
        <i class="square ph-info"></i>
        <span> En savoir plus : Facture en cours de vérification</span>
      </button>
      <button class="default-btn" type="button" mat-flat-button (click)="showInfo()" id="infoBtn">
        <i class="square ph-info"></i>
        <span>Informations délais de règlements</span>
      </button>
    </div>
  </div>
  <div>
    <div class="text-input-container">
      <mat-form-field hideRequiredMarker appearance="outline" class="search-field">
        <input
          type="text"
          matInput
          [formControl]="searchInvoices"
          (keydown.enter)="announceSearchChange()"
          placeholder="Facture, Dossier, Contrat"
        />

        <em (click)="announceSearchChange()" class="ph-magnifying-glass" matSuffix></em>
      </mat-form-field>
    </div>

    <ng-template #statusTemplate let-element>
      <mat-chip
        style="pointer-events: none"
        [class.saisie]="true"
        [class.open]="element['status.name'] === 'Validé'"
        [class.close]="element['status.name'] !== 'Validé'"
      >
        {{ transformStatus(element) }}
      </mat-chip>
    </ng-template>

    <app-table
      [dataSource]="dataSource"
      [columns]="dataColumns"
      [sortActive]="'invoiceDate'"
      [sortDirection]="'asc'"
      [isLoading]="isLoading"
      [downloading]="downloading"
      [noDataMessage]="noDataMessage"
      [tableId]="'invoices-table'"
      (sortChange)="announceSortChange($event)"
      (actionEvent)="handleActionEvent($event)"
      [noHoverOnRows]="true"
    ></app-table>
    <app-paginator></app-paginator>
  </div>
</div>
