import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { MessageReportLevel, MessageReportVariant } from '@app/shared/components/report-message/report-message.model';
import { MessageService } from '@app/shared/services/message-service.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { CustomMatchingValidators } from '@app/shared/validators/matching.validator';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  protected readonly MessageReportVariant = MessageReportVariant;

  protected readonly MessageReportLevel = MessageReportLevel;

  options = {
    autoClose: false,
    keepAfterRouteChange: false,
  };

  resetPasswordform = new UntypedFormGroup(
    {
      password: new UntypedFormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(12),
          CustomMatchingValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomMatchingValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          CustomMatchingValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          CustomMatchingValidators.patternValidator(/^(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S.*$/, {
            hasSpecialCharacters: true,
          }),
        ],
      }),
      password_confirm: new UntypedFormControl('', Validators.required),
    },
    { validators: CustomMatchingValidators.mustMatch },
  );

  submitted: boolean = false;

  isLoadingSubmit: boolean = false;

  isLoadingSendConfirmationCode: boolean = false;

  hiddenPassword: boolean = false;

  hiddenConfirmPassword: boolean = false;

  submittedError: boolean = false;

  emailForgotPassword: string = '';

  errorMessagesCode = [
    { name: 'required', value: 'Le code est requis' },
    { name: 'maxlength', value: 'Le code doit contenir 6 caractères' },
    { name: 'minlength', value: 'Le code doit contenir 6 caractères' },
  ];

  constructor(
    public authService: AuthService,
    private router: Router,
    private location: Location,
    protected alertService: AlertService,
    private messageService: MessageService<{ email: string }>,
  ) {}

  get f() {
    return this.resetPasswordform.controls;
  }

  ngOnInit(): void {
    window.onbeforeunload = () => this.ngOnDestroy();

    this.addValidator();
    this.getMessage();
  }

  addValidator() {
    if (this.authService.isForgotPassword) {
      this.resetPasswordform.addControl(
        'confirmationCode',
        new UntypedFormControl('', {
          validators: [Validators.required, Validators.maxLength(6), Validators.minLength(6)],
        }),
      );
    }
  }

  getMessage() {
    this.messageService
      .getMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message) => {
        if (message?.email) {
          this.emailForgotPassword = message.email;
        }
      });
  }

  ngOnDestroy(): void {
    this.authService.removeChallengeSession();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  sendConfirmationCode() {
    this.isLoadingSendConfirmationCode = true;
    if (this.emailForgotPassword) {
      this.authService
        .forgotPassword(this.emailForgotPassword)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.isLoadingSendConfirmationCode = false;
            this.alertService.success(MESSAGES.RECEIVE_CODE);
          },
          error: () => {
            this.isLoadingSendConfirmationCode = false;
            this.alertService.error(MESSAGES.SEND_CODE_ERROR);
          },
        });
    }
  }

  navigateToProfileSelection() {
    this.authService.canNavigateToProfileSelection().subscribe({
      error: () => {
        this.authService.emptySession();
        this.isLoadingSubmit = false;
      },
    });
  }

  resetpassword() {
    this.submitted = true;
    if (this.resetPasswordform.valid) {
      this.isLoadingSubmit = true;

      this.authService
        .resetPassword(this.resetPasswordform.get('password')?.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.navigateToProfileSelection();
          },
          error: () => {
            this.isLoadingSubmit = false;
            this.submittedError = true;
            this.router.navigate([''], {
              state: { errorMessage: MESSAGES.CONNEXION_ERROR },
            });
          },
        });
    }
  }

  forgotPassword() {
    this.isLoadingSubmit = true;

    this.authService
      .forgotPassword(this.emailForgotPassword, this.f.confirmationCode.value, this.f.password.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.isLoadingSubmit = false;
          this.authService.isForgotPassword = false;
          this.router.navigate([''], {
            queryParams: { isPasswordModified: true },
          });
        },
        error: (error) => {
          this.isLoadingSubmit = false;
          let alertMesage;
          switch (error.error.errorType) {
            case 'CodeMismatchException': {
              alertMesage = MESSAGES.MISMATCH_CODE;
              break;
            }
            case 'TooManyRequestsException':
            case 'LimitExceededException': {
              alertMesage = MESSAGES.LIMIT_EXCEEDED_CODE_ERROR;
              break;
            }
            default: {
              alertMesage = MESSAGES.DEFAULT_ERROR;
            }
          }
          this.alertService.error(alertMesage);
        },
        complete: () => {
          this.messageService.clearMessages();
        },
      });
  }

  cancel() {
    this.authService.isForgotPassword = false;
    this.router.navigate(['']);
  }

  handleBack() {
    this.location.back();
  }

  disablPasteRightClick(event: ClipboardEvent | MouseEvent): void {
    event.preventDefault();
  }
}
