<div class="help-container">
  <mat-card id="help-panel" class="help-content">
    <h2>Questions/Réponses</h2>
    <div class="div-help-page">
      <div id="side-menu-help">
        <a
          [ngClass]="{
            'line-menu-help': activeButton !== 'frequent',
            'line-menu-help-active': activeButton === 'frequent',
          }"
          (click)="setActiveButton('frequent')"
          >Les questions les plus fréquentes</a
        >
        <a
          [ngClass]="{
            'line-menu-help': activeButton !== 'mpt',
            'line-menu-help-active': activeButton === 'mpt',
          }"
          (click)="setActiveButton('mpt')"
          >Les questions liées au compteur</a
        >
      </div>
      <div id="side-question-help">
        <div id="frequently-asked-questions" [hidden]="activeButton !== 'frequent'">
          <div class="sub-title">Questions les plus fréquentes</div>
          <app-collapse
            title="
              Quand commencer à déclarer ma production d’énergie photovoltaïque ?
            "
          >
            <p>
              Au paragraphe « PERIODICITE DE FACTURATION » des Conditions Particulières de votre contrat, le rythme de
              facturation est indiqué (Annuel,Semestriel, Mensuel). Le rythme est également spécifié dans la rubrique
              <a class="rubrique" routerLink="/home/contracts">« Mes contrats »</a>.
            </p>
            <br />
            <p>
              Si la puissance de votre installation est inférieure à 36 kWc, votre périodicité est annuelle. Vous
              pourrez donc déclarer un an après la mise en service au raccordement de votre installation (soit 1 an
              après la date de la prise d’effet de votre contrat).
            </p>
            <br />
            <p>
              Un email vous sera envoyé à chaque début de période de déclaration de production d’énergie photovoltaïque
              afin que vous puissiez déclarer votre index.
            </p>
            <br />
            <p>
              Pour en savoir plus sur vos échéances de déclaration, vous pouvez consulter l’historique de vos index
              <em class="ph-lightning index-container-lightning"></em>
              et également consulter vos prochaines déclarations
              <a routerLink="/home/index">
                <button class="next-index-btn" type="button" mat-flat-button>
                  <i class="ph ph-binoculars"></i>
                  Saisies à venir
                </button>
              </a>
            </p>
            <br />
            <p>
              Vous pouvez connaitre la date à laquelle vous allez pouvoir saisir votre index grâce à la colonne : « Date
              TPF » qui signifie Date Théorique de Prochaine Facturation. C’est la date à laquelle votre saisie d’index
              sera autorisée. Une ligne s’affichera en bleu : La saisie sera donc ouverte :
              <mat-chip class="saisie open">Ouverte</mat-chip>
            </p>
            <br />
            <p>
              Avant cette date et conformément à la règlementation de votre contrat d’achat, il est inutile de nous
              contacter pour inscrire votre index. Par exception, nous enregistrerons l’index si un évènement est
              intervenu (un avenant, un changement de compteur …)
            </p>
          </app-collapse>
          <app-collapse
            title="
              Je me suis trompé(e) lors de la saisie de mon index ?
            "
          >
            <p>
              Nous vous prions via le
              <a routerLink="/home/contact" class="rubrique"><em class="ph-envelope"></em> formulaire de contact</a> de
              nous communiquer l’index réel (l’index à corriger) avec la date de relève. Nous effectuerons la
              modification.
            </p>
            <br />
            <p>Lorsque la correction sera effectuée, nous vous l’indiquerons par mail.</p>
            <br />
            <p>La correction de l’index modifiera la facture qui a été générée.</p>
          </app-collapse>
          <app-collapse title="Comment utiliser l'Espace Producteur ?">
            <p>
              SOREGIES met à votre disposition l’Espace Producteur pour saisir vos index et consulter vos factures de
              production d’énergie photovoltaïque.
            </p>
            <div class="fit-div">
              <app-download-file
                id="doc-modeop-ep"
                link="https://static.soregies.fr/divers/Mode+Op%C3%A9ratoire+Espace+Producteur+.pdf"
                title="Mode d'emploi de l'Espace producteur"
              ></app-download-file>
            </div>
          </app-collapse>
          <app-collapse title="Une fois mes index renseignés, quand serai-je payé ?">
            <p>Cela dépend de la date de votre saisie sur notre plateforme Espace Producteur :</p>
            <ul>
              <li>
                <p>
                  - Si vous déclarez votre production avant le 20 du mois, le règlement est effectué le dernier jour du
                  mois.
                </p>
              </li>
              <li>
                <p>
                  - Si vous déclarez votre production au-delà du 20 du mois, le règlement est effectué le dernier jour
                  du mois M+1.
                </p>
              </li>
            </ul>
            <p>
              Il faut également ajouter quelques jours supplémentaires en raison du délai des transactions bancaires.
            </p>
          </app-collapse>
          <app-collapse title="Ma situation évolue, que dois-je faire ? ">
            <p>
              Dans le cadre des évolutions citées ci-dessous, vous pouvez retrouver toutes les informations dans la
              rubrique
              <a class="rubrique" routerLink="/home/step">« Mes démarches »</a>.
            </p>
            <ul>
              <li>
                <p>
                  - Je vends un bien disposant d’une installation photovoltaïque bénéficiant d’un contrat d’obligation
                  d’achat
                </p>
              </li>
              <li>
                <p>- Je souhaite modifier le RIB relatif aux versements de la production photovoltaïque</p>
              </li>
              <li>
                <p>- Le titulaire du contrat est décédé</p>
              </li>
              <li>
                <p>- TVA : Je souhaite modifier mon contrat afin qu’il soit conforme à ma situation fiscale</p>
              </li>
              <li>
                <p>- Mon installation a été détruite ou il faut remplacer des panneaux</p>
              </li>
              <li>
                <p>- Je me sépare : séparation, divorce, rupture de PACS</p>
              </li>
              <li>
                <p>- Je souhaite ajouter un co-titulaire</p>
              </li>
            </ul>
          </app-collapse>
          <app-collapse
            title="
              SORÉGIES installe des panneaux ou peut me proposer d'en installer
            "
          >
            <p>
              SORÉGIES est un fournisseur et un acheteur obligé d’énergie. SORÉGIES n’est pas installateur de panneaux
              photovoltaïques.
            </p>
            <br />
            <p>
              <b>
                En revanche, en partenariat avec des installateur locaux, SORÉGIES propose une offre clé en main
                d’installation de panneaux photovoltaïques et une solution de stockage.</b
              >
              Plus d’informations sur
              <a class="rubrique" href="//www.soregies.fr/offre-autoconsommation/">cette page</a>.
            </p>
            <br />
            <p>
              SORÉGIES n’est pas responsable de votre installation. Celle-ci est de l’ordre privée. Veuillez contacter
              un installateur.
            </p>
          </app-collapse>
          <app-collapse
            title="
              J'ai reçu un Contrats D'accès aux Réseaux (CAE) ou une convention de raccordement que dois-je faire ?
            "
          >
            <p>
              Vous pouvez consulter
              <a href="https://www.srd-energies.fr/">SRD</a> le Gestionnaire de Réseau de Distribution
            </p>
          </app-collapse>
          <app-collapse
            title="
              J’ai un problème sur mon installation (panneaux / onduleurs), que dois- je faire ?
            "
          >
            <p>Vous devez consulter un installateur. SORÉGIES ou SRD n’interviendront pas sur votre installation.</p>
          </app-collapse>
          <app-collapse
            title="Factures de consommation élevées malgré des panneaux photovoltaïques en autoconsommation"
          >
            <p>
              Tout d’abord vous devez connaitre le mode de facturation de votre consommation : Réglez-vous vos factures
              selon la consommation réelle de l’électricité ou la consommation estimée (calculée grâce à l’année
              passée). Les estimations ne tiennent peut-être pas en compte la pose de vos panneaux, si cela est récent.
            </p>
            <br />
            <p>Aussi une facture de consommation compte plusieurs lignes de facturation :</p>
            <ul>
              <li>
                <p>– L’énergie</p>
              </li>
              <li>
                <p>– L’abonnement</p>
              </li>
              <li>
                <p>– Les taxes</p>
              </li>
            </ul>
            <br />
            <p>
              L’autoconsommation vous permet d’économiser sur l’énergie mais n’impacte que très faiblement le reste de
              la facture, d’un point de vue financier. Il vous faut donc être prudent si vous souhaitez diminuer le
              montant de vos factures si vous avez choisi la mensualisation.
            </p>
            <br />
            <p>
              De plus, il est important d’avoir à l’esprit que l’autoconsommation est l’énergie que vous consommez au
              moment où vous la produisez.
            </p>
            <br />
            <p>
              Les profils d’autoconsommation en résidentiel ne sont parfois pas favorables à l’autoconsommation dans la
              mesure où :
            </p>
            <ul>
              <li>
                – La forte production a lieu au milieu de la journée (utilisateurs au travail ou à l’école) et en
                période estivale (congés d’été, …) ;
              </li>
              <li>
                – La forte consommation a lieu en fin de journée, lorsque l’ensoleillement est faible (retour à la
                maison en soirée : éclairage hivernal, chauffage, plaque de cuisson, four, machine à laver, etc.)
              </li>
            </ul>
            <br />
            <p>
              Avant toute réalisation, il est nécessaire de connaître les données de consommation de votre logement, à
              différentes périodes de l’année et lors des périodes d’occupation et d’inoccupation de votre logement.
            </p>
            <br />
            <p>
              En effet, toute l’énergie produite et non consommée instantanément est injectée dans le réseau : le
              surplus est donc conséquent.
            </p>
            <br />
            <p>
              Quelques informations en plus en suivant
              <a
                class="rubrique"
                href="https://www.photovoltaique.info/fr/preparer-un-projet/vente-ou-autoconsommation/autoconsommation-partielle-ou-totale/#modeles_economiques_de_lautoconsommation"
                >ce lien</a
              >.
            </p>
          </app-collapse>
          <app-collapse
            title="
              Pourquoi l’achat d’énergie n’est pas déduit de mes factures de consommation ?
            "
          >
            <p>
              Dans le cadre de l’obligation d’achat, le kilowattheure d’électricité photovoltaïque est vendu par le
              producteur à un tarif fixé par arrêté ministériel. Le producteur photovoltaïque injecte de l’électricité
              sur le réseau ; l’acheteur est obligé d’acheter l’énergie photovoltaïque au prix fixé par la loi.
            </p>
            <br />
            <p>
              Ce mécanisme est totalement décorrélé de la consommation. L’achat d’énergie est une obligation
              règlementaire. Vos factures de consommation sont établies grâce à un contrat commercial (au marché ou
              règlementé).
            </p>
            <br />
            <p>Ces services sont totalement différents et ne dépendent pas des mêmes pratiques.</p>
            <br />
            <p>
              L’obligation d’achat est un service public imposée par l’Etat aux fournisseurs et est compensée par la
              Contribution au Service Public de l’Electricité (CSPE)
            </p>
            <br />
            <p>
              Les conditions d’achat ainsi que les conditions d’éligibilité à l’obligation d’achat sont décrites dans un
              arrêté tarifaire. Les arrêtés tarifaires sont adaptés aux conditions économiques et aux priorités
              publiques du moment ; ainsi, ils sont abrogés et remplacés régulièrement, au fil du temps.
            </p>
          </app-collapse>
          <app-collapse
            title="
              Qu'est ce que la puissance Q ?
            "
          >
            <p>
              Quelques définitions au sens de l’Arrêté du 6 octobre 2021 fixant les conditions d’achat de l’électricité
              produite par les installations implantées sur bâtiment, hangar ou ombrière utilisant l’énergie solaire
              photovoltaïque, d’une puissance crête installée inférieure ou égale à 500 kilowatts telles que visées au
              3o de l’article D. 314-15 du code de l’énergie et situées en métropole continentale.
            </p>
            <br />
            <p>On entend par :</p>
            <br />
            <p>
              « Distance entre deux installations » : distance au sol la plus courte entre les capteurs des deux
              installations.
            </p>
            <br />
            <p>
              « Site d’implantation » : les contours d’un site d’implantation s’apprécient en fonction de la distance
              entre les installations et de la propriété des bâtiments ou ombrières sur lesquelles elles sont
              implantées. Les règles sont données à l’annexe 3 du présent arrêté.
            </p>
            <br />
            <p>
              <b>Annexe 1 point 5 : DÉFINITION DE LA PUISSANCE Q</b>
            </p>
            <br />
            <p>
              Pour chaque installation, il est défini une puissance Q, exprimée en kWc et définie comme la puissance
              installée de l’ensemble des autres installations raccordées ou en projet sur le même site d’implantation
              que l’installation objet du contrat d’achat, et dont les demandes complètes de raccordement au réseau
              public de distribution ont été déposées dans les 18 mois avant ou après la date de demande complète de
              raccordement au réseau public de distribution de l’installation objet du contrat d’achat.
            </p>
            <br />
            <p>
              <b>Annexe 3 : RÈGLES POUR ÉTABLIR LES CONTOURS DES SITES D’IMPLANTATION</b>
            </p>
            <br />
            <p>
              En général, deux installations distantes de moins de cent (100) mètres sont considérées comme implantées
              sur un même site.
            </p>
            <br />
            <p>
              Par exception à l’alinéa précédent, deux installations photovoltaïques peuvent être considérées comme
              implantées sur des sites distincts :
            </p>
            <br />
            <p>
              1. Lorsqu’elles sont implantées sur des bâtiments, hangars ou ombrières appartenant à des propriétaires
              indépendants. Pour les personnes physiques, deux personnes distinctes sont réputées indépendantes.
            </p>
            <br />
            <p>
              Pour les personnes morales, l’indépendance des propriétaires s’évalue en particulier au regard du contrôle
              direct, indirect ou conjoint au sens de l’article L. 233-3 et L. 233-4 du code de commerce ;
            </p>
            <br />
            <p>
              2. Lorsqu’elles sont implantées sur des bâtiments, hangars ou ombrière destinés à des usages distincts
              détenus par une même personne morale de droit public.
            </p>
            <br />
            <p>
              Par exception au premier alinéa, deux bâtiments, hangars ou ombrières exclusivement destinés à l’usage
              d’habitation au sens de l’article R. 311-1-1 du code de la construction et de l’urbanisme et distants de
              moins de cent (100) mètres sont considérés comme des sites distincts dès lors que le demandeur présente un
              document émanant d’un architecte qui atteste que l’un et l’autre de ces bâtiments pourrait assurer ses
              fonctions en l’absence du deuxième bâtiment. Dans ce cas, le tarif auquel l’installation est éligible au
              sens de l’article 8 du présent arrêté est diminué de dix pourcents. ➡️ Une attestation architecte est
              alors à communiquer.
            </p>
            <br />
            <p>
              Si une modification de la puissance Q intervient dans les 18 mois suivant la demande complète de
              raccordement, il peut y avoir un impact tarifaire (tarif et prime) sur le contrat initial. Si le contrat
              d’achat est déjà signé, il est modifié par avenant. Lorsque d’autres installations sont situées sur le
              même site d’implantation, le producteur joint un plan de situation desdites installations, en précisant
              les distances entre les installations.
            </p>
          </app-collapse>
          <app-collapse
            title="
              A quel moment je vais percevoir ma prime surplus ?
            "
          >
            <p>
              Si vous êtes titulaire d’un contrat d’achat S17 ou S21
              <b>
                <u>en vente de surplus</u>
              </b>
              (uniquement), une prime est prévue dans votre contrat.
            </p>
            <br />
            <p>
              La prime est réglée en même temps que l'achat de votre énergie et elle apparaitra sur votre facture de
              production (disponible sur votre espace producteur dans la rubrique
              <a routerLink="/home/invoices" class="rubrique">« Mes factures » </a>).
            </p>
            <br />
            <p>
              Vous pouvez consulter le montant de votre prime dans le paragraphe « tarifs et primes » des conditions
              particulières de votre contrat mais également dans la rubrique
              <a routerLink="/home/contracts" class="rubrique"> « Mes contrats » </a> de votre espace producteur
            </p>
            <br />
            <p>Toutefois, vous pouvez consulter les conditions applicables aux différents types de contrat :</p>
            <br />
            <img class="tab-prime-surplus" alt="Tableau Prime Surplus" src="assets/tab_prime_surplus.png" />
            <br />
            <p>
              *à la différence du consommateur qui attend la facture de son fournisseur pour payer, en tant que
              producteur c'est à vous de facturer SOREGIES pour bénéficier de la vente de l'électricité que vous avez
              produite. Ainsi pour faciliter les producteurs nous avons mis en place un Espace Producteur conforme à la
              règlementation pour l’édition de vos factures de production : vous devez donc saisir votre index de
              production a la période prévue par votre contrat. La saisie de votre index générera une facture.
            </p>
            <p>Nous vous rappelons que la facturation est de votre responsabilité.</p>
            <p>Vous trouverez le détail du montant de votre prime sur votre facture validée.</p>
            <br />
            <p>
              **La date de prise d’effet de votre contrat est inscrite dans votre contrat : paragraphe « DATE DE PRISE
              D'EFFET, DUREE DU CONTRAT » des conditions particulières. Sachez que votre contrat est règlementé, par
              conséquent les dates de déclarations ne sont pas modifiables ni réalisées au hasard.
            </p>
            <p>Elles sont programmées selon la prise d’effet de votre contrat (date d’anniversaire).</p>
          </app-collapse>
          <app-collapse title="Puis-je négocier mon tarif d'achat ?">
            <p>
              La négociation des tarifs d'achat n'est pas possible. Les tarifs sont fixés conformément à l'arrêté
              tarifaire en vigueur et aux conditions en place au moment de la demande de raccordement de votre
              installation.
            </p>
            <br />
            <p>
              Le tarif auquel vous vendez le kilowattheure d’électricité photovoltaïque est déterminé par la
              réglementation dans le cadre de l’obligation d’achat. En tant que producteur, vous injectez de
              l’électricité dans le réseau, et l’acheteur est légalement tenu d’acheter cette énergie à un prix
              réglementé.
            </p>
            <br />
            <p>
              Notez que pour certains contrats, le tarif est indexé annuellement, ce qui signifie qu'il peut évoluer en
              fonction des ajustements prévus par la réglementation.
            </p>
          </app-collapse>
          <app-collapse title="Pourquoi la saisie de mon index est-elle interdite ?">
            <p>
              Le délai pour la saisie de votre index est dépassé. Dès réception de l’e-mail vous invitant à saisir votre
              index, nous vous encourageons à le faire rapidement.
            </p>
            <br />
            <p>
              En tant que producteur d’électricité, contrairement aux consommateurs qui reçoivent une facture de leur
              fournisseur, il est de votre responsabilité de facturer SOREGIES pour la vente de l'électricité que vous
              produisez.
            </p>
            <br />
            <p>
              Votre contrat a été établi conformément à la réglementation en vigueur, avec une obligation de déclarer
              vos index et de facturer selon des périodes prédéfinies. Ces dates de déclaration, basées sur la date
              d’anniversaire de votre contrat, sont fixes et ne peuvent être modifiées.
            </p>
            <br />
            <p>
              <b>Vous avez deux options :</b>
            </p>
            <br />
            <ol>
              <li>
                <p>
                  <b>Déclaration manuelle :</b> Vous pouvez nous transmettre votre relevé d’index via le formulaire en
                  ligne, en précisant la date de relevé. Nous enregistrerons l’index pour vous. Toutefois, cette
                  procédure doit rester exceptionnelle.
                </p>
                <br />
              </li>
              <li>
                <p>
                  <b>Prochaine échéance :</b> Vous pouvez attendre la prochaine période de facturation. Les informations
                  sur votre calendrier de facturation sont disponibles dans l’onglet
                  <a class="rubrique" routerLink="/home/contracts">« Mes contrats »</a>.
                </p>
                <br />
              </li>
            </ol>
          </app-collapse>
          <br />
        </div>
        <div id="mpt-related-questions" [hidden]="activeButton !== 'mpt'">
          <div class="sub-title">Questions liées au compteur</div>
          <app-collapse title="Comment relever mes index ?">
            <p>Un mode opératoire est disponible pour vous aider à relever vos index de production</p>
            <div class="fit-div">
              <app-download-file
                id="doc-relever-index"
                link="https://static.soregies.fr/divers/COMMENT+RELEVER+SES+INDEX.pdf"
                [title]="'Comment relever ses index'"
              ></app-download-file>
            </div>
          </app-collapse>
          <app-collapse
            title="
              J’ai eu un changement de compteur pour bénéficier d’un compteur Linky. Que dois-je faire ?
            "
          >
            <p>
              Rien. L’agent en charge de l’intervention a réalisé la relève d’index de production sur votre ancien
              compteur. La pose du compteur LINKY remet à 0 votre index.
            </p>
            <p>
              Dans un délai d’environ un mois, votre index de dépose sera transmis à votre acheteur qui pourra alors
              générer une facture afin de vous régler la production entre la précédente relève et la pose du compteur
              LINKY.
            </p>
            <p>
              Toutefois, si vous constatez que le nécessaire n’a pas été fait dans le délai imparti, nous vous prions de
              nous contacter via le
              <a routerLink="/home/contact" class="rubrique"><em class="ph-envelope"></em> formulaire de contact</a>
            </p>
          </app-collapse>
          <app-collapse
            title="
              Dois-je continuer à communiquer mes index après la pose d’un compteur Linky ?
            "
          >
            <p>Oui, vous devez continuer à communiquer vos index selon le processus habituel.</p>

            <p>
              A toutes fins utiles, vous trouverez ci-dessous le mode opératoire vous permettant de relever votre index
              sur le compteur LINKY.
            </p>
            <img
              alt="boitier linky"
              src="https://www.soregies.fr/wp-content/uploads/sites/10/2022/05/image-768x379.png"
            />
            <p>La relève de votre index doit se faire exclusivement sur votre compteur de production.</p>
            <a href="https://www.linkyparsrd.fr/">Tout savoir sur le compteur Linky.</a>
          </app-collapse>
          <app-collapse
            title="
              J’ai un dysfonctionnement sur mon compteur, à qui m’adresser ?
            "
          >
            <p>
              Toute demande technique est traitée par l’<a href="https://www.soregies.fr/contact/">agence SORÉGIES</a>
              la plus proche de chez vous.
            </p>
          </app-collapse>
          <app-collapse
            title="
              J’ai un problème sur mon installation (panneaux / onduleurs), que dois-je faire ?
            "
          >
            <p>Vous devez consulter un installateur. SORÉGIES ou SRD n’interviendront pas sur votre installation</p>
          </app-collapse>
        </div>
      </div>
    </div>
    <div class="center-div">
      <p class="p-answer">Vous n'avez pas trouvé votre réponse ? Contactez-nous !</p>
      <button class="default-btn" type="button" mat-flat-button id="infoBtn" routerLink="/home/contact">
        <i class="ph ph-at"></i>
        Formulaire de contact
      </button>
    </div>
  </mat-card>
</div>
