import { Component, Input, OnInit } from '@angular/core';
import { MessageReportVariant } from '@app/shared/components/report-message/report-message.model';
import { ContractDataResponse } from '@app/shared/interfaces/contract.interface';

@Component({
  selector: 'app-change-consent',
  templateUrl: './change-consent.component.html',
  styleUrls: ['./change-consent.component.scss'],
})
export class ChangeConsentComponent implements OnInit {
  @Input() contracts: ContractDataResponse[] = [];

  protected contractsSize: number = 0;

  protected MessageReportVariant = MessageReportVariant;

  protected displayedColumns = ['dossier', 'titulaire', 'contract', 'prm'];

  public ngOnInit(): void {
    this.contractsSize = this.contracts.length;
  }
}
