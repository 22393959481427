import { Component, ElementRef, ViewChild } from '@angular/core';
import { FeedItems } from '@app/shared/models/FeedItems';

@Component({
  selector: 'app-rss-feed',
  templateUrl: './rss-feed.component.html',
  styleUrls: ['./rss-feed.component.scss'],
})
export class RssFeedComponent {
  feedItemList: FeedItems[] = [
    {
      title: 'Les enjeux et les démarches universelles associés au photovoltaïque',
      link: 'https://www.photovoltaique.info/fr/',
      pubDate: undefined,
    },
    {
      title: 'Quelques détails sur l’autoconsommation et autoproduction',
      link: 'https://www.photovoltaique.info/fr/preparer-un-projet/quel-modele-economique/autoconsommation-partielle-ou-totale/',
      pubDate: undefined,
    },
    {
      title: 'Arrêtés tarifaires photovoltaïques en métropole',
      link: 'https://www.cre.fr/Pages-annexes/open-data',
      pubDate: undefined,
    },
    {
      title: 'Le Solaire par le ministère de la transition énergétique',
      link: 'https://www.ecologie.gouv.fr/solaire',
      pubDate: undefined,
    },
    {
      title: 'Pour y voir + clair sur le solaire',
      link: 'https://www.ecologie.gouv.fr/y-voir-clair-sur-solaire',
      pubDate: undefined,
    },
    {
      title: 'Installation de panneaux solaires : vous avez droit à des aides !',
      link: 'https://www.economie.gouv.fr/particuliers/aides-installation-photovoltaiques',
      pubDate: undefined,
    },
  ];

  @ViewChild('carousel', { static: false }) carousel!: ElementRef;

  scrollLeft(): void {
    this.carousel.nativeElement.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  }

  scrollRight(): void {
    this.carousel.nativeElement.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  }

  // rssList: { url : string, parser: any }[] = [
  //   { url: 'https://www.ecologie.gouv.fr/rss_actualites.xml', parser: DateTime.fromISO },
  //   { url: 'https://www.photovoltaique.info/fr/actualites/rss/', parser: DateTime.fromRFC2822 },
  //   { url: 'https://www.ecologie.gouv.fr/rss_presse.xml', parser: parserFormat },
  // ];
}
