import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EmbeddedViewRef,
  QueryList,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Color } from '@app/shared/models/color';
import { Size, Style } from '@app/components/icon/icon.component';
import { CarouselDirective } from './carousel.directive';

const CAROUSEL_DELAY_MS: number = 10000;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  currentPageNumber = 0;

  private viewsTop: EmbeddedViewRef<any>[] = [];

  private viewsBottom: EmbeddedViewRef<any>[] = [];

  // @ts-ignore
  private timeoutId?: NodeJS.Timeout;

  @ContentChildren(CarouselDirective) carouselItemEls!: QueryList<CarouselDirective>;

  @ViewChild('currentPageTop', { read: ViewContainerRef }) currentPageTop!: ViewContainerRef;

  @ViewChild('currentPageBottom', { read: ViewContainerRef }) currentPageBottom!: ViewContainerRef;

  protected readonly Style = Style;

  protected readonly Size = Size;

  protected readonly Color = Color;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.carouselItemEls.changes.subscribe({
      next: () => {
        if (this.viewsTop.length <= 0 || this.viewsBottom.length <= 0) {
          this.createView();
        }
        this.updateView();
        this.resetTimer();
      },
    });
    this.carouselItemEls.notifyOnChanges();
  }

  private resetTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => this.nextPage(), CAROUSEL_DELAY_MS);
  }

  private createView() {
    // Diviser les éléments de carrousel en deux groupes, top et bottom
    this.carouselItemEls.forEach((carouselItemEl, index) => {
      const view =
        index % 2 === 0
          ? this.currentPageTop.createEmbeddedView(carouselItemEl.templateRef)
          : this.currentPageBottom.createEmbeddedView(carouselItemEl.templateRef);

      if (index % 2 === 0) {
        this.viewsTop.push(view);
      } else {
        this.viewsBottom.push(view);
      }
    });
    this.changeDetector.detectChanges();
  }

  private updateView() {
    // Mettre à jour les vues en fonction de la page active
    this.updatePageView(this.viewsTop);
    this.updatePageView(this.viewsBottom);
    this.changeDetector.detectChanges();
  }

  private updatePageView(views: EmbeddedViewRef<any>[]) {
    views.forEach((view, index) => {
      const [carouselItem] = view.rootNodes;
      this.renderer.removeClass(carouselItem, 'next-item');
      this.renderer.removeClass(carouselItem, 'prev-item');
      this.renderer.removeClass(carouselItem, 'anim');

      if (this.currentPageNumber < index) {
        this.renderer.addClass(carouselItem, 'next-item');
      }

      if (this.currentPageNumber > index) {
        this.renderer.addClass(carouselItem, 'prev-item');
      }

      if (this.currentPageNumber === index) {
        this.renderer.addClass(carouselItem, 'anim');
      }
    });
  }

  get totalPages() {
    return this.carouselItemEls ? Math.ceil(this.carouselItemEls.length / 2) : 0;
  }

  prevPage() {
    let prevPage = this.currentPageNumber - 1;
    if (prevPage < 0) {
      prevPage = this.totalPages - 1;
    }
    this.changePage(prevPage);
  }

  nextPage() {
    let nextPage: number = this.currentPageNumber + 1;
    if (nextPage >= this.totalPages) {
      nextPage = 0;
    }
    this.changePage(nextPage);
  }

  changePage(page: number) {
    this.currentPageNumber = page;
    this.carouselItemEls.notifyOnChanges();
  }
}
