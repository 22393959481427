<div class="paginator-container">
  <fieldset [disabled]="disabled">
    <legend></legend>
    <span *ngIf="(isHandset$ | async) === false; else mobileVersion"
      >{{ itemProperties.shown }} résultat{{ itemProperties.shown > 1 ? 's' : '' }} sur {{ itemProperties.total }}</span
    >
    <ng-template #mobileVersion>
      <span #mobileVersion>{{ itemProperties.shown }} / {{ itemProperties.total }}</span>
    </ng-template>
    <ul class="paginator">
      <li>
        <button
          class="select-page-btn"
          [disabled]="isLoading || currentPage === 1"
          (click)="!isLoading && changePage(currentPage - 1)"
        >
          <em class="ph-caret-left-bold"></em>
        </button>
      </li>

      <li *ngIf="totalPage > 5">
        <button
          class="select-page-btn {{ currentPage === 1 && 'active' }}"
          [disabled]="isLoading"
          (click)="!isLoading && changePage(1)"
        >
          {{ totalPage ? '1' : '0' }}
        </button>
      </li>

      <li *ngIf="selectPages[0] >= 3">
        <span>...</span>
      </li>

      <li *ngFor="let page of selectPages">
        <button
          class="select-page-btn {{ currentPage === page && 'active' }}"
          [disabled]="isLoading"
          (click)="!isLoading && changePage(page)"
        >
          {{ page }}
        </button>
      </li>

      <li *ngIf="selectPages[2] <= totalPage - 2 && totalPage > 5">
        <span>...</span>
      </li>

      <li *ngIf="totalPage > 5">
        <button
          class="select-page-btn {{ currentPage === totalPage && 'active' }}"
          [disabled]="isLoading || currentPage === totalPage || totalPage === 0"
          (click)="!isLoading && changePage(totalPage)"
        >
          {{ totalPage }}
        </button>
      </li>

      <li>
        <button
          class="select-page-btn"
          [disabled]="isLoading || currentPage === totalPage || totalPage === 0"
          (click)="!isLoading && changePage(currentPage + 1)"
        >
          <em class="ph-caret-right-bold"></em>
        </button>
      </li>
    </ul>
    <div class="displayed-item-selected-container">
      <span *ngIf="(isHandset$ | async) === false">Nombre de résultats par page : </span>
      <div class="diplayer-select-dropdown-menu">
        <button
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"
          id="displayerSelectBtn"
          mat-stroked-button
          [disabled]="isLoading"
        >
          <span>{{ itemLimit }}</span>
          <em class="{{ menuTrigger.menuOpen ? 'ph-caret-up-bold' : 'ph-caret-down-bold' }}"></em>
        </button>
        <mat-menu #menu="matMenu" class="action-menu" xPosition="before">
          <button
            *ngFor="let itemLimit of itemLimitArray"
            (click)="changeDisplayedItemNumber(itemLimit)"
            id="downloadBtn"
            mat-menu-item
            [disabled]="isLoading"
          >
            <span>{{ itemLimit }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </fieldset>
</div>
