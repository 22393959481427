import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import {
  ContractDataResponse,
  ContractResponse,
  GetContractsParams,
  PutContractsRequest,
} from '@app/shared/interfaces/contract.interface';
import { User } from '@app/shared/models/user-info';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { appendParams } from '@app/shared/utils/services-utils';
import { environment } from '@environments/environment';
import { Observable, Subject, map, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractsService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  public getContracts(
    params: {
      limit?: number;
      offset?: number;
      sortBy?: string;
      search?: string;
      partnerId?: string | null;
      filter?: string[];
    },
    isHistory: boolean,
  ): Observable<ContractResponse> {
    if (isHistory) {
      return this.httpClient
        .get(`${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS_HISTORY}`, {
          params: appendParams(params),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .pipe(map((res: GetContractsParams) => res)) as Observable<ContractResponse>;
    }
    return this.httpClient
      .get(`${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS}`, {
        params: appendParams(params),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: GetContractsParams) => res)) as Observable<ContractResponse>;
  }

  public updateContracts(
    contracts: PutContractsRequest,
    params: {
      partnerId?: string | null;
    },
  ): Observable<ContractDataResponse[]> {
    return this.httpClient
      .patch(`${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS}${API_ROUTES.CONSENT}`, contracts, {
        params: appendParams(params),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res) => res)) as Observable<ContractDataResponse[]>;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
