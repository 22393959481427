<div class="form-container">
  <form (ngSubmit)="submit()" [formGroup]="this.forgotPasswordForm">
    <div class="forgot-password-form">
      <div class="header">
        <button (click)="handleBack()" class="menu-btn" type="button" mat-flat-button>
          <mat-icon>arrow_back</mat-icon>
          <span>Revenir en arriere</span>
        </button>
      </div>
      <h2 class="title">Mot de passe oublié</h2>
      <div class="text-input-container input-container">
        <mat-label>
          Vous avez oublié votre mot de passe ? <br />
          Merci de renseigner votre email, un code de confirmation vous sera envoyé dans quelques secondes.
        </mat-label>
        <app-input
          id="emailOwner"
          type="email"
          placeholder="Email associé à votre compte"
          [formCtrl]="forgotPasswordForm.get('email')! | formControl"
        >
        </app-input>
        <mat-hint *ngIf="!displayResendPassword"
          >Si vous n'avez pas reçu de code de confirmation, vérifier votre adresse email
        </mat-hint>

        <mat-error *ngIf="displayResendPassword"
          >Votre compte n'a pas été activé, c'est pourquoi aucun code n'a été envoyé. Si votre mot de passe temporaire
          est perdu ou expiré, veuillez génerer un nouveau mot de passe temporaire.
        </mat-error>
      </div>

      <div class="submit-form">
        <button
          *ngIf="!displayResendPassword"
          [disabled]="loading || this.forgotPasswordForm.controls.email.invalid"
          class="default-btn"
          id="forgotpassword-validate"
          mat-flat-button
          type="submit"
        >
          <span *ngIf="!loading">Envoyer le code de confirmation</span>
          <i *ngIf="!loading" class="ph ph-paper-plane-right"></i>
          <mat-spinner *ngIf="loading" class="default-loader" [diameter]="30"></mat-spinner>
        </button>

        <button
          *ngIf="displayResendPassword"
          class="default-btn"
          id="resend-btn"
          mat-flat-button
          type="submit"
          (click)="generatePassword()"
          [disabled]="loading || this.forgotPasswordForm.controls.email.invalid"
        >
          <span *ngIf="!loading" id="button-text">Générer un mot de passe temporaire</span>
          <mat-spinner *ngIf="loading" [diameter]="30" class="default-loader" id="mat-spinner"></mat-spinner>
        </button>
      </div>
    </div>
  </form>
</div>
