import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Size } from '@app/components/icon/icon.component';
import { FilterItem } from './filter.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() label: string = '';

  @Input() data: string[] = [];

  @Input() defaultValues: string[] | null = null;

  @Input() filterType: 'single' | 'multi' = 'single';

  @Input() filterTranslate?: (value: string) => string;

  @Output() filterChange = new EventEmitter<any>();

  @ViewChild('connectedEl') protected connectedEl!: ElementRef;

  @ViewChild(CdkPortal) protected contentTemplate!: CdkPortal;

  private overlayRef!: OverlayRef;

  protected Size = Size;

  filterItems: FilterItem[] = [];

  isOpen: boolean = false;

  constructor(private overlay: Overlay) {}

  ngOnInit() {
    this.filterItems = this.data.map((item) => ({
      value: item,
      checked: false,
    }));

    if (this.defaultValues) {
      this.defaultValues.forEach((value) => {
        const filterItem = this.filterItems.find((item) => item.value === value);
        if (filterItem) {
          filterItem.checked = true;
        }
      });
    }
  }

  onOpenFilter() {
    this.isOpen = true;
    this.createOverlay();
  }

  hide() {
    this.isOpen = false;
    this.overlayRef.detach();
  }

  onFilterItemClick(item: FilterItem) {
    let newItems;
    if (this.filterType === 'multi') {
      newItems = this.filterItems.map((filterItem) => {
        if (filterItem.value === item.value) {
          return {
            ...filterItem,
            checked: !filterItem.checked,
          };
        }
        return filterItem;
      });
    } else {
      newItems = this.filterItems.map((filterItem) => {
        if (filterItem.value === item.value) {
          return {
            ...filterItem,
            checked: !filterItem.checked,
          };
        }
        return {
          ...filterItem,
          checked: false,
        };
      });
    }
    this.filterItems = newItems;
    // send only checked values
    this.filterChange.emit(
      this.filterItems.filter((filteredItem) => filteredItem.checked).map((filteredItem) => filteredItem.value),
    );
  }

  onKeyDownFilterItem(item: FilterItem) {
    this.onFilterItemClick(item);
  }

  createOverlay() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.overlayRef.backdropClick().subscribe(() => this.hide());
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.connectedEl.nativeElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }
}
