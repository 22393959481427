<div class="form-container">
  <div class="resend-password-form">
    <mat-hint
      >Si votre mot de passe temporaire est perdu ou expiré, veuillez génerer un nouveau mot de passe
      temporaire.</mat-hint
    >
    <button class="generate-btn" id="resend-btn" mat-flat-button type="submit" (click)="generatePassword()">
      <span *ngIf="!loading" id="button-text">Générer un mot de passe temporaire</span>
      <mat-spinner *ngIf="loading" [diameter]="30" class="default-loader" id="mat-spinner"></mat-spinner>
    </button>
  </div>
</div>
