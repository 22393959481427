<mat-form-field class="password-input" hideRequiredMarker>
  <input
    id="{{ id }}"
    matInput
    [formControl]="formCtrl"
    [type]="isPasswordVisible ? 'text' : 'password'"
    [placeholder]="placeholder"
    (keyup)="onChange()"
    (change)="onChange()"
  />
  <em
    id="togglePasswordVisibility"
    (click)="onTogglePasswordVisibility()"
    class="ph-{{ isPasswordVisible ? 'eye' : 'eye-closed' }}"
  ></em>
</mat-form-field>
