<div class="main-container-producer">
  <app-login-header></app-login-header>

  <app-profile-selector
    [profiles]="profiles"
    [searchPlaceholder]="'Rechercher par nom ou email'"
    [showSearch]="true"
    [showPagination]="true"
    [itemsPerPage]="10"
    (profileSelected)="onProfileSelected($event)"
  ></app-profile-selector>
</div>
