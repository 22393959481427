<div class="confirm-dialog" [ngStyle]="dimensions">
  <div class="dialog-container">
    <span class="title">{{ data.title }}</span>
    <ng-template #messageTemplate>
      <div class="dialog-content">
        <p [innerHTML]="data.message" class="dialog-message"></p>
      </div>
    </ng-template>

    <ng-container
      *ngTemplateOutlet="
        data.dialogContent ?? messageTemplate;
        context: { setEnable: this.setEnable, setLoading: this.setLoading }
      "
    >
    </ng-container>
  </div>
  <div class="dialog-actions">
    <button class="btn btn-cancel" mat-flat-button *ngIf="data.cancelText" (click)="cancel()">
      {{ data.cancelText }}
    </button>
    <button
      class="default-btn btn btn-confirm"
      mat-flat-button
      (click)="confirm()"
      [disabled]="!enableConfirmButton || loading"
    >
      {{ data.confirmText }}
    </button>
  </div>
</div>
