import { IndexDataResponse, IndexEventDetails } from '@app/shared/interfaces/index.interface';
import { DateTime } from 'luxon';

export function isNext(index: IndexDataResponse | IndexEventDetails) {
  const dateTPF = DateTime.fromFormat(index.theoreticalDateNextInvoice, 'yyyy-MM-dd').set({ day: 1 });
  const now = DateTime.now().set({ hour: 0 });
  return now < dateTPF;
}

export function isPast(index: IndexDataResponse | IndexEventDetails) {
  const dateTPF = DateTime.fromFormat(index.theoreticalDateNextInvoice, 'yyyy-MM-dd').set({ day: 1 });
  const now = DateTime.now().set({ hour: 0 });
  if (index?.contractLine?.invoicingRhythmSelect === '1') {
    return dateTPF.plus({ months: 1 }) < now;
  }
  return dateTPF.plus({ months: 3 }) < now;
}

export function isCloseOrContest(index: IndexDataResponse | IndexEventDetails, ste: 'contest' | 'close') {
  const tenDaysAgo = DateTime.now().minus({ days: ste === 'contest' ? 10 : 11 });

  if (!index.indexDate || !index.invoicedOk) {
    return false;
  }

  const indexDate = DateTime.fromISO(index.indexDate);
  const formattedTenDaysAgo = tenDaysAgo.toFormat('yyyy-MM-dd');
  const formattedIndexDate = indexDate.toFormat('yyyy-MM-dd');

  if (ste === 'contest') {
    return (
      index.invoicedOk &&
      index.contractLine.consentOk &&
      (index.manualEntry === null || !index.manualEntry) &&
      formattedIndexDate >= formattedTenDaysAgo
    );
  }

  // For 'close' state
  if (index.contractLine.consentOk) {
    if (index.manualEntry) {
      return index.invoicedOk;
    }
    return indexDate.isValid && indexDate < tenDaysAgo;
  }

  return index.invoicedOk;
}

export function state(index: IndexDataResponse | IndexEventDetails) {
  if (index && isCloseOrContest(index, 'close')) return 'close';
  if (index && isCloseOrContest(index, 'contest')) return 'contest';
  if (index && isPast(index)) return 'forbidden';
  if ((index && isNext(index)) || (index && !index.emailSentOk)) return 'next';
  return 'open';
}

export function isEditable(index: IndexEventDetails) {
  const dateTPF = DateTime.fromFormat(index.theoreticalDateNextInvoice, 'yyyy-MM-dd').set({ day: 1 });
  const now = DateTime.now().set({ hour: 0 });
  if (index?.contractLine?.invoicingRhythmSelect === '1') {
    return dateTPF < now && now < dateTPF.plus({ months: 1 });
  }
  return dateTPF < now && now < dateTPF.plus({ months: 3 });
}

export function getEditLabel(edit: string) {
  switch (edit) {
    case 'open':
      return 'Ouverte';
    case 'close':
      return 'Fermée';
    case 'forbidden':
      return 'Interdite';
    case 'next':
      return 'À venir';
    case 'contest':
      return 'Contestable';
    default:
      return '';
  }
}
