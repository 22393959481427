<div class="forbidden-index-event-container">
  <div class="forbidden-index-title">
    <h3>Saisies interdites</h3>
  </div>
  <app-report-message
    align="left"
    message="<h4>Pourquoi la saisie de mon index est-elle interdite ?</h4><br><p>
      Le délai pour la saisie de votre index est dépassé. Dès réception de l’e-mail vous invitant à saisir votre index, nous vous encourageons à le faire rapidement.
    </p>
    <br/>
    <p>
      En tant que producteur d’électricité, contrairement aux consommateurs qui reçoivent une facture de leur fournisseur, il est de votre responsabilité de facturer SOREGIES pour la vente de l'électricité que vous produisez.
    </p>
    <br/>
    <p>
      Votre contrat a été établi conformément à la réglementation en vigueur, avec une obligation de déclarer vos index et de facturer selon des périodes prédéfinies. Ces dates de déclaration, basées sur la date d’anniversaire de votre contrat, sont fixes et ne peuvent être modifiées.
    </p>
    <br/>
    <p>
      <b>Vous avez deux options :</b>
    </p>
    <br/>
    <ol>
      <li>
        <p><b>Déclaration manuelle :</b> Vous pouvez nous transmettre votre relevé d’index via le formulaire en ligne, en précisant la date de relevé. Nous enregistrerons l’index pour vous. Toutefois, cette procédure doit rester exceptionnelle.</p>
        <br/>
      </li>
      <li>
        <p><b>Prochaine échéance :</b> Vous pouvez attendre la prochaine période de facturation. Les informations sur votre calendrier de facturation sont disponibles dans l’onglet « Mon Contrat ».</p>
        <br/>
      </li>
    </ol>
  "
  ></app-report-message>
  <div class="table-container">
    <table [dataSource]="dataSource" class="default-table" id="index-table" mat-table>
      <caption></caption>
      <ng-container matColumnDef="contractLine.contractLineId">
        <th *matHeaderCellDef mat-header-cell>Contrat</th>
        <td *matCellDef="let element" class="default-cell default-column-type-semibold-center" mat-cell>
          {{ element?.contractLine.contractLineId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ownerPartner.mainContact.fullName">
        <th *matHeaderCellDef mat-header-cell class="left">Titulaire</th>
        <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
          {{ element?.ownerPartner.mainContact.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractLine.mpt.code">
        <th *matHeaderCellDef class="center" mat-header-cell>Point de comptage</th>
        <td *matCellDef="let element" class="default-cell default-column-type-semibold-left" mat-cell>
          {{ element?.contractLine.mpt.code }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractLine.mpt.address.fullName">
        <th *matHeaderCellDef class="left" mat-header-cell>Adresse du point de comptage</th>
        <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
          {{ element?.contractLine.mpt.address.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="theoreticalDateNextInvoice">
        <th *matHeaderCellDef mat-header-cell>Date TPF</th>
        <td *matCellDef="let element" class="default-cell date-column-type" mat-cell>
          {{ element?.theoreticalDateNextInvoice | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <tr *matHeaderRowDef="forbiddenIndexHead; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let element; columns: forbiddenIndexHead" mat-row></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="default-cell no-data" *ngIf="!isLoading" colspan="4">
          {{ noDataMessage }}
        </td>
        <ng-container *ngIf="isLoading">
          <td *ngFor="let column of forbiddenIndexHead">
            <div class="loading-table default-cell default-column-type-regular-center">
              <ngx-skeleton-loader [theme]="{ 'height.rem': 4 }" count="3"></ngx-skeleton-loader>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  </div>
</div>
