<div class="main-container">
  <section *ngIf="!error" class="section-container">
    <div class="section-content">
      <div class="section-header">
        <app-buttons [type]="'borderedBackBtn'" (clickAction)="handleBack()"></app-buttons>
        <div class="title-container">
          <h1 class="title" id="contract-name">
            {{ !this.isHistory ? 'Mon Contrat' : 'Mon contrat avant cession' }}
          </h1>
        </div>
      </div>

      <app-report-message
        *ngIf="contractDetails?.status?.code === 'ACT'"
        align="left"
        title="Consentement auto-facturation"
        [messageTemplate]="consentementTemplate"
      ></app-report-message>

      <ng-template #consentementTemplate>
        <ng-container
          *ngIf="
            contractDetails?.mpt?.exchangeRef === '' || contractDetails?.mpt?.exchangeRef === undefined;
            else elseBlock
          "
        >
          <p><b>Impossibilité d’effectuer la gestion de votre auto-facturation grâce à la relève à distance</b></p>
          <br />

          <p class="m-b-5">
            Nous ne pouvons pas gérer votre auto-facturation pour le moment en raison d’une ou des raisons suivantes :
          </p>
          <li>Absence de compteur Linky lié à votre production d’énergie.</li>
          <li>
            Un changement récent de votre compteur, pour lequel nous n'avons pas encore reçu toutes les données
            nécessaires
          </li>
          <br />
          <p class="m-b-5"><b>Solutions :</b></p>
          <li>
            <b>Pas de compteur Linky ?</b> Contactez votre gestionnaire de réseau SRD pour demander son installation.
          </li>
          <li>
            <b>Changement de compteur récent ?</b> Patientez quelques jours pour la mise à jour. Si vous ne pouvez
            toujours pas donner votre consentement pour l’auto-facturation dans plusieurs mois, merci de bien vouloir
            nous contacter afin que nous puissions résoudre ce problème.
          </li>
        </ng-container>

        <ng-template #elseBlock>
          <ng-container *ngIf="contractDetails?.userSegment?.code === 'P4'">
            <div class="d-flex align-item-center f-16">
              <app-checkbox
                (checkedChange)="onChangeConsent($event)"
                aria-label="check-consent"
                [checked]="!contractDetails || contractDetails.consentOk || false"
              ></app-checkbox>
              <p class="m-l-5">
                <b>Je souhaite que SOREGIES gère la facturation de ma production à la place</b>, grâce aux relevés de
                mon compteur LINKY. <b>Je n’ai plus à déclarer mes index, tout est automatisé</b>. Je peux profiter du
                soleil pendant qu’il travaille pour moi !
              </p>
            </div>
            <br />
            <p class="f-13">
              <i
                >En cochant cette case, je donne mon consentement explicite pour que SOREGIES procède à
                l’auto-facturation de ma production en récupérant mes données de production auprès du gestionnaire de
                réseau de distribution SRD, collectées à distance via mon compteur LINKY. La télérelève s’effectuera
                conformément à la fréquence de facturation définie dans mon contrat, et seules les données de production
                liées à mon Point de Livraison (PDL) seront collectées. Je suis informée(e) que :</i
              >
            </p>
            <ul>
              <li class="f-13 line-h-25">
                <i
                  >Si la relève à distance ne fonctionne pas, je serais averti(e) par mail ou SMS afin de déclarer mon
                  index.</i
                >
              </li>
              <li class="f-13">
                <i>Je dispose d’un délai de 10 jours pour contester l’index relevé et facturé.</i>
              </li>
            </ul>
            <br />
            <p class="f-16">
              Je pourrais à tout moment choisir de déclarer moi-même mes index de production en modifiant mon choix. Si
              je ne coche pas cette case, je dois continuer de déclarer ma production via mon espace producteur.
            </p>
          </ng-container>
          <ng-container *ngIf="contractDetails?.userSegment?.code !== 'P4'">
            <div>
              A ce jour, la technologie ne nous permet pas encore de récupérer les index de production pour les
              installations supérieures à 36 kVA. Pas de panique ! Nous sommes sur le coup, armés de café et de bonne
              volonté. Nous faisons de notre mieux pour vous offrir ce service le plus vite possible.<br />
              Restez à l’affût, car on n’est jamais à l’abri d’une belle surprise technologique.
            </div>
          </ng-container>
        </ng-template>
      </ng-template>

      <div class="contract-content">
        <div class="part">
          <h3>Gestion du contrat n° {{ contractDetails?.mpt?.code }}</h3>
          <div class="main-information-grid">
            <app-main-information
              id="contract"
              title="Dossier"
              [value]="contractDetails?.contractLineId"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="holder"
              title="Titulaire"
              [value]="contractDetails?.ownerPartner?.name"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="mpt"
              title="Contrat"
              [value]="contractDetails?.mpt?.code"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="mptAddress"
              title="Adresse du point de comptage"
              [value]="contractDetails?.mpt?.address?.fullName"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="supplyStartDate"
              title="Date de prise d'effet du contrat"
              [type]="'date'"
              [value]="contractDetails?.startDate"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="supplyEndDate"
              title="Date de fin"
              [type]="'date'"
              [value]="contractDetails?.endDate"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="decreeDate"
              title="Arrêté fixant les conditions d’achat de l’électricité produite"
              [value]="contractDetails?.decree?.nameProducerArea"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              *ngIf="contractDetails?.mpt?.exchangeRef"
              id="exchangeRef"
              title="Numéro de PRM"
              [value]="contractDetails?.mpt?.exchangeRef"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="signatureDate"
              title="Date demande complète de raccordement"
              [type]="'date'"
              [value]="contractDetails?.signatureDate"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="billingRate"
              title="Rythme de facturation"
              [value]="this.billingRythm(contractDetails?.invoicingRhythmSelect)"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="salesType"
              title="Type de vente"
              [value]="this.salesType(contractDetails?.salesTypeSelect)"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="peakPower"
              title="Puissance crête"
              [value]="(contractDetails?.peakPower ?? 0 | number: '1.0-2' : 'fr-FR') + ' kWc'"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              *ngIf="!this.isHistory"
              id="status"
              title="Statut"
              [value]="contractDetails?.status?.name"
              [loading]="isLoading"
            ></app-main-information>
          </div>
        </div>
      </div>
      <div class="subpart-content" *ngIf="displayPrime()">
        <div class="part">
          <h3>Prime surplus</h3>
          <div class="main-information-grid">
            <app-main-information
              id="bonusOnMore"
              title="Prime surplus"
              [value]="contractDetails?.reward"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="remainingBonusOnMore"
              title="Prime surplus restante"
              [value]="contractDetails?.rewardRemaining"
              [loading]="isLoading"
            ></app-main-information>
          </div>
        </div>
      </div>
      <div class="subpart-content" *ngIf="!displayPrime()">
        <div class="part">
          <h3>Coefficient L</h3>
          <div class="main-information-grid">
            <app-main-information
              id="coeffL"
              title="Valeur coefficient L"
              [value]="contractDetails?.activeLValue"
              [loading]="isLoading"
            ></app-main-information>
          </div>
        </div>
      </div>
      <div class="subpart-content" *ngIf="!this.isHistory">
        <div class="part">
          <h3>Prix de rachat de l'énergie</h3>
          <div class="main-information-grid">
            <app-main-information
              id="currentRepurchasePriceBelowCap"
              title="Prix de rachat actuel sous le plafond"
              [value]="contractDetails?.energyBuyoutPrice + ' €/kWh'"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="currentRepurchasePriceAboveCap"
              title="Prix de rachat actuel au-dessus du plafond"
              [value]="contractDetails?.energyBuyoutPriceBeyondCap + ' €/kWh'"
              [loading]="isLoading"
            ></app-main-information>
            <app-main-information
              id="engInvest"
              title="Engagement à l\'investissement participatif"
              [value]="contractDetails?.participatoryInvestmentEngagement + ' €/kWh'"
              [loading]="isLoading"
              *ngIf="contractDetails?.decree?.code === 'FV16'"
            ></app-main-information>
            <app-main-information
              title="Engagement à un financement participatif"
              [value]="contractDetails?.participatoryFundingEngagement + ' €/kWh'"
              id="engFund"
              [loading]="isLoading"
              *ngIf="contractDetails?.decree?.code === 'FV16'"
            ></app-main-information>
            <app-main-information
              title="Prix majoré de rachat actuel sous le plafond"
              [value]="getPriceMajUnder(contractDetails) + ' €/kWh'"
              id="priceMajUnder"
              [loading]="isLoading"
              *ngIf="contractDetails?.decree?.code === 'FV16'"
            ></app-main-information>
            <app-main-information
              title="Prix majoré de rachat actuel au-dessus du plafond"
              [value]="getPriceMajBeyond(contractDetails) + ' €/kWh'"
              id="priceMajBeyond"
              [loading]="isLoading"
              *ngIf="contractDetails?.decree?.code === 'FV16'"
            ></app-main-information>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="!error && !displayPrime() && !this.isHistory" class="section-container">
    <div class="section-content">
      <div class="section-header">
        <div class="title-container">
          <h3 class="title">Historique sur les années précédentes</h3>
        </div>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="priceHistory" class="default-table" id="test">
          <caption></caption>
          <ng-container matColumnDef="historyDate">
            <th *matHeaderCellDef class="left" mat-header-cell>Date d'historisation</th>
            <td *matCellDef="let element" class="default-cell default-column-type-semibold-left" mat-cell>
              {{ element.historyDate | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="coeffL">
            <th *matHeaderCellDef class="left" mat-header-cell>Valeur coefficient L</th>
            <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
              {{ element.coeffL }}
            </td>
          </ng-container>
          <ng-container matColumnDef="buyoutPrice">
            <th *matHeaderCellDef class="left" mat-header-cell>Prix de rachat sous le plafond</th>
            <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
              {{ element.buyoutPrice + ' €/kWh' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="buyoutPriceBeyondCap">
            <th *matHeaderCellDef class="left" mat-header-cell>Prix de rachat au-dessus du plafond</th>
            <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
              {{ element.buyoutPriceBeyondCap + ' €/kWh' }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </section>
  <app-error-section *ngIf="error" [error]="error"></app-error-section>
</div>
