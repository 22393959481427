import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DataColumns } from '@app/shared/interfaces/table.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit {
  @Input() dataSource: MatTableDataSource<T> | T[] = [];

  @Input() columns: DataColumns[] = [];

  @Input() sortActive: string = '';

  @Input() routerCondition: boolean;

  @Input() routerWithColor: boolean = false;

  @Input() sortDirection: 'asc' | 'desc' = 'asc';

  @Input() noHoverOnRows: boolean = false;

  @Input() noDataMessage: string = 'Aucune donnée disponible';

  // Propriété pour le chargement du tableau en entier
  @Input() isLoading: boolean = false;

  @Input() tableId: string = '';

  // Loading sur action
  @Input() downloading!: boolean[];

  @Output() sortChange = new EventEmitter<any>();

  @Output() actionEvent = new EventEmitter<any>();

  @Input() tableWithoutAllStyle: boolean = false;

  @Input() stateFunction: (element: any) => string;

  @Input() rowClickFunction: (element: any, event: MouseEvent) => void;

  columnDefs: string[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.columnDefs = this.columns.map((column) => column.def!);
  }

  onSortChange(event: any) {
    this.sortChange.emit(event);
  }

  performAction(element: any, index: number) {
    this.actionEvent.emit({ element, index });
  }
}
