<div class="contracts-container">
  <div class="contracts-header">
    <div class="title-container">
      <h2 class="contracts-title">Tous mes contrats</h2>
      <app-toggle-switch-check
        [size]="ToggleSwitchSize.large"
        [disabled]="isLoading"
        [options]="contractOptions"
        [selected]="currentStatus"
        (selectedChange)="onClickContractToggleButton($event)"
      ></app-toggle-switch-check>
    </div>
  </div>
  <div class="text-input-container">
    <mat-form-field hideRequiredMarker appearance="outline" class="search-field">
      <input
        type="text"
        matInput
        [formControl]="searchContracts"
        (keydown.enter)="announceSearchChange()"
        placeholder="Dossier, Contrat, Titulaire"
      />
      <em class="ph-magnifying-glass" (click)="announceSearchChange()" matSuffix> </em>
    </mat-form-field>
  </div>

  <ng-template #statusTemplate let-element>
    <mat-chip class="saisie" [class.open]="element.status === 'Actif'" [class.close]="element.status !== 'Actif'">
      {{ element.status }}
    </mat-chip>
  </ng-template>

  <ng-template #consentTemplate let-element>
    <ng-container *ngIf="isConsentAvailable(element); else unavailableTemplate">
      <app-icon
        [phosphorClass]="element.consentOk ? 'ph-check-circle' : 'ph-x-circle'"
        [color]="element.consentOk ? Colors.success500 : Colors.danger500"
        [iconStyle]="IconStyle.fill"
        size="2rem"
      ></app-icon>
    </ng-container>

    <ng-template #unavailableTemplate>
      <p>Indisponible</p>
    </ng-template>
  </ng-template>

  <app-table
    [dataSource]="dataSource"
    [columns]="dataColumns"
    [routerCondition]="this.isActualContract"
    [isLoading]="isLoading"
    [noDataMessage]="noDataMessage"
    [tableId]="'contracts-table'"
    (sortChange)="announceSortChange($event)"
  ></app-table>
  <app-paginator [isLoading]="isLoading"></app-paginator>
</div>
