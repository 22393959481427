import { AsyncPipe, NgIf, NgClass, CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LoaderService } from '@app/shared/services/loader.service';

@Component({
  selector: 'app-loader',
  imports: [CommonModule, AsyncPipe, NgIf, NgClass],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) {}
}
