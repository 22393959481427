import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationResult,
  ChallengeName,
  UserAttributes,
  UserResponse,
} from '@app/shared/interfaces/user-response.interface';
import { User } from '@app/shared/models/user-info';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { environment } from '@environments/environment';
import jwt_decode from 'jwt-decode';
import { DateTime } from 'luxon';
import { BehaviorSubject, EMPTY, Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { appendParams } from '@app/shared/utils/services-utils';
import { PaginatorService } from '@app/shared/components/paginator/paginator.service';
import { GetProfileParams, Profile, ProfileResponse } from '../interfaces/profile.interface';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // #region Constantes
  public static ID_TOKEN = 'idToken';

  public static ACCESS_TOKEN = 'accessToken';

  public static REFRESH_TOKEN = 'refreshToken';

  public static CHALLENGE_SESSION = 'challenge-session';

  public static USER_MAIL = 'userEmail';

  public static CREATED_IN = 'date';

  public static CONTACT_ID = 'contact_id';

  public static PROFILE_ID = 'profile_id';

  // #endregion Constantes

  public connectedUser$: BehaviorSubject<User | null>;

  public currentUser: Observable<User | null>;

  public isForgotPassword: boolean = false;

  public authLoader$ = new BehaviorSubject(false);

  public profiles: Profile[];

  private resultLimit: number = 10;

  public currentProfile: Profile | undefined = undefined;

  public isFromSSO: boolean = false;

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private authJwtDecoded = {
    auth_time: '',
    email_verified: false,
    sub: '',
    email: '',
    identities: [
      {
        providerType: '',
      },
    ],
  };

  public totalProfilesNumber: number | undefined;

  constructor(
    private http: HttpClient,
    public router: Router,
    public sessionStorage: SessionStorageService,
    private paginatorService: PaginatorService,
  ) {
    const savedToken = sessionStorage.get(AuthService.ID_TOKEN);
    const user = this.createUserFromToken(savedToken ?? '');
    this.connectedUser$ = new BehaviorSubject<User | null>(user);
    this.currentUser = this.connectedUser$.asObservable();
  }

  // #region Sign in Management

  public signIn(login: string, password: string): Observable<{}> {
    return this.http
      .post<UserResponse>(
        `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.LOGIN}`,
        {
          login,
          password,
        },
        {
          headers: this.headers,
        },
      )
      .pipe(map((res: UserResponse) => this.manageChallenge(res)));
  }

  public signOut(type?: boolean) {
    const headers = new HttpHeaders({
      Authorization: this.sessionStorage.get(AuthService.ID_TOKEN) ?? [],
    });
    const body = {
      refreshToken: this.sessionStorage.get(AuthService.REFRESH_TOKEN),
    };

    this.clear();

    this.http
      .post<unknown>(`${environment.PA_API_BASE_URL_IAM}${API_ROUTES.LOGOUT}`, body, {
        headers,
      })
      .subscribe(() => {
        this.router
          .navigate([''], {
            queryParams: { isMailModified: type },
          })
          .then(() => {})
          .catch((error) => {
            throw new Error(`Something failed : + ${error}`);
          });
      });
    this.router
      .navigate([''], { state: { type } })
      .then(() => {})
      .catch((error) => {
        throw new Error(`Something failed : + ${error}`);
      });
  }

  responseLogin(res: UserResponse, updateCredentials = true) {
    const { idToken } = res.authenticationResult;

    if (idToken) {
      this.sessionStorage.set(AuthService.ACCESS_TOKEN, res.authenticationResult.accessToken);
      this.sessionStorage.set(AuthService.ID_TOKEN, idToken);
      if (res.authenticationResult.refreshToken) {
        this.sessionStorage.set(AuthService.REFRESH_TOKEN, res.authenticationResult.refreshToken);
        this.sessionStorage.set(AuthService.CREATED_IN, DateTime.now().toISO());
      }

      if (updateCredentials) {
        const user = this.createUserFromToken(idToken);
        this.connectedUser$.next(user);
      }
    }
    return {};
  }

  protected manageChallenge(res: UserResponse) {
    const challenge = res.challengeName as keyof typeof ChallengeName;

    if (challenge && ChallengeName[challenge] === ChallengeName.NEW_PASSWORD_REQUIRED) {
      this.sessionStorage.set(AuthService.CHALLENGE_SESSION, res.session);
      const userAttributes: UserAttributes = JSON.parse(res.challengeParameters.userAttributes);
      this.sessionStorage.set(AuthService.USER_MAIL, userAttributes.email);
      this.sessionStorage.set(AuthService.CREATED_IN, DateTime.now().toISO());
      return ChallengeName.NEW_PASSWORD_REQUIRED;
    }
    return this.responseLogin(res);
  }

  initUser() {
    if (this.isLoggedIn() && !this.isSessionExpired()) {
      const savedToken = this.sessionStorage.get(AuthService.ID_TOKEN);
      const user = this.createUserFromToken(savedToken ?? '');
      if (user) {
        return this.loadProfiles().pipe(
          tap((profiles: ProfileResponse) => {
            const profile: Profile | undefined = profiles.data!.find((p) => p.id === this.getProfileID());
            this.connectedUser$.next({
              ...user,
              selectedProfile: profile,
            });
          }),
        );
      }
    }
    return EMPTY;
  }

  public loadProfiles(search?: string, currentPage?: number, itemsPerPage?: number): Observable<ProfileResponse> {
    const currentPageCalc = currentPage ?? 1;
    const itemsPerPageCalc = itemsPerPage ?? 10;
    return this.getProfiles({
      limit: itemsPerPage ?? this.resultLimit,
      offset: (currentPageCalc - 1) * itemsPerPageCalc,
      search,
    }).pipe(
      tap((profiles: ProfileResponse) => {
        if (profiles.data) {
          this.profiles = profiles.data;

          this.paginatorService.itemProperties$.next({
            total: profiles.total!,
            shown: profiles.data.length,
          });
          this.totalProfilesNumber = Math.ceil(profiles.total! / this.resultLimit);
        } else {
          this.profiles = [];
        }
      }),
      catchError((error) => {
        if (error.status === 401) {
          this.router.navigate(['/login']);
        }
        if (search) {
          return throwError(() => error);
        }
        this.router.navigate(['/contact-not-found-page']);
        return EMPTY;
      }),
    );
  }

  // #region Profiles

  public canNavigateToProfileSelection(fromSSO?: boolean): Observable<boolean> {
    return this.loadProfiles(undefined, undefined, this.resultLimit).pipe(
      map((profiles: ProfileResponse) => {
        let foundProfile = false;
        if (profiles.data && profiles.data.length === 1) {
          this.setProfile(profiles.data[0]);
          this.router.navigate(['/home']);
        } else if (profiles.data && profiles.data.length > 1) {
          if (fromSSO) {
            this.isFromSSO = true;
            this.router.navigate(['/producers']);
          } else {
            this.isFromSSO = false;
            this.router.navigate(['/profiles']);
          }
          foundProfile = true;
        }
        return foundProfile;
      }),
      catchError(() => of(false)),
    );
  }

  public getProfiles(params: GetProfileParams): Observable<ProfileResponse> {
    return this.http
      .get<ProfileResponse>(`${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTACTS}`, {
        params: appendParams({ ...params, partnerId: this.getProfileID() }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: GetProfileParams) => res)) as Observable<ProfileResponse>;
  }

  public getProfile(force: boolean = false): Observable<Profile> {
    if (!force && this.currentProfile) {
      return of(this.currentProfile);
    }
    return this.http
      .get<Profile>(`${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTACTS}/${this.getProfileID()}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        tap((profileDetails: Profile) => {
          this.currentProfile = profileDetails;
          const user = this.currentUserValue;
          if (user) user.selectedProfile = profileDetails;
        }),
      );
  }

  public setProfile(profile: Profile) {
    const user = this.currentUserValue;
    if (user) {
      user.selectedProfile = profile;
      this.currentProfile = profile;
      this.sessionStorage.set(AuthService.PROFILE_ID, profile.id);
      this.sessionStorage.set(AuthService.USER_MAIL, profile.email);
      this.connectedUser$.next(user);
    }
  }

  // #endregion

  forgotPassword(email: string, confirmationCode?: string, newPassword?: string) {
    return this.http
      .post<unknown>(
        `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.FORGOT_PASSWORD}`,
        {
          email,
          confirmationCode,
          newPassword,
        },
        {
          headers: this.headers,
        },
      )
      .pipe(map((response: unknown) => response));
  }

  public resetPassword(newPassword: string): Observable<{}> {
    const session = this.getChallengeSession();

    return this.http
      .post<UserResponse>(
        `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.RESET_PASSWORD}`,
        {
          login: this.getUserEmail(),
          newPassword,
          session,
        },
        {
          headers: this.headers,
        },
      )
      .pipe(
        map((response: UserResponse) => {
          this.removeChallengeSession();
          this.removeUserEmail();
          return this.responseLogin(response);
        }),
      );
  }

  generateNewPassword(email: string): Observable<unknown> {
    if (!email) {
      return throwError(() => new Error('Invalid Email'));
    }
    return this.http
      .post<unknown>(
        `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.RESEND}`,
        {
          email,
        },
        {
          headers: this.headers,
        },
      )
      .pipe(map((response: unknown) => response));
  }

  changePassword(oldPassword: string, newPassword: string): Observable<unknown> {
    const accessToken = this.getAccessToken();
    const email = this.currentUserValue?.email;
    if (!email) {
      return throwError(() => new Error('Invalid Email'));
    }
    const changePasswordUrl = `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.CHANGE_PASSWORD}`;

    return this.http
      .post(
        changePasswordUrl,
        { accessToken, oldPassword, newPassword },
        {
          headers: this.headers,
        },
      )
      .pipe(map((res: unknown) => res));
  }

  public changeMail(mail: string) {
    return this.http
      .post<unknown>(
        `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.CHANGE_MAIL}`,
        {
          accessToken: this.getAccessToken(),
          mail,
        },
        {
          headers: this.headers,
        },
      )
      .pipe(map((response: unknown) => response));
  }

  public verifyMail(code: string) {
    return this.http
      .post<unknown>(
        `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.VERIFY_MAIL}`,
        {
          accessToken: this.getAccessToken(),
          code,
        },
        {
          headers: this.headers,
        },
      )
      .pipe(map((response: unknown) => response));
  }

  // #region User Management

  public createUserFromToken(idToken: string): User | null {
    if (!idToken) {
      return null;
    }

    this.authJwtDecoded = jwt_decode(idToken);

    return {
      authTime: this.authJwtDecoded.auth_time,
      email: this.authJwtDecoded.email,
      emailVerified: this.authJwtDecoded.email_verified,
      authJwt: idToken,
      id: this.authJwtDecoded.sub,
      providerType: this.authJwtDecoded?.identities && this.authJwtDecoded?.identities[0]?.providerType,
    };
  }

  // #endregion management

  // #region SSO
  responseLoginSaml(authenticationResult: AuthenticationResult) {
    const { idToken, accessToken, refreshToken } = authenticationResult;

    if (idToken) {
      this.sessionStorage.set(AuthService.ACCESS_TOKEN, accessToken);
      this.sessionStorage.set(AuthService.ID_TOKEN, idToken);
      if (refreshToken) {
        this.sessionStorage.set(AuthService.REFRESH_TOKEN, refreshToken);
        this.sessionStorage.set(AuthService.CREATED_IN, DateTime.now().toISO());
      }

      const user = this.createUserFromToken(idToken);
      this.connectedUser$.next(user);
    }
    return {};
  }

  public samlAuth(code: string): Observable<AuthenticationResult> {
    const params = new HttpParams().append('code', code);
    return this.http.post<AuthenticationResult>(
      `${environment.PA_API_BASE_URL_IAM}${API_ROUTES.SAML_AUTH}`,
      {},
      {
        params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }

  // #endregion sso

  clear() {
    this.emptySession();
    this.connectedUser$.next(null);
    this.profiles = [];
  }

  public emptySession() {
    this.sessionStorage.remove(AuthService.ACCESS_TOKEN);
    this.sessionStorage.remove(AuthService.ID_TOKEN);
    this.sessionStorage.remove(AuthService.REFRESH_TOKEN);
    this.sessionStorage.remove(AuthService.CREATED_IN);
    this.sessionStorage.remove(AuthService.PROFILE_ID);
    this.sessionStorage.remove(AuthService.USER_MAIL);
  }

  public isSessionExpired(): boolean {
    const createdIn = DateTime.fromISO(this.sessionStorage.get(AuthService.CREATED_IN)!);
    const now = DateTime.now();
    return createdIn < now.minus({ day: environment.USER_SESSION_EXPIRY_DELAY_DAYS });
  }

  public isLoggedIn(): boolean {
    return this.sessionStorage.get(AuthService.ID_TOKEN) !== null;
  }

  public get currentUserValue(): User | null {
    return this.connectedUser$.value;
  }

  getToken() {
    return this.sessionStorage.get(AuthService.ID_TOKEN);
  }

  getAccessToken() {
    return this.sessionStorage.get(AuthService.ACCESS_TOKEN);
  }

  getRefreshToken() {
    return this.sessionStorage.get(AuthService.REFRESH_TOKEN);
  }

  getProfileID() {
    return this.sessionStorage.get(AuthService.PROFILE_ID);
  }

  getChallengeSession() {
    return this.sessionStorage.get(AuthService.CHALLENGE_SESSION);
  }

  removeChallengeSession() {
    this.sessionStorage.remove(AuthService.CHALLENGE_SESSION);
  }

  getUserEmail() {
    return this.sessionStorage.get(AuthService.USER_MAIL);
  }

  removeUserEmail() {
    this.sessionStorage.remove(AuthService.USER_MAIL);
  }
}
