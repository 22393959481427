<div>
  <div>
    <mat-form-field hideRequiredMarker appearance="outline" [ngClass]="{ readonly: readonly }">
      <input
        id="{{ id }}"
        matInput
        [type]="type"
        name="Generic-input"
        [formControl]="formCtrl"
        [placeholder]="placeholder"
        [readonly]="readonly"
      />
    </mat-form-field>
  </div>
  <div *ngFor="let message of errorMessages">
    <mat-error *ngIf="!readonly && formCtrl.touched && formCtrl.hasError(message.name)" id="{{ message.name }}">
      {{ message.value }}
    </mat-error>
  </div>
</div>
