<div class="filter" #connectedEl>
  <button type="button" size="Size.mediumSmall" (click)="onOpenFilter()" mat-stroked-button>
    <app-icon [phosphorClass]="'ph-funnel'"></app-icon>
    <span>{{ label }}</span>
  </button>
</div>

<ng-template cdkPortal>
  <div class="filter-container">
    <div
      class="filter"
      *ngFor="let item of filterItems"
      (click)="onFilterItemClick(item)"
      (keydown.enter)="onKeyDownFilterItem(item)"
      tabindex="0"
      role="button"
    >
      <div class="filter-item">
        <input
          type="radio"
          *ngIf="filterType === 'single'"
          (change)="onFilterItemClick(item)"
          [checked]="item.checked"
        />
        <app-checkbox
          *ngIf="filterType === 'multi'"
          (checkedChange)="onFilterItemClick(item)"
          [checked]="item.checked"
          [aria-label]="item.value"
        ></app-checkbox>
        <span>{{ filterTranslate ? filterTranslate(item.value) : (item.value | titlecase) }}</span>
      </div>
    </div>
  </div>
</ng-template>
