import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input() slides: any[] = [];

  currentSlide = 0;

  get transform() {
    return `translateX(-${this.currentSlide * 100}%)`;
  }

  onPreviousClick() {
    this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
  }

  onNextClick() {
    this.currentSlide = (this.currentSlide + 1) % this.slides.length;
  }
}
