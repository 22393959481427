import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IndexEventService } from '@app/components/indexEvent/indexEvent.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { IndexDataResponse, IndexResponse } from '@app/shared/interfaces/index.interface';
import { MESSAGES } from '@app/shared/utils/messages';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-index-event-container',
  templateUrl: './indexEventContainer.component.html',
  styleUrls: ['./indexEventContainer.component.scss'],
})
export class IndexEventContainerComponent implements OnInit {
  @Input() eventType: 'forbidden' | 'next' = 'next';

  @Input() noDataMessage: string = 'Aucune donnée disponible';

  @Input() currentEvent: any = {};

  isLoading: boolean = false;

  dataSource: MatTableDataSource<IndexDataResponse> = new MatTableDataSource<IndexDataResponse>();

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(
    private indexService: IndexEventService,
    private alertService: AlertService,
    private authentication: AuthService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.authentication.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) {
        this.getIndex();
      }
    });
  }

  getIndex(): void {
    this.isLoading = true;
    this.indexService
      .getIndex({
        limit: 10,
        offset: 0,
        edit: [this.eventType],
        sortBy: 'theoreticalDateNextInvoice',
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: IndexResponse) => {
          if (response.data) {
            this.dataSource.data = response.data;
          } else {
            this.dataSource.data = [];
            this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          }
          this.isLoading = false;
        },
        error: () => {
          this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.isLoading = false;
        },
      });
  }
}
