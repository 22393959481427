export enum MessageReportLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum MessageReportVariant {
  OUTLINED = 'outlined',
  FILLED = 'filled',
}
