export enum Themes {
  CompteurDeProductionEnPanne = 'Compteur de production en panne',
  ErreurDeSaisieDIndex = "Erreur de saisie d'index",
  ReglementDeFacture = 'Règlement de facture',
  SaisirMonIndexDeProduction = 'Saisir mon index de production',
  ModificationRIBPourReglement = 'Modification RIB pour règlement',
  CessionDuContrat = 'Cession du contrat',
  JeContesteMonIndex = 'Je conteste mon index',
  Autres = 'Autres',
}
