export enum Color {
  greyBlack = 'var(--color-greyBlack)',
  warning500 = 'var(--color-warning500)',
  danger500 = 'var(--color-danger500)',
  success500 = 'var(--color-success500)',
  info500 = 'var(--color-info500)',
  info100 = 'var(--color-info100)',
  warning100 = 'var(--color-warning100)',
  danger100 = 'var(--color-danger100)',
}
