export const MESSAGES = {
  SEND_CODE_ERROR: 'Une erreur est survenue lors de l’envoi du code de confirmation par mail',
  RECEIVE_CODE: 'Vous avez reçu le code de confirmation dans votre boite mail.',
  MISMATCH_CODE: 'Le code saisi est incorrect. Veuillez entrer le code reçu par mail.',
  LIMIT_EXCEEDED_CODE_ERROR: 'Vous avez généré trop de code, faites une pause, réessayez plus tard.',
  DEFAULT_ERROR: 'Une erreur est survenue, réessayez ultérieurement.',
  CONNEXION_ERROR: "Une erreur s'est produite lors de la connexion",
  PASSWORD_MODIFIED: 'La modification de votre mot de passe a été réalisée avec succès !',
  EXPIRED_SESSION: 'Votre session a expiré, veuillez vous reconnecter.',
  NO_DATA_ERROR: 'Aucune donnée à afficher',
  RESOURCE_NOT_FOUND: 'Aucune ressource trouvé',
  RESOURCE_UNAUTHORIZED: 'Accès à la ressource non autorisé',
  RESOURCE_ERROR: 'Problème pour accéder à la ressource, veuillez réessayer plus tard',
  CONTACT_NOT_FOUND: 'Vos données contractuelles et personnelles n’ont pas été trouvées sur votre espace',
  INFO_ERROR:
    'Une erreur est survenue, veuillez vérifier les informations saisies. Si l’erreur persiste, contactez votre gestionnaire commercial pour plus d’information.',
  INFO_INVOICE:
    "<p>Lorsque l'index est validé <b>avant le 20 du mois</b>, le règlement est effectué le dernier jour du mois.</p> <br> <p>En revanche, s'il est validé <b>après le 20 du mois</b>, le règlement est effectué le dernier jour du mois M+1.</p> <br> <p>Quelques jours sont à ajouter en raison des transactions bancaires.</p>",
  INFO_CHECKING_STATUS_INVOICE:
    '<p>Si elle est validée par nos services, elle sera disponible en téléchargement :</p> <ul><li>• À partir du 25 du mois, si vous avez enregistré votre index avant le 20 du mois en cours.</li><li>• À partir du 25 du mois M+1, si vous avez enregistré votre index après le 20.</li></ul><br><br><p>Pour la date de paiement, veuillez vous référer aux informations sur les délais de règlements</p><br><br><p>Il est inutile de nous contacter pour essayer d’obtenir votre facture validée plus rapidement. Nous ne répondrons pas positivement à votre demande.</p>',
  CHANGE_MAIL:
    "<p>Vous venez d'etre déconnecté de votre espace producteur suite à la modification de votre adresse mail.</p> <br> <p>Veuillez-vous reconnecter avec votre nouvelle adresse et le mot de passe anciennement utilisé</p>",
  DOWNLOAD_INVOICE_SUCCESS: 'Téléchargement de la facture avec succès',
  DOWNLOAD_INVOICE_ERROR: 'Erreur lors du téléchargement de la facture',
  DOWNLOAD_INVOICE_FORBIDDEN:
    '<h4>Votre facture est en cours de vérification.</h4> <br> <div class="span-text-left"><p>Si elle est validée par nos services, elle sera disponible en téléchargement :</p> <ul><li>• À partir du 25 du mois si vous avez enregistré votre index avant le 20 du mois en cours.</li><li>• À partir du 25 du mois M+1 si vous avez enregistré votre index après le 20.</li> </ul> <br> <p>Pour la date de paiement, veuillez vous référer aux informations sur les délais de règlements.</p></div>',
  SEND_PROVIDER_CONTACT_SUCCESS: 'Envoi du formulaire avec succès',
  SEND_PROVIDER_CONTACT_ERROR: "Erreur lors de l'envoi du formulaire",
  RESEND_PASSWORD_ERROR: 'Une erreur est survenue, veuillez vérifier les informations saisies.',
  SEARCH_PERSON_FAILED_ERROR:
    "La personne recherchée n'est pas présente dans la liste, veuillez vérifier l'orthographe.",
  RESEND_PASSWORD_SUCCES:
    "La génération d'un nouveau mot de passe temporaire a été réalisé avec succès. Celui-ci est disponible dans voitre boîte mail",
};
