<div class="index-event-container">
  <div class="index-title">
    <h3>{{ currentEvent.title }}</h3>
  </div>
  <app-report-message align="left" [messageTemplate]="currentEvent.message"> </app-report-message>

  <div class="table-container">
    <table [dataSource]="dataSource" class="default-table" id="index-table" mat-table>
      <caption></caption>

      <!-- Structure de la table, identique pour les deux cas -->
      <ng-container matColumnDef="contractLine.contractLineId">
        <th *matHeaderCellDef mat-header-cell>Contrat</th>
        <td *matCellDef="let element" class="default-cell default-column-type-semibold-center" mat-cell>
          {{ element?.contractLine.contractLineId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ownerPartner.mainContact.fullName">
        <th *matHeaderCellDef mat-header-cell class="left">Titulaire</th>
        <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
          {{ element?.ownerPartner.mainContact.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractLine.mpt.code">
        <th *matHeaderCellDef class="center" mat-header-cell>Point de comptage</th>
        <td *matCellDef="let element" class="default-cell default-column-type-semibold-left" mat-cell>
          {{ element?.contractLine.mpt.code }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractLine.mpt.address.fullName">
        <th *matHeaderCellDef class="left" mat-header-cell>Adresse du point de comptage</th>
        <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
          {{ element?.contractLine.mpt.address.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="theoreticalDateNextInvoice">
        <th *matHeaderCellDef mat-header-cell>Date TPF</th>
        <td *matCellDef="let element" class="default-cell date-column-type" mat-cell>
          {{ element?.theoreticalDateNextInvoice | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <tr *matHeaderRowDef="currentEvent.tableHead; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let element; columns: currentEvent.tableHead" mat-row></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="default-cell no-data" *ngIf="!isLoading" colspan="4">
          {{ noDataMessage }}
        </td>
        <ng-container *ngIf="isLoading">
          <td *ngFor="let column of currentEvent.tableHead">
            <div class="loading-table default-cell default-column-type-regular-center">
              <ngx-skeleton-loader [theme]="{ 'height.rem': 4 }" count="3"></ngx-skeleton-loader>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  </div>
</div>
