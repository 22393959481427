import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { IndexEventDetails } from '@app/shared/interfaces/index.interface';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';

import { Router } from '@angular/router';
import { IndexEventDetailsService } from '@app/components/indexEvent/indexEvent-details/indexEvent-details.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { MESSAGES } from '@app/shared/utils/messages';
import { DateTime } from 'luxon';
import { getPriceMajUnder } from '@app/shared/utils/prices';
import { Category } from '@app/shared/enums/category';
import { MessageReportLevel } from '@app/shared/components/report-message/report-message.model';

@Component({
  selector: 'app-new-index-event',
  templateUrl: './newIndexEvent.component.html',
  styleUrls: ['./newIndexEvent.component.scss'],
})
export class NewIndexEventComponent implements OnInit, OnDestroy {
  @Input() index: IndexEventDetails | null;

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  price: number = 0;

  linkedPrice: number = 0;

  dateValue: DateTime = DateTime.local();

  dateError: boolean = false;

  minDate: DateTime | null;

  maxDate: DateTime | null;

  loading: boolean = false;

  linkyValue: number;

  linkyDate: DateTime;

  MESSAGES = MESSAGES;

  displayMain = true;

  displaySecondary = false;

  protected readonly Category = Category;

  public newIndexEventForm: UntypedFormGroup = this.fb.group({
    newIndex: ['', [Validators.required, this.indexValueGreaterValidator()]],
    production: ['', [Validators.required, Validators.min(1)]],
    productionSecondaire: ['', [Validators.required, Validators.min(1)]],
    date: ['', [Validators.required]],
  });

  errorMessagesIndex = [
    { name: 'required', value: 'Le nouvel index est requis' },
    { name: 'hasOldIndexGreater', value: "Le nouvel index doit être supérieur à l'ancien" },
  ];

  errorMessagesProduction = [
    { name: 'min', value: 'La production doit etre supérieur à 0' },
    { name: 'required', value: 'La production est requise' },
  ];

  constructor(
    public dialogService: DialogService,
    public alertService: AlertService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private indexEventDetailsService: IndexEventDetailsService,
  ) {}

  ngOnInit(): void {
    if (this.index?.fromDate) {
      this.minDate = DateTime.fromFormat(this.index.fromDate, 'yyyy-MM-dd');
    }

    if (this.index?.indexDate) this.updateField('statementDate', this.index.indexDate);

    if (this.index?.contractLine) {
      this.price = getPriceMajUnder(this.index.contractLine);
    }

    if (this.index?.linkedIndex?.contractLine) {
      this.linkedPrice = getPriceMajUnder(this.index.linkedIndex.contractLine);
    }

    this.maxDate = DateTime.local();
    if (this.index) this.index.indexDate = this.dateValue.toFormat('yyyy-MM-dd');
    if (this.index?.linkedIndex) this.index.linkedIndex.indexDate = this.dateValue.toFormat('yyyy-MM-dd');
    this.newIndexEventForm.get('date')?.setValue(this.dateValue);
    // Call opera
    this.callOpera();
    this.displayMain = this.index?.contractLine?.status.code === 'ACT';
    this.displaySecondary = this.index?.linkedIndex?.contractLine?.status.code === 'ACT';
  }

  callOpera() {
    if (this.index?.contractLine?.mpt?.exchangeRef) {
      this.indexEventDetailsService
        .getLinky({ prm: this.index?.contractLine?.mpt?.exchangeRef })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res) => {
            this.linkyValue = res.value;
            this.linkyDate = DateTime.fromFormat(res.date, 'yyyy-MM-dd');
            if (this.index) {
              this.index.telereleve = this.linkyValue;
              this.index.dateTelereleve = this.linkyDate.toISODate();
            }
          },
          error: (err) => {
            console.error(err);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getProductionTime(): string {
    if (this.index?.indexDate) {
      const statementDateFormat = DateTime.fromFormat(this.index.indexDate, 'yyyy-MM-dd');
      const startDateFormat = DateTime.fromFormat(this?.index?.fromDate || '', 'yyyy-MM-dd');
      const productionTime = statementDateFormat.diff(startDateFormat, 'days').days;
      this.index.productionDuration = productionTime;
      if (this.index.linkedIndex) this.index.linkedIndex.productionDuration = productionTime;
      return productionTime < 0 ? '0' : productionTime.toString();
    }
    return '0';
  }

  updateField(field: string, value: string | number, opts?: { emitEvent?: boolean; onlySelf?: boolean }) {
    this.newIndexEventForm.get(field)?.setValue(value, opts);
    this.newIndexEventForm.get(field)?.markAsTouched();
  }

  setStartDateError(event: boolean): void {
    this.dateError = event;
  }

  setStatementDate(event: DateTime | null) {
    if (this.index && event) {
      this.index.indexDate = event.toFormat('yyyy-MM-dd');
    }
    if (this.index?.linkedIndex && event) {
      this.index.linkedIndex.indexDate = event.toFormat('yyyy-MM-dd');
    }
  }

  setNewIndex($event: string | null) {
    if (this.index && $event) {
      const val = Number($event);
      if (val < 0)
        this.updateField('newIndex', (this.index.oldIndexValue ?? 0) + (this.index.linkedIndex?.oldIndexValue ?? 0));

      if (this.index.linkedIndex) {
        const breakdown = this.index.contractLine.productionBreakdown;
        const coef = Number(breakdown) / 100;

        const production = val - this.index.oldIndexValue - this.index.linkedIndex.oldIndexValue;

        const repartition = coef * production;

        this.index.production = Math.round(repartition < 0 ? 0 : repartition);
        const linkedProd = production - repartition < 0 ? 0 : production - repartition;
        this.index.linkedIndex.production = coef !== 0.5 ? Math.round(linkedProd) : Math.trunc(linkedProd);

        this.index.indexValue = this.index.oldIndexValue + this.index.production;
        this.index.linkedIndex.indexValue = this.index.linkedIndex.oldIndexValue + this.index.linkedIndex.production;

        this.updateField('production', production < 0 ? 0 : this.index.production, { emitEvent: false });
        this.updateField('productionSecondaire', production < 0 ? 0 : this.index.linkedIndex.production, {
          emitEvent: false,
        });
      } else {
        this.index.indexValue = val;
        const production = this.index.indexValue - this.index.oldIndexValue;
        this.index.production = production < 0 ? 0 : production;
        this.updateField('production', production < 0 ? 0 : production, { emitEvent: false });
      }
    }
  }

  setProduction(event: string | number | null) {
    if (this.index && event != null) {
      const val = Number(event);
      if (val < 0) this.updateField('production', 0);
      this.index.production = val;
      this.index.indexValue = this.index.production + this.index.oldIndexValue;

      let newIndex = this.index.oldIndexValue + (this.index.linkedIndex?.oldIndexValue ?? 0);

      if (this.index.production > 0) {
        newIndex += this.index.production + (this.index.linkedIndex?.production ?? 0);
      }

      this.updateField('newIndex', newIndex, { emitEvent: false });
    }
  }

  indexValueGreaterValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.index?.oldIndexValue) {
        const oldIndex = this.index.linkedIndex
          ? this.index.oldIndexValue + this.index.linkedIndex.oldIndexValue
          : this.index.oldIndexValue;
        if (control.value && oldIndex >= control.value) {
          return { hasOldIndexGreater: true };
        }
      }
      return null;
    };
  }

  get f() {
    return this.newIndexEventForm.controls;
  }

  acceptTelereleve() {
    this.newIndexEventForm.get('date')?.setValue(this.linkyDate);
    this.updateField('newIndex', this.linkyValue);
  }

  submit() {
    const options: ConfirmDialogData = {
      message: 'Êtes-vous sûrs de vouloir valider votre nouvelle production ?',
      confirmText: 'Valider la production',
      cancelText: 'Annuler',
      enableConfirmButton: true,
      confirmFunction: () => {
        this.loading = true;

        this.dialogService.close();

        lastValueFrom(this.indexEventDetailsService.validateIndex(this.index, this.index?.linkedIndex))
          .then(() => {
            this.router.navigate(['home/index']);
            this.alertService.success("Saisie d'index effectuée", {
              icon: 'check',
            });
            this.loading = false;
          })
          .catch((error) => {
            this.alertService.error(error.error.flash);
            this.loading = false;
          });
      },
    };
    this.dialogService.open(options);
  }

  protected readonly TypeError = TypeError;

  protected readonly MessageReportLevel = MessageReportLevel;
}
