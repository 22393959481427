<div class="index-container">
  <div class="index-header">
    <div class="title-container">
      <h2 class="index-title">Tous mes index</h2>
    </div>
    <div class="actions-header">
      <button (click)="openDialog('forbidden')" class="next-index-btn" type="button" mat-flat-button>
        <i class="ph ph-binoculars"></i> Saisies interdites
      </button>

      <button (click)="openDialog('next')" class="next-index-btn" type="button" mat-flat-button>
        <i class="ph ph-binoculars"></i>
        Saisies d'index à venir
      </button>

      <ng-template #dialogForbiddenIndexContent>
        <app-forbidden-index-event></app-forbidden-index-event>
      </ng-template>

      <ng-template #dialogNextIndexContent>
        <app-next-index-event></app-next-index-event>
      </ng-template>
    </div>
  </div>
  <div class="text-input-container">
    <mat-form-field hideRequiredMarker appearance="outline" class="search-field">
      <input
        type="text"
        matInput
        [formControl]="searchIndex"
        (keydown.enter)="announceSearchChange()"
        placeholder="Dossier, Contrat, Titulaire"
      />

      <em (click)="announceSearchChange()" class="ph-magnifying-glass" matSuffix></em>
    </mat-form-field>
    <app-filter
      [label]="'Filtrer par saisie'"
      [data]="editFilter"
      [defaultValues]="editFilterDefaultValues"
      [filterType]="'multi'"
      (filterChange)="announceFilterChange($event)"
      [filterTranslate]="getEditLabel"
    ></app-filter>
  </div>

  <ng-template #statusTemplate let-element>
    <mat-chip class="saisie open" *ngIf="state(element) === 'open'">Ouverte</mat-chip>
    <mat-chip class="saisie next" *ngIf="state(element) === 'next'">À venir</mat-chip>
    <mat-chip class="saisie close" *ngIf="state(element) === 'close'">Fermée</mat-chip>
    <mat-chip class="saisie forbidden" *ngIf="state(element) === 'forbidden'">Interdite</mat-chip>
  </ng-template>

  <app-table
    [columns]="dataColumns"
    [dataSource]="dataSource"
    [isLoading]="isLoading"
    [noDataMessage]="noDataMessage"
    [routerWithColor]="true"
    [rowClickFunction]="clickedRow"
    [sortActive]="'theoreticalDateNextInvoice'"
    [stateFunction]="state"
    [tableId]="'index-table'"
    (sortChange)="announceSortChange($event)"
  ></app-table>

  <app-paginator></app-paginator>
</div>
