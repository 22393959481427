<div class="main-container">
  <section *ngIf="!error" class="section-container">
    <div class="section-content">
      <div class="section-header">
        <app-buttons type="borderedBackBtn" (clickAction)="handleBack()"></app-buttons>
        <div class="title-container">
          <h2 class="title" id="index-name">Ma saisie d'index</h2>
        </div>
      </div>

      <div class="index-content">
        <div class="part-site">
          <h3>Gestion du site</h3>
          <div class="main-information-grid">
            <div class="information-block">
              <app-main-information
                id="owner"
                title="Titulaire"
                [value]="indexEventDetails?.ownerPartner?.name"
                [loading]="isLoading"
              ></app-main-information>
            </div>

            <div class="information-block">
              <app-main-information
                id="contract"
                [title]="this.productionBreakdown ? 'Dossier principal' : 'Dossier'"
                [value]="indexEventDetails?.contractLine?.contractLineId"
                [loading]="isLoading"
              ></app-main-information>
              <app-main-information
                *ngIf="this.productionBreakdown"
                id="breakdownContract"
                title="Répartition"
                [value]="(this.productionBreakdown | number: '1.0-2') + '%'"
                [loading]="isLoading"
              ></app-main-information>
              <app-main-information
                id="status"
                title="Status"
                [value]="indexEventDetails?.contractLine?.status?.name"
                [loading]="isLoading"
              ></app-main-information>
            </div>

            <div class="information-block">
              <app-main-information
                *ngIf="this.productionBreakdown"
                id="linkedContract"
                title="Dossier secondaire"
                [value]="numeroDossierSecondaire"
                [loading]="isLoading"
              ></app-main-information>
              <app-main-information
                *ngIf="this.productionBreakdown"
                id="breakdownLinkedContract"
                title="Répartition"
                [value]="(100 - this.productionBreakdown | number: '1.0-2') + '%'"
                [loading]="isLoading"
              ></app-main-information>
              <app-main-information
                *ngIf="this.productionBreakdown"
                id="linkedContratStatus"
                title="Status"
                [value]="
                  this.isNewIndex
                    ? indexEventDetails?.contractLine?.linkedContractLine?.status?.name
                    : indexEventDetails?.linkedIndex?.contractLine?.status?.name
                "
                [loading]="isLoading"
              ></app-main-information>
            </div>

            <div class="information-block">
              <app-main-information
                id="mpt"
                title="Contrat"
                [value]="indexEventDetails?.contractLine?.mpt?.code"
                [loading]="isLoading"
              ></app-main-information>
              <app-main-information
                id="mptAddress"
                title="Adresse du point de comptage"
                [value]="indexEventDetails?.address"
                [loading]="isLoading"
              ></app-main-information>
            </div>
          </div>
        </div>

        <div class="part-old-index">
          <h3>{{ this.isNewIndex ? 'Mon ancien Index' : 'Ma production' }}</h3>
          <div class="main-information-grid">
            <app-main-information
              id="theoreticalDateNextInvoice"
              title="Date TPF"
              [value]="indexEventDetails?.theoreticalDateNextInvoice"
              type="date"
              [loading]="isLoading"
            ></app-main-information>

            <app-main-information
              id="fromDate"
              title="Date de début"
              [value]="indexEventDetails?.fromDate"
              [loading]="isLoading"
              type="date"
            ></app-main-information>

            <app-main-information
              *ngIf="this.indexEventDetails"
              id="oldIndexValue"
              title="Ancien index"
              [value]="getOldIndexValue() | number: '1.0-0' : 'fr-FR'"
              [loading]="isLoading"
            ></app-main-information>

            <app-main-information
              *ngIf="!this.isNewIndex"
              id="indexDate"
              title="Date de relevé"
              [value]="indexEventDetails?.indexDate"
              [loading]="isLoading"
              type="date"
            ></app-main-information>

            <app-main-information
              *ngIf="!this.isNewIndex"
              title="Nouvel index"
              [value]="
                (indexEventDetails && indexEventDetails.linkedIndex
                  ? indexEventDetails.linkedIndex.indexValue + indexEventDetails.indexValue
                  : indexEventDetails?.indexValue
                ) | number: '1.0-0' : 'fr-FR'
              "
              [loading]="isLoading"
              id="indexValue"
            ></app-main-information>

            <app-main-information
              *ngIf="!this.isNewIndex"
              title="Durée de production"
              [value]="(indexEventDetails?.productionDuration | number: '1.0-0' : 'fr-FR') + ' j'"
              [loading]="isLoading"
              id="productionDuration"
            ></app-main-information>

            <app-main-information
              *ngIf="!this.isNewIndex"
              title="Production"
              [value]="
                ((indexEventDetails && indexEventDetails.linkedIndex
                  ? indexEventDetails.linkedIndex.production + indexEventDetails.production
                  : indexEventDetails?.production
                ) | number: '1.0-0' : 'fr-FR') + ' kWh'
              "
              [loading]="isLoading"
              id="production"
            ></app-main-information>
            <app-main-information
              *ngIf="!this.isNewIndex && indexEventDetails && indexEventDetails.linkedIndex"
              [title]="'Production du dossier principal ' + indexEventDetails.contractLine.contractLineId"
              [value]="(indexEventDetails.production | number: '1.0-0' : 'fr-FR') + ' kWh'"
              [loading]="isLoading"
              id="productionMainContract"
            ></app-main-information>
            <app-main-information
              *ngIf="!this.isNewIndex && indexEventDetails && indexEventDetails.linkedIndex"
              [title]="'Production du dossier secondaire ' + numeroDossierSecondaire"
              [value]="(indexEventDetails.linkedIndex.production | number: '1.0-0' : 'fr-FR') + ' kWh'"
              [loading]="isLoading"
              id="productionLinkedContract"
            ></app-main-information>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="this.isNewIndex && !this.isLoading && !error">
    <app-new-index-event [index]="indexEventDetails"></app-new-index-event>
  </section>
  <app-error-section *ngIf="error" [error]="error"></app-error-section>
</div>
