import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChooseProducerGuard implements CanActivate {
  constructor(
    private authentication: AuthService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authentication.isFromSSO) {
      return true;
    }
    this.router.navigate(['/']);

    return false;
  }
}
