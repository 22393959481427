import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorType } from '@app/shared/components/error-section/error.model';
import { ContractDetails } from '@app/shared/interfaces/contract.interface';
import { billingRythm, salesType } from '@app/shared/utils/referential';
import { of, Subject, switchMap } from 'rxjs';
import { getPriceMajBeyond, getPriceMajUnder } from '@app/shared/utils/prices';
import { LoaderService } from '@app/shared/services/loader.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { ContractDetailsService } from './contract-details.service';

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  contractDetails: ContractDetails | null = null;

  refExchangeName: string = '';

  salesType = salesType;

  billingRythm = billingRythm;

  isHistory: boolean = false;

  isLoading: boolean = false;

  error: ErrorType;

  priceHistory: [];

  displayedColumns: string[] = ['historyDate', 'coeffL', 'buyoutPrice', 'buyoutPriceBeyondCap'];

  arretesList: string[] = ['2017 V.S.', '2021 V.S.', '2021 modificatif Pa', '2021 modificatif Pb 80%'];

  protected readonly getPriceMajBeyond = getPriceMajBeyond;

  protected readonly getPriceMajUnder = getPriceMajUnder;

  constructor(
    private route: ActivatedRoute,
    private contractDetailsService: ContractDetailsService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.refExchangeName = 'PRM';
    this.isHistory = this.location.path().startsWith('/home/contracts-history');
    this.getContract();
  }

  private getContract() {
    this.route.paramMap
      .pipe(
        switchMap((pMap) => this.contractDetailsService.getContract(pMap.get('id')!, this.isHistory)),
        switchMap((details) => {
          this.contractDetails = details;
          if (!this.isHistory) {
            return of(this.getPriceHistory(this.contractDetails.contractLineId));
          }
          return of(details);
        }),
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  private getPriceHistory(contractId: string) {
    return this.contractDetailsService
      .getPriceHistory({ sortBy: '-historyDate', criteria: contractId, limit: 2 })
      .subscribe({
        next: (res: any) => {
          this.priceHistory = res;
        },
        error: (err: unknown) => {
          console.error('error', err);
        },
      });
  }

  displayPrime(): boolean {
    return this.arretesList.includes(this.contractDetails?.decree?.code!);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  handleBack() {
    this.location.back();
  }

  onChangeConsent(isChecked: boolean) {
    this.loaderService.show('half');
    this.route.paramMap
      .pipe(switchMap((pMap) => this.contractDetailsService.updateContract(pMap.get('id')!, { consentOk: isChecked })))
      .subscribe({
        next: () => {
          this.loaderService.hide();
        },
        error: () => {
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.loaderService.hide();
        },
      });
  }
}
