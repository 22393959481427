import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface LoaderState {
  open: boolean;
  type: 'default' | 'half';
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loading = new BehaviorSubject<LoaderState>({ open: false, type: 'default' });

  public readonly loading$ = this.loading.asObservable();

  show(type: 'default' | 'half' = 'default') {
    if (!this.loading.value.open || this.loading.value.type !== type) {
      this.loading.next({ open: true, type });
    }
  }

  hide() {
    if (this.loading.value.open) {
      this.loading.next({ open: false, type: 'default' });
    }
  }
}
