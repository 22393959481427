<div class="demarche-container">
  <mat-card id="step-panel" class="demarche-content">
    <h2>Mes démarches</h2>
    <app-report-message
      message="Pendant la durée de votre contrat, il peut arriver toutes sortes
        d'événements impactant les conditions particulières de votre contrat
        d'achat. Il est très important de nous tenir informés au préalable afin
        de garantir la continuité de celui-ci."
    >
    </app-report-message>
    <app-collapse
      title="
        Je vends un bien disposant d'une installation photovoltaïque bénéficiant d'un contrat d'obligation d'achat.
      "
    >
      <p>
        Nous vous remercions de nous faire parvenir, via le
        <a routerLink="/home/contact"><em class="ph-envelope"></em> formulaire de contact</a>
        , les documents suivants :
      </p>
      <ul>
        <li><p>- Attestation notariale de vente en intégralité</p></li>
        <li>
          <p>- Le RIB au format bancaire du/des acquéreurs (futurs titulaires du contrat)</p>
        </li>
        <li>
          <p>- L'avenant complété, paraphé et signé correspondant à votre contrat</p>
        </li>
      </ul>
      <div class="fit-div">
        <app-download-file
          id="doc-amendment-ceased"
          link="https://static.soregies.fr/divers/Avenant-cession-vierge-(tous-d%C3%A9cret).pdf"
          title="Avenant de cession"
        ></app-download-file>
        <app-download-file
          id="doc-amendment-help"
          link="https://static.soregies.fr/divers/aide-MOP-avenant-vierge.pdf"
          title="Aide bien remplir l'avenant"
        ></app-download-file>
        <app-download-file
          id="doc-relever-index"
          link="https://static.soregies.fr/divers/COMMENT+RELEVER+SES+INDEX.pdf"
          title="Comment relever ses index"
        ></app-download-file>
      </div>
    </app-collapse>
    <app-collapse
      title="
        Je souhaite modifier le RIB relatif aux versements de ma production photovoltaïque
      "
    >
      <p>
        Dans le cadre d'un changement de RIB relatif aux paiements des productions d'énergie photovoltaïque, nous vous
        remercions de nous faire parvenir par le
        <a routerLink="/home/contact"><em class="ph-envelope"></em> formulaire de contact</a>
        le RIB :
      </p>
      <p>
        <span class="important"
          >Le RIB doit être au nom du (ou des) titulaire(s) du contrat d'achat et au format bancaire.</span
        >
        Si vous transmettez le RIB par email, merci de bien vouloir l'envoyer au format PDF.
      </p>
      <p>
        Le changement de RIB concerne la partie paiement de votre production. Ce changement ne s'applique pas aux
        règlements de vos factures de consommation.
      </p>
      <p>
        Le RIB est contrôlé par nos services avant modification. Si celui-ci ne correspond pas à la règlementation
        imposée, il sera refusé. Nous vous en avertirons par mail
      </p>
    </app-collapse>
    <app-collapse title="Le titulaire du contrat est décédé">
      <p>
        Afin de procéder à la modification du contrat d'achat d'énergie photovoltaïque, nous vous remercions de nous
        faire parvenir par le
        <a routerLink="/home/contact"><em class="ph-envelope"></em> formulaire de contact</a>
        les documents suivants :
      </p>
      <ul>
        <li>
          <p>- Attestation « Immobilière » (un document provenant d'un notaire indiquant le(s) héritier(s))</p>
        </li>
        <li><p>- Avis de décès</p></li>
        <li>
          <p>- Le RIB au nom de (des) (l') héritier(s) bénéficiaire(s) du contrat d'achat</p>
        </li>
      </ul>
      <p>
        Une fois que le dossier est complet, nous procédons, selon les situations, au changement de nom du titulaire.
      </p>
    </app-collapse>
    <app-collapse
      title="
        TVA : je souhaite modifier mon contrat afin qu'il soit conforme à ma situation fiscale
      "
    >
      <p>
        Une personne assujettie à la TVA est une personne réalisant de manière indépendante une activité économique.
        Dans ce cas, le producteur est considéré comme un professionnel. Ainsi, le site de production est enregistré au
        répertoire des entreprises.
      </p>
      <br />
      <p>
        Afin de clarifier votre situation par rapport à la TVA, nous vous prions de compléter l'avenant prévu à cet
        effet.
      </p>
      <div class="fit-div">
        <app-download-file
          id="doc-amendment-tva"
          link="https://static.soregies.fr/divers/AVENANT-TVA-VIERGE.pdf"
          title="Avenant TVA"
        >
        </app-download-file>
      </div>
      <br />
      <p>
        Un avenant doit obligatoirement être complété,
        <span class="bold">paraphé, daté et signé par le/les cédants et cessionnaires</span>. L'avenant doit être
        imprimé en <span class="bold">3 exemplaires ORIGINAUX identiques</span>.
      </p>
      <br />
      <p>
        Si vous êtes assujetti, merci de compléter votre numéro de SIREN, votre numéro de TVA intracommunautaire et le
        numéro de SIRET de l'installation.
      </p>
      <p>Nous vous prions de joindre également un avis de situation au répertoire SIRENE.</p>
      <p>
        Pour les personnes morales, hors Entreprise Individuelle, nous vous prions de communiquer un Extrait Kbis de
        moins de 3 mois.
      </p>
      <br />
      <p class="bold">Informations à prendre en compte :</p>
      <ul>
        <li>
          <p class="italic">
            - Si une facturation a déjà eu lieu, la date d'effet doit être postérieure à la dernière facture émise et
            réglée par SOREGIES.
          </p>
        </li>
        <li>
          <p class="italic">
            - Pour les signatures de l'avenant de cession : si un co-titualire souhaite passer le contrat à son seul
            nom, dans ce cas, il faut également la signature du second titulaire.
          </p>
        </li>
      </ul>
      <br />
      <p>
        Nous vous remercions de nous faire parvenir les éléments via le
        <a routerLink="/home/contact"><em class="ph-envelope"></em> formulaire de contact</a>
      </p>
      <br />
      <p class="bold">Le saviez-vous ?</p>
      <p>
        Pour obtenir votre avis de situation au répertoire SIRENE, vous pouvez consulter le site de l'Institut National
        de la Statistique et des Etudes Economiques :
        <a href="https://avis-situation-sirene.insee.fr/" target="_blank"
          >Avis de situation au répertoire Sirene - Insee</a
        >
      </p>
      <p class="italic">
        Depuis le 1er avril 2012, tous les producteurs, même professionnels, facturent leur production photovoltaïque
        sans TVA puisque c'est l'acheteur (SORÉGIES) qui doit désormais verser la TVA directement à l'Etat.
      </p>
    </app-collapse>
    <app-collapse
      title="
        Mon installation a été détruite ou il faut remplacer des panneaux
      "
    >
      <p>
        Le producteur doit tenir l'acheteur informé de la production, du fonctionnement de son installation et de ses
        modifications éventuelles envisagées. En cas d'arrêt définitif de l'activité de production, le producteur doit
        en avertir l'acheteur par lettre recommandée avec accusé de réception un mois au moins avant la cessation
        d'activité.
      </p>
      <p>
        Conformément aux conditions générales et aux courriers d'instructions du ministère du 12 octobre 2017, du 23
        août 2018 et du 7 avril 2021, des remplacements de panneaux sont autorisés sous certaines conditions.
      </p>
      <div class="fit-div">
        <app-download-file
          id="doc-12-10-17"
          link="https://static.soregies.fr/divers/121017_-_remplacement_de_panneaux_pour_les_installations_pv_0.pdf"
          title="Courrier d'instructions de la réponse ministérielle du 12/10/2017"
        >
        </app-download-file>
        <app-download-file
          id="doc-23-08-18"
          link="https://static.soregies.fr/divers/23-08-2018-remplacement_de_panneaux_s11_s17_ao_dgec_.pdf"
          title="
            Courrier d'instructions de la réponse ministérielle du 23/08/2018
          "
        >
        </app-download-file>
        <app-download-file
          id="doc-07-04-21"
          link="https://static.soregies.fr/divers/07-04-2021-note_dinstruction-_precisions_sur_le_remplacem._.pdf"
          title="
            Courrier d'instructions de la réponse ministérielle du 07/04/2021
          "
        >
        </app-download-file>
      </div>
      <br />
      <p>
        La règlementation est très spécifique selon les contrats et les situations. Avant toute démarche, nous vous
        invitons à consulter nos services afin de vous guider sur les obligations règlementaires à respecter pour le
        maintien de votre contrat.
      </p>
      <p class="important">Attention : Le non-respect des conditions conduira à la résiliation du contrat.</p>
    </app-collapse>
    <app-collapse title="Je me sépare : séparation, divorce, rupture de PACS">
      <p>
        Dans le cadre d'une séparation, divorce, rupture de PACS, nous vous remercions de nous faire parvenir par le
        <a routerLink="/home/contact"><em class="ph-envelope"></em> formulaire de contact</a>
        les documents suivants :
      </p>
      <p class="bold">Pour une séparation à l'amiable :</p>
      <ul>
        <li>
          <p>
            - Un avenant de cession complété, paraphé, signé (voir rubrique « Vente d'un bien ayant une installation
            photovoltaïque »)
          </p>
        </li>
        <li>
          <p>- Copie des pièces d'identité des personnes concernées (CNI ou Passeport valide)</p>
        </li>
        <li>
          <p>
            - Attestation sur l'honneur des personnes concernées indiquant la situation et l'acceptation du changement
            du nom du titulaire du contrat, avec les signatures
          </p>
        </li>
      </ul>
      <p class="bold">Pour une séparation en cas de désaccord :</p>
      <ul>
        <li>
          <p>
            - Une décision judiciaire du bénéficiaire définitive ou une convention de cession homologuée par un juge.
            <span class="italic"
              >Attention ! une Ordonnance de non-conciliation n'est pas recevable car non définitive.</span
            >
          </p>
        </li>
        <li><p>- Le RIB du bénéficiaire</p></li>
        <li>
          <p>- Relevé d'index à la date de cession signé par les personnes concernées</p>
        </li>
      </ul>
    </app-collapse>
    <app-collapse title="Je souhaite ajouter un co-titulaire">
      <p>
        Afin de procéder à la modification du contrat d'achat d'énergie photovoltaïque, nous vous remercions de nous
        faire parvenir par le
        <a routerLink="/home/contact"><em class="ph-envelope"></em> formulaire de contact</a>
        les documents suivants :
      </p>
      <ul>
        <li>
          <p>- Copie des pièces d'identité des personnes concernées (CNI ou Passeport valide)</p>
        </li>
        <li>
          <p>- Attestation sur l'honneur des personnes concernées indiquant la volonté d'ajouter un co-titulaire</p>
        </li>
        <li><p>- Un RIB aux noms des co-titulaires</p></li>
      </ul>
      <p>
        Attention si vous êtes concernés par un régime fiscal imposant d'être assujetti à la TVA (statut
        d'auto-entrepreneur, de micro-entreprise et toutes personnes morales) le nom du co-titulaire doit apparaitre sur
        l'avis de situation SIREN ou l'extrait Kbis.
      </p>
    </app-collapse>
    <app-collapse title="J'ai changé de RIB, par conséquent je n'ai pas reçu de virement relatif à ma production">
      <p>
        Si vous avez omis de nous signaler un changement de banque avant le paiement : Nous vous prions de nous
        l'indiquer rapidement via le
        <a routerLink="/home/contact"><em class="ph-envelope"></em> formulaire de contact</a> en joignant votre nouveau
        RIB (tout en respectant les recommandations relatives au changement de RIB *ci-dessus*).
      </p>
      <br />
      <p>
        Nous serons dans l'obligation d'effectuer quelques recherches et de vérifier si un refus de virement a été
        signalé. Si tel est le cas, un autre ordre de virement sera lancé sur le nouveau compte. Ce processus demande du
        temps : au moins 1 mois après votre signalement.
      </p>
    </app-collapse>
    <app-collapse title="Je souhaite ajouter des panneaux">
      <p>
        Vous êtes propriétaire d'un bien équipé de panneaux photovoltaïques et vous souhaitez ajouter des panneaux ?
        Cela est possible mais la gestion est différente selon les intentions :
      </p>
      <br />
      <p class="bold">
        JE SOUHAITE UNE SECONDE INSTALLATION AVEC UN TYPE DE VENTE DIFFERENTE DE MA PREMIERE INSTALLATION (Vente totale
        ≠ Vente surplus)
      </p>
      <p>
        Il est totalement possible que votre bien dispose de deux installations avec des types de ventes différentes.
      </p>
      <p>
        Il convient alors de déposer une nouvelle demande de raccordement auprès de SRD-Energies dans le cadre d'une
        installation nouvelle.
      </p>
      <br />
      <p>Les installations auront deux compteurs distincts et ainsi deux contrats d'achats différents.</p>
      <ul>
        <li>
          <p>
            - S'il s'agit d'une vente surplus, elle sera raccordée au compteur Linky de consommation et votre compteur
            sera programmé en mode producteur. Ce compteur intelligent saura faire la différence entre votre
            consommation et l'énergie produite par les panneaux non consommée et renvoyée dans le réseau (= le surplus)
          </p>
        </li>
        <li>
          <p>- S'il s'agit d'une vente totale, un autre compteur sera mis en place.</p>
        </li>
      </ul>
      <p>Dans les deux cas, vous serez en possession de deux compteurs Linky.</p>
      <br />
      <p>OU</p>
      <br />
      <p class="bold">JE SOUHAITE AJOUTER DES PANNEAUX A UNE INSTALLATION EXISTANTE (même type de vente)</p>
      <br />
      <p>
        Il s'agit d'une augmentation de puissance. Il convient alors de déposer une nouvelle demande de raccordement
        auprès de SRD-Energies dans le cadre d'une augmentation de puissance.
      </p>
      <br />
      <p>
        L'installation existante et l'installation nouvelle seront raccordées sur le même compteur. Cependant, la
        nouvelle demande conduira à l'élaboration d'un nouveau contrat d'achat aux conditions et tarif en vigueur lors
        du dépôt de cette nouvelle demande (les conditions peuvent être différentes du premier contrat).
      </p>
      <br />
      <p>
        Ce nouveau contrat sera associé à l'existant et la production de vos installations sera répartie au prorata des
        puissances installées. Une mise en service sera faite, ainsi, deux déclarations devront être réalisées sur les
        différents contrats.
      </p>
      <br />
      <p>Exemple :</p>
      <img src="assets/demarche-table.png" alt="tableau" />
      <div class="fit-div">
        <b
          ><app-report-message message="La déclaration du second contrat est calquée sur le premier contrat.">
          </app-report-message
        ></b>
      </div>
      <p>
        Sur le contrat 00001 il faudra donc déclarer : 515 kWh de production (détail du calcul : 3 250 – 1 500 = 1 750
        kWh de production puis 1 750 x 29.41% = 515 kWh de production).
      </p>
      <br />
      <p>
        Sur le contrat 00002 il faudra donc déclarer : 1 235 kWh de production (détail du calcul : 3250 – 1 500 = 1 750
        kWh de production puis 1 750 x 70.59 %= 1 235 kWh de production).
      </p>
    </app-collapse>

    <div class="center-div">
      <p class="p-answer">Pour envoyer les documents nécessaires à vos démarches.</p>
      <button class="default-btn" type="button" mat-flat-button id="infoBtn" routerLink="/home/contact">
        <i class="ph ph-at"></i>
        Formulaire de contact
      </button>
    </div>
  </mat-card>
</div>
