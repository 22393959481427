export interface UserResponse {
  authenticationResult: AuthenticationResult;
  challengeName: string;
  challengeParameters: {
    USER_ID_FOR_SRP: string;
    requiredAttributes: string;
    userAttributes: string;
  };
  session: string;
}

export interface AuthenticationResult {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn: number;
  newDeviceMetadata: {
    deviceGroupKey: string;
    deviceKey: string;
  };
}

export interface UserAttributes {
  email_verified: string;
  email: string;
}

export enum ChallengeName {
  SMS_MFA,
  SOFTWARE_TOKEN_MFA,
  SELECT_MFA_TYPE,
  MFA_SETUP,
  PASSWORD_VERIFIER,
  CUSTOM_CHALLENGE,
  DEVICE_SRP_AUTH,
  DEVICE_PASSWORD_VERIFIER,
  ADMIN_NO_SRP_AUTH,
  NEW_PASSWORD_REQUIRED,
}
