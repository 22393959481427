<div class="actu">
  <div class="title-container">
    <h3>En savoir plus</h3>
  </div>

  <div class="actu-wrapper">
    <button class="actu-arrow left-arrow" (click)="scrollLeft()">
      <span class="arrow left"></span>
    </button>

    <div class="actu-container" #carousel>
      <a href="{{ column.link }}" target="_blank" class="actu-item background" *ngFor="let column of feedItemList">
        <div class="{{ column.link?.includes('photovoltaique.info') ? 'image photo' : 'image ministre' }}"></div>
        <div class="content">
          <h5 class="title">{{ column.title }}</h5>
          <p class="date">
            {{ column.pubDate?.toFormat('dd MMMM yyyy', { locale: 'fr-FR' }) }}
          </p>
          <app-icon phosphorClass="ph-link" />
        </div>
      </a>
    </div>

    <button class="actu-arrow right-arrow" (click)="scrollRight()">
      <span class="arrow right"></span>
    </button>
  </div>
</div>
