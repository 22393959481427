import { IndexDataResponse, IndexEventDetails } from '@app/shared/interfaces/index.interface';
import { DateTime } from 'luxon';

export function isNext(index: IndexDataResponse) {
  const dateTPF = DateTime.fromFormat(index.theoreticalDateNextInvoice, 'yyyy-MM-dd').set({ day: 1 });
  const now = DateTime.now().set({ hour: 0 });
  return now < dateTPF;
}

export function isPast(index: IndexDataResponse) {
  const dateTPF = DateTime.fromFormat(index.theoreticalDateNextInvoice, 'yyyy-MM-dd').set({ day: 1 });
  const now = DateTime.now().set({ hour: 0 });
  if (index?.contractLine?.invoicingRhythmSelect === '1') {
    return dateTPF.plus({ months: 1 }) < now;
  }
  return dateTPF.plus({ months: 3 }) < now;
}

export function state(index: IndexDataResponse) {
  if (index.invoicedOk) return 'close';
  if (isPast(index)) return 'forbidden';
  if (isNext(index) || !index.emailSentOk) return 'next';
  return 'open';
}

export function isEditable(index: IndexEventDetails) {
  const dateTPF = DateTime.fromFormat(index.theoreticalDateNextInvoice, 'yyyy-MM-dd').set({ day: 1 });
  const now = DateTime.now().set({ hour: 0 });
  if (index?.contractLine?.invoicingRhythmSelect === '1') {
    return dateTPF < now && now < dateTPF.plus({ months: 1 });
  }
  return dateTPF < now && now < dateTPF.plus({ months: 3 });
}

export function getEditLabel(edit: string) {
  switch (edit) {
    case 'open':
      return 'Ouverte';
    case 'close':
      return 'Fermée';
    case 'forbidden':
      return 'Interdite';
    case 'next':
      return 'À venir';
    default:
      return '';
  }
}
