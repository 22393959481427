<footer class="footer-container">
  <mat-divider class="divider"></mat-divider>
  <div class="footer-menu">
    <ul>
      <li>
        <a class="links" routerLink="politique-de-confidentialite">Conditions générales d'utilisation</a>
      </li>
      <li>
        <a class="links" routerLink="mentions-legales">Mentions légales</a>
      </li>
    </ul>
  </div>
</footer>
