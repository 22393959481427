<div class="banner-inline">
  <div class="banner-content">
    <app-icon
      class="banner-icon"
      [phosphorClass]="getPhosphorClassAndColor().class"
      [color]="getPhosphorClassAndColor().color"
    ></app-icon>
    <p class="banner-title" [innerHTML]="title"></p>
    <div></div>
    <p class="banner-text" [innerHTML]="description"></p>
  </div>

  <ng-container *ngIf="buttonText">
    <button class="default-btn" color="primary" id="bannerBtn" mat-flat-button (click)="handleClick($event)">
      <span class="banner-button-text" [innerHTML]="buttonText"></span>
    </button>
  </ng-container>
</div>
