import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { ContractDetails } from '@app/shared/interfaces/contract.interface';
import { User } from '@app/shared/models/user-info';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { appendParams } from '@app/shared/utils/services-utils';
import { environment } from '@environments/environment';

import { Observable, Subject, map, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractDetailsService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  /**
   * @param id
   * @param isHistory
   */
  public getContract(id: string, isHistory: boolean = false): Observable<ContractDetails> {
    let detailContractUrl;
    if (isHistory) {
      detailContractUrl = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS_HISTORY}/${id}`;
    } else {
      detailContractUrl = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS}/${id}`;
    }

    return this.http
      .get<unknown>(detailContractUrl, {
        params: appendParams({ partnerId: this.authService.getProfileID() }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: any) => res.data));
  }

  /**
   * Met à jour les informations d'un contrat.
   * @param id Identifiant du contrat
   * @param contractData Données à mettre à jour
   * @returns Observable<ContractDetails> Observable des détails mis à jour du contrat
   */
  public updateContract(id: string, contractData: Partial<ContractDetails>): Observable<ContractDetails> {
    const updateContractUrl = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS}/${id}`;
    return this.http
      .put<unknown>(updateContractUrl, contractData, {
        params: appendParams({ partnerId: this.authService.getProfileID() }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: any) => res.data));
  }

  public getPriceHistory(params: any): Observable<unknown> {
    const priceHistoryUrl = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.PRICE_HISTORY}`;

    return this.http
      .get<unknown>(priceHistoryUrl, {
        params: appendParams(params),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: any) => res.data));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
