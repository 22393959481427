import { HttpParams } from '@angular/common/http';
import queryString from 'query-string';

export function appendParams(object: Record<string, any>) {
  return new HttpParams({
    fromString: queryString.stringify(object, {
      arrayFormat: 'comma',
    }),
  });
}
