<div class="change-consent">
  <p>
    <b>Je souhaite que SOREGIES gère la facturation de ma production à la place</b>, grâce aux relevés de mon compteur
    LINKY.
    <b>Je n’ai plus à déclarer mes index, tout est automatisé.</b>
  </p>
  <div class="description">
    <p>
      Je donne mon consentement explicite pour que SOREGIES procède à l’auto-facturation de ma production en récupérant
      mes données de production auprès du gestionnaire de réseau de distribution SRD, collectées à distance via mon
      compteur LINKY.<br />
      La télérelève s’effectuera conformément à la fréquence de facturation définie dans mon contrat, et seules les
      données de production liées à mon Point de Livraison (PDL) seront collectées.<br />
      Je suis informée(e) que :<br />
    </p>
    <ul>
      <li>Si la relève à distance ne fonctionne pas, je serais averti(e) par mail ou SMS afin dedéclarer mon index</li>
      <li>Je dispose d’un délai de 10 jours pour contester l’index relevé et facturé</li>
    </ul>
  </div>
  <app-report-message
    [variant]="MessageReportVariant.FILLED"
    [message]="
      'Je pourrais à tout moment choisir de déclarer moi-même mes index de production en modifiant mon choix dans la rubrique “Mes contrats”.'
    "
    [align]="'left'"
  ></app-report-message>
  <div class="contracts-table">
    <mat-table [dataSource]="contracts">
      <!-- Dossier Column -->
      <ng-container matColumnDef="dossier">
        <mat-header-cell *matHeaderCellDef> Dossier </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.contractLineId }} </mat-cell>
      </ng-container>

      <!-- Titulaire Column -->
      <ng-container matColumnDef="titulaire">
        <mat-header-cell *matHeaderCellDef> Titulaire </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>

      <!-- Contract Column -->
      <ng-container matColumnDef="contract">
        <mat-header-cell *matHeaderCellDef> Contract </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.mpt.code }} </mat-cell>
      </ng-container>

      <!-- PRM Column -->
      <ng-container matColumnDef="prm">
        <mat-header-cell *matHeaderCellDef> PRM </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.mpt.exchangeRef }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="footer">
      {{ this.contractsSize }}
      contrat{{ this.contractsSize > 1 ? 's' : '' }}
    </div>
  </div>
</div>
