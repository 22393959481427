<div class="legal-notice-container">
  <h1>Mentions légales</h1>

  <div class="content">
    <div class="social-reason">
      <strong>Raison sociale :</strong>
      <br /><br />
      La société Sorégies est une société anonyme à directoire et conseil de surveillance,
      <br />
      au capital de 3 800 000 euros, dont le siège social est :
      <br />
      78 Avenue Jacques Cœur - CS 10 000
      <br />
      86068 Poitiers CEDEX 9
      <br />
      Immatriculée au RCS de Poitiers sous le numéro : 502.035.785.
      <br />
      Responsable de la publication : Fréderic BOUVIER en qualité de Directeur Général
    </div>

    <div class="hosting">
      <span class="bold-text">
        <strong>
          <br />
          Hébergement du site internet
          <br /> </strong
      ></span>
      <br />
      Site internet hébergé sur AWS : Amazon Web Services Amazon.com Legal Department
      <br />
      410 Terry Avenue North P.O. Box 81226 Seattle, WA 98108-1226 – USA http://aws.amazon.com
      <br />
    </div>

    <div class="condition">
      <span class="bold-text">
        <strong>
          <br />
          Conditions d'utilisation
          <br />
        </strong>
      </span>
      <br />
      L’utilisateur reconnaît avoir pris connaissance des présentes conditions générales d’utilisation et s’engage à les
      respecter. Sorégies se réserve le droit de modifier les présentes conditions d’utilisation à tout moment et sans
      préavis. Les présentes conditions d’utilisation restent valides jusqu’à la publication des suivantes. Par
      conséquent, l’utilisateur s’engage à les consulter régulièrement.
      <br />
    </div>

    <div class="article-1">
      <span class="bold-text">
        <strong>
          <br />
          Article 1 – Contenu du site
          <br />
        </strong>
      </span>
      <br />
      Ce site donne accès à d’autres sites d’entreprises appartenant au Groupe Sorégies et susceptibles de disposer de
      leurs propres notices légales, qu’il convient de consulter et de respecter. Les sociétés du groupe Sorégies ont
      leur propre existence légale et disposent d’une personnalité juridique autonome. Les informations fournies sur le
      site mon-espace-producteur.soregies.fr sont présentées à titre indicatif et général et ne sauraient être utilisées
      sans vérification préalable de l’utilisateur. Le site mon-espace-producteur.soregies.fr propose également des
      Services en ligne pour les clients Sorégies. Ces services sont exclusivement réservés aux clients de Sorégies et
      leur utilisation nécessite une procédure obligatoire d’inscription et d’identification décrite à l’article 2 des
      présentes conditions d’utilisation. Sorégies met tout en œuvre pour offrir aux utilisateurs des informations et
      outils disponibles et vérifiés, mais ne saurait être tenue pour responsable des erreurs ou omissions, d’une
      absence de disponibilité des informations et des services, ainsi que des éventuelles conséquences pouvant résulter
      de l’utilisation de ces informations ou données. En conséquence, l’utilisateur reconnaît utiliser ces informations
      sous sa responsabilité exclusive. Sorégies ne saurait être tenu pour responsable des dommages directs ou indirects
      qui pourraient résulter de l’accès ou de l’utilisation du portail mon-espace-producteur.soregies.fr, y compris
      l’inaccessibilité, les pertes de données, et/ou la présence de virus sur le site, sans que cette liste soit
      exhaustive.
      <br />
    </div>

    <div class="article-2">
      <span class="bold-text">
        <strong>
          <br />
          Article 2 – Obligation d’inscription et d’identification pour l’utilisation de certains services
          <br />
        </strong>
      </span>
      <br />
      L’utilisation de certains services nécessite la création d’un espace client ainsi que la fourniture d’informations
      permettant l’identification de l’utilisateur, renseignées dans un formulaire d’inscription. Ces informations sont
      ensuite stockées et accessibles à la rubrique « Données personnelles ».
      <br />
    </div>

    <div class="article-3">
      <span class="bold-text">
        <strong>
          <br />
          Article 3 – Validité des informations
          <br />
        </strong>
      </span>
      <br />
      Les informations fournies sur le site mon-espace-producteur.soregies.fr sont présentées à titre indicatif et
      général et ne sauraient être utilisées sans vérifications préalables de l’utilisateur. GROUPE SOREGIES met tout en
      œuvre pour offrir aux utilisateurs des informations et outils disponibles et vérifiés, mais ne saurait être tenue
      pour responsables des erreurs ou omissions, d’une absence de disponibilité des informations et des services, ainsi
      que des éventuelles conséquences pouvant résulter de l’utilisation de ces informations ou données. En conséquence,
      l’utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive.
      <br />
    </div>

    <div class="article-4">
      <span class="bold-text">
        <strong>
          <br />
          Article 4 – Gestion des codes d’accès et des mots de passe
          <br />
        </strong>
      </span>
      <br />
      Certains services présents sur le site mon-espace-producteur.soregies.fr nécessitent un code et un mot de passe.
      Les codes et les mots de passe sont confidentiels et personnels. Il est interdit de les transmettre à un tiers.
      L’utilisateur reconnaît être responsable des conséquences de leur mauvaise utilisation, ainsi que de leur
      confidentialité. En cas de perte ou de vol d’un code d’accès, l’utilisateur s’engage à prévenir Sorégies dans les
      plus brefs délais.

      <br />
    </div>

    <div class="article-5">
      <span class="bold-text">
        <strong>
          <br />
          Article 5 – Résiliation du compte client
          <br />
        </strong>
      </span>
      <br />
      Tout utilisateur pourra résilier son compte client à tout moment par mail à l’adresse suivante :
      <a href="mailto:dpo@soregies.fr">contact&#64;soregies.fr</a>. En cas de manquement à l’une des obligations
      essentielles découlant des obligations légales énumérées à l’article 8, Sorégies sera en droit, sans notification
      préalable et avec effet immédiat, de désactiver le compte personnel d’un client. La résiliation du compte client,
      quelle qu’en soit la cause, n’entraîne pas résiliation des contrats de fourniture d’énergie ou tout autre contrat
      conclus avec Sorégies.

      <br />
    </div>

    <div class="article-6">
      <span class="bold-text">
        <strong>
          <br />
          Article 6 – Protection des données personnelles
          <br />
        </strong>
      </span>
      <br />
      Merci de bien vouloir vous référer à la politique de confidentialité et de protection des données personnelles
      Sorégies
      <br />
    </div>

    <div class="article-7">
      <span class="bold-text">
        <strong>
          <br />
          Article 7 – Informations techniques
          <br />
        </strong>
      </span>
      <br />
      En cas de litige le droit applicable est le droit français. Il est fait attribution exclusive de juridiction aux
      tribunaux compétents du ressort de la Cour d’Appel de Sorégies met à disposition son site internet et se service
      en se basant sur l’état de l’art informatique. Soregies ne peut être tenu pour responsable des incompatibilités
      techniques ou technologiques ou pour tous disfonctionnement de son site sur le matériel de l’utilisateur final.
      L’utilisateur reconnaît avoir été informé que le site mon-espace-producteur.soregies.fr et ses services sont
      accessibles en permanence, à l’exception des cas de force majeure, difficultés informatiques, techniques ou liées
      aux réseaux de télécommunications. Pour des raisons de maintenance, Sorégies pourra interrompre l’accès au site
      pour une période indéterminée pour toute opération de mise à jour ou de maintenance informatique.
      <br />
    </div>

    <div class="article-8">
      <span class="bold-text">
        <strong>
          <br />
          Article 8 – Cookies
          <br />
        </strong>
      </span>
      <br />
      Le cookie (ou témoin de connexion) est un petit fichier texte déposé sur un terminal (ordinateur, tablette,
      smartphone, etc.) qui a pour but de collecter des informations relatives à la navigation. Les cookies sont gérés
      par le navigateur internet du terminal. Tout visiteur du site mon-espace-producteur.soregies.fr est informé qu’un
      cookie peut s’installer automatiquement ou après obtention d’un consentement de l’utilisateur final sur le
      logiciel de navigation. Le cookie est un bloc de données qui n’est pas utilisé à des fins d’identification mais
      qui sert à enregistrer des informations relatives à la navigation sur le site dans le but de procéder notamment à
      des analyses de fréquentation ou des mesures d’audience. Pour plus de détails, nous vous invitons à consulter
      notre politique d’utilisation des cookies.
      <br />
    </div>

    <div class="article-9">
      <span class="bold-text">
        <strong>
          <br />
          Article 9 – Respect de la législation
          <br />
        </strong>
      </span>
      <br />
      L’utilisateur s’engage à respecter les lois applicables à Internet lors de la consultation du site. Dans
      l’hypothèse où l’utilisateur accède au site mon-espace-producteur.soregies.fr à partir d’un autre Etat que la
      France, il s’engage à s’assurer également du respect de la législation localement applicable. L’utilisateur
      reconnaît que toute tentative de récupération d’informations d’une manière détournée sera considérée comme une
      tentative de piratage informatique et pourra faire l’objet de poursuites judiciaires. L’utilisateur reconnaît que
      l’utilisation de tout système d’aspiration de site sur mon-espace-producteur.soregies.fr est formellement
      interdite.
      <br />
    </div>

    <div class="article-10">
      <span class="bold-text">
        <strong>
          <br />
          Article 10 – Propriété intellectuelle
          <br />
        </strong>
      </span>
      <br />
      <strong>Les marques de Sorégies</strong> ainsi que les logos des partenaires figurant sur le site sont des marques
      déposées. Les informations stockées dans le site sont la propriété de sorégies, les images et les graphismes sont
      la propriété de Sorégies ou de leurs auteurs respectifs. Plus généralement, toutes les informations et contenus
      figurant sur le site mon-espace-producteur.soregies.fr sont la propriété de Sorégies ou font l’objet d’un droit
      d’utilisation ou d’exploitation. L’utilisateur reconnaît que toute reproduction ou représentation totale ou
      partielle d’un élément du site sans autorisation écrite, expresse et préalable de Sorégies représente une
      violation des droits de la propriété intellectuelle.
      <br />
    </div>

    <div class="article-11">
      <span class="bold-text">
        <strong>
          <br />
          Article 11 – Liens
          <br />
        </strong>
      </span>
      <br />
      La création de liens hypertextes vers le site est soumise à l’accord préalable de Sorégies. Il est interdit de
      mettre en place un lien hypertexte en direction du site mon-espace-producteur.soregies.fr sans l’autorisation
      écrite, expresse et préalable de Sorégies.
      <br />
    </div>

    <div class="article-12">
      <span class="bold-text">
        <strong>
          <br />
          Article 12 – Crédits
          <br />
        </strong>
      </span>
      <br />
      En cas de litige le droit applicable est le droit français. Il est fait attribution exclusive de juridiction aux
      tribunaux compétents du ressort de la Cour d’Appel de Sorégies met à disposition son site internet et se service
      en se basant sur l’état de l’art informatique. Soregies ne peut être tenu pour responsable des inLes crédits
      photographiques et ceux relatifs à la conception et réalisation du site internet mon-espace-producteur.soregies.fr
      sont la propriété de Sorégies de fourniture d’énergie ou tout autre contrat conclus avec Sorégies.
      <br />
    </div>

    <div class="article-13">
      <span class="bold-text">
        <strong>
          <br />
          Article 13 – Litiges
          <br />
        </strong>
      </span>
      <br />
      En cas de litige le droit applicable est le droit français. Il est fait attribution exclusive de juridiction aux
      tribunaux compétents du ressort de la Cour d’Appel de Sorégies met à disposition son site internet et se service
      en se basant sur l’état de l’art informatique. Soregies ne peut être tenu pour responsable des inc En cas de
      litige le droit applicable est le droit français. Il est fait attribution exclusive de juridiction aux tribunaux
      compétents du ressort de la Cour d’Appel de Poitiers (France). Les parties s’efforceront au préalable de résoudre
      tout litige à l’amiable. Sorégies s’engage à répondre dans les plus brefs délais. En cas de non-règlement à
      l’amiable dans un délai de deux mois à compter de la réception de la réclamation, le
      <a href="https://www.energie-mediateur.fr/">Médiateur national de l’énergie </a>
      peut être saisi gratuitement et directement par Internet ou par courrier à : Médiateur national de l’énergie –
      Libre réponse n°59252 -75443 Paris Cedex 9.
      <br />
    </div>
  </div>
</div>
